import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import wordings from './wordings';
import RocketsBg from '../../assets/components/rockets';
import RocketsDesktop from '../../assets/components/rocketsDesktopMlb';
import ArrowStep from '../../assets/components/ArrowStep';

const Rockets = ({ i18n, siteId }) => {
  const content = wordings(i18n, siteId);
  const { title } = content;

  const cardByCountry = classnames('rockets__card',
    { 'rockets__card--card-mlb': siteId === 'MLB' });

  const contSteps = classnames('rockets__steps',
    { 'rockets__steps--cont-mlb': siteId === 'MLB' });

  return (
    <div className="rockets">
      <div className="rockets__card-container">
        <div className={cardByCountry}>
          <div>
            <h2 className="rockets__title">{title}</h2>
          </div>
          <div className="rockets__image-cont">
            {
              siteId === 'MLB' ? (
                <RocketsDesktop className="rockets__img" />
              ) : (
                <RocketsBg className="rockets__img" />
              )
            }
          </div>
          <ul className={contSteps}>
            {content.rockets.map(
              ({ title, subtitle, description, month, month2, month_mlb, month2_mlb }, index) => (
                <li className="rockets__rocket">
                  <div className="rockets__rocket-head">
                    <span className="rockets__rocket-number">{index + 1}</span>
                    <h3 className="rockets__rocket-head-title">{title}</h3>
                  </div>
                  <h4 className="rockets__rocket-subtitle">{subtitle}</h4>
                  <p className="rockets__rocket-description">{description}</p>
                  <div className="rockets__rocket-line">
                    <span className="rockets__rocket-line-dot" />
                    {content.rockets.length === index + 1 ? (
                      <ArrowStep className="rockets__arrow" />
                    ) : null}
                  </div>
                  <div className="rockets__rocket-months">
                    {siteId === 'MLB'
                      ? (
                        <>
                          <p className="rockets__rocket-month" dangerouslySetInnerHTML={{ __html: month_mlb }} />
                          {content.rockets.length === index + 1 && (
                          <p className="rockets__rocket-month" dangerouslySetInnerHTML={{ __html: month2_mlb }} />
                          )}
                        </>
                      )
                      : (
                        <>
                          <p className="rockets__rocket-month" dangerouslySetInnerHTML={{ __html: month }} />
                          {content.rockets.length === index + 1 && (
                            <p className="rockets__rocket-month" dangerouslySetInnerHTML={{ __html: month2 }} />
                          )}
                        </>
                      )}
                  </div>
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

Rockets.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default Rockets;
