import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';
import Button from '@andes/button';

import CardList from './CardList';
import wordings from './wordings';
import trackEvent from '../../../../utils/tracking';
import { TRACK_CLICK_ACTIVATE } from './tracks';

const Design = ({ i18n, siteId }) => {
  const locales = wordings(i18n);
  const { title, description, button } = locales.design;
  const imageCall = 'landing30/';

  return (
    <section id="design" className="design">
      <div className="container">
        <div className="design__title-cont">
          <h2 className="design__headline" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="design__paragraph">
            {description}
          </p>
        </div>
      </div>
      <div className="container design-cols-cont">
        <div className="design-cols-cont__cont-img">
          { siteId !== 'MLB' ? (
            <Image src={`${imageCall}img_design.png`} className="design-cols-cont__img-design" alt="" />) : (
              <Image src={`${imageCall}img_design_br.png`} className="design-cols-cont__img-design" alt="" />)}
        </div>
        <div className="design-cols-cont__cont-text">
          <ul className="design-cols-cont__icon-list">
            {locales.card.map((item) => (<CardList
              title={item.title}
              description={item.description}
              descriptionMx={item.description_mx}
              icons={item.designIcon}
              siteId={siteId}
            />))}
          </ul>
          <div className="button-cont">
            <Button
              onClick={() => trackEvent(TRACK_CLICK_ACTIVATE())}
              variant="danger"
              size="large"
              type="submit"
              className="btn-mshops"
            >
              {button}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

Design.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default Design;
