import React from 'react';

const IconCredits = () => (
  <svg width="20" height="23" viewBox="0 0 20 23" fill="none">
    <path
      d="M6.63861 21.7242C5.07009 20.9724 3.80356 19.71 3.0467 18.1439C2.28983 16.5779 2.08755 14.8011 2.47302 13.105C2.85849 11.4089 3.80884 9.89405 5.16823 8.80893C6.52762 7.72382 8.21537 7.13281 9.95475 7.13281C11.6941 7.13281 13.3819 7.72382 14.7413 8.80893C16.1007 9.89405 17.051 11.4089 17.4365 13.105C17.8219 14.8011 17.6197 16.5779 16.8628 18.1439C16.1059 19.71 14.8394 20.9724 13.2709 21.7242"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M17.0238 17.7911C17.4948 17.8601 17.9253 18.0959 18.2371 18.4556C18.5488 18.8154 18.721 19.2751 18.7223 19.7511V19.7511C18.7184 20.2733 18.5091 20.7731 18.1395 21.1421C17.7699 21.5111 17.2699 21.7198 16.7476 21.7228H2.97763C2.45334 21.7205 1.95123 21.511 1.58077 21.14C1.21031 20.769 1.00155 20.2665 1 19.7423V19.7423C1.00124 19.255 1.18155 18.7851 1.50663 18.4221C1.83172 18.0591 2.27891 17.8282 2.76312 17.7734"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M11.8669 13.1405C11.8904 11.1335 8.08498 11.1688 8.0791 13.1405C8.23191 15.4913 11.7905 13.8134 11.9403 16.1731C11.9403 18.1536 8.12024 18.1713 8.14081 16.1466"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M10.0098 10.748V18.4823"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.17676 1V3.31263"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M12.5811 1V3.31263"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M9.87988 2.07031V4.40645"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default IconCredits;
