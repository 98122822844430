import React from 'react';

const Arrow = ({ className }) => (
  <svg viewBox="0 0 24 24" fill="none" className={className}>
    <path d="M17.15 9.98001L12.37 14.76L7.59003 9.98001" stroke="#E73B90" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M22.25 12.42C22.25 14.3741 21.6706 16.2843 20.5849 17.909C19.4993 19.5338 17.9562 20.8002 16.1509 21.5479C14.3456 22.2957 12.359 22.4914 10.4425 22.1102C8.52595 21.729 6.76551 20.7881 5.38376 19.4063C4.00202 18.0246 3.06107 16.264 2.67984 14.3475C2.29862 12.431 2.49425 10.4443 3.24204 8.63901C3.98983 6.83368 5.2562 5.2908 6.88096 4.20517C8.50571 3.11955 10.4159 2.54014 12.37 2.54014C13.6678 2.53882 14.9531 2.79335 16.1524 3.2894C17.3517 3.78545 18.4415 4.51306 19.3592 5.43076C20.2769 6.34847 21.0045 7.43826 21.5006 8.63755C21.9966 9.83684 22.2513 11.1222 22.25 12.42V12.42Z"
      stroke="#E73B90"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default Arrow;
