const paths = '/shops/learning/more_info';

const CATEGORY_LANDING = 'LEARNING';
const ACTION_LANDING = {
  sellers: 'MORE_INFO_CDV',
  youtube: 'MORE_INFO_YOUTUBE',
  workshops: 'MORE_INFO_WORKSHOPS',
  webinar: 'MORE_INFO_WEBINARS',
  ebooks: 'MORE_INFO_EBOOKS',
  trafic: 'MORE_INFO_TRAFFIC',
};

export const TRACK_LEARN = (name) => ({
  melidata: {
    path: paths,
    eventData: {
      category: CATEGORY_LANDING.toLowerCase(),
      action: ACTION_LANDING[name].toLowerCase(),
    },
  },
  analytics: {
    category: CATEGORY_LANDING,
    action: ACTION_LANDING[name],
  },
});
