import React from 'react';

const IconHeadset = () => (
  <svg width="29" height="35" viewBox="0 0 29 35" fill="none">
    <path d="M25.2745 27.1986C25.1567 27.1986 25.0436 27.1517 24.9602 27.0684C24.8769 26.985 24.8301 26.872 24.8301 26.7541V12.0418C24.8586 10.6693 24.6128 9.30492 24.1073 8.02861C23.6018 6.75229 22.8466 5.58971 21.886 4.60898C20.9254 3.62825 19.7788 2.8491 18.5132 2.31718C17.2477 1.78526 15.8887 1.51128 14.5159 1.51128C13.1431 1.51128 11.7841 1.78526 10.5186 2.31718C9.25303 2.8491 8.10637 3.62825 7.14579 4.60898C6.18521 5.58971 5.43003 6.75229 4.92449 8.02861C4.41896 9.30492 4.17324 10.6693 4.20173 12.0418V26.7541C4.20173 26.872 4.1549 26.985 4.07155 27.0684C3.98819 27.1517 3.87513 27.1986 3.75725 27.1986C3.63937 27.1986 3.52631 27.1517 3.44295 27.0684C3.3596 26.985 3.31277 26.872 3.31277 26.7541V12.0418C3.28309 10.5518 3.55093 9.07086 4.10061 7.68564C4.65029 6.30043 5.47076 5.03878 6.51401 3.97455C7.55725 2.91031 8.80231 2.06487 10.1763 1.48772C11.5503 0.910561 13.0256 0.613281 14.5159 0.613281C16.0062 0.613281 17.4815 0.910561 18.8555 1.48772C20.2295 2.06487 21.4745 2.91031 22.5178 3.97455C23.561 5.03878 24.3815 6.30043 24.9312 7.68564C25.4809 9.07086 25.7487 10.5518 25.719 12.0418V26.7541C25.719 26.872 25.6722 26.985 25.5888 27.0684C25.5055 27.1517 25.3924 27.1986 25.2745 27.1986Z" fill="white" />
    <path d="M20.6468 33.2441C20.54 33.2472 20.4357 33.2118 20.3529 33.1442C20.2701 33.0766 20.2144 32.9815 20.1961 32.8762C20.1777 32.7709 20.1979 32.6626 20.2529 32.5709C20.308 32.4793 20.3941 32.4106 20.4957 32.3774C21.7673 31.9206 22.8664 31.0816 23.6423 29.9755C24.4182 28.8693 24.8328 27.5502 24.8294 26.1991V24.9679C24.8294 24.85 24.8762 24.737 24.9596 24.6536C25.0429 24.5703 25.156 24.5234 25.2739 24.5234C25.3918 24.5234 25.5048 24.5703 25.5882 24.6536C25.6715 24.737 25.7184 24.85 25.7184 24.9679V26.1991C25.7272 27.7386 25.2582 29.2429 24.3762 30.5047C23.4941 31.7665 22.2424 32.7236 20.7935 33.2441C20.745 33.2523 20.6954 33.2523 20.6468 33.2441Z" fill="white" />
    <path d="M6.89481 27.6115H5.70361C4.43155 27.6056 3.21354 27.0965 2.31572 26.1954C1.4179 25.2942 0.91327 24.0743 0.912109 22.8022V19.6687C0.913286 18.3935 1.42035 17.1709 2.32201 16.2693C3.22367 15.3676 4.44625 14.8606 5.72139 14.8594H6.91259C7.10463 14.8594 7.29479 14.8972 7.47221 14.9707C7.64963 15.0442 7.81083 15.1519 7.94662 15.2877C8.08241 15.4235 8.19013 15.5847 8.26362 15.7621C8.33711 15.9395 8.37494 16.1297 8.37494 16.3217V26.1314C8.37732 26.3264 8.34067 26.52 8.26713 26.7006C8.19359 26.8813 8.08466 27.0454 7.94674 27.1833C7.80882 27.3212 7.6447 27.4302 7.46404 27.5037C7.28339 27.5772 7.08985 27.6139 6.89481 27.6115ZM5.70361 15.7483C4.66732 15.7542 3.67541 16.1697 2.9443 16.9041C2.21319 17.6386 1.80223 18.6323 1.80107 19.6687V22.8022C1.80225 23.8416 2.21566 24.8381 2.9506 25.573C3.68555 26.308 4.68202 26.7214 5.72139 26.7226H6.91259C7.06466 26.7226 7.21051 26.6621 7.31804 26.5546C7.42557 26.4471 7.48597 26.3012 7.48597 26.1492V16.3217C7.48597 16.1696 7.42557 16.0238 7.31804 15.9163C7.21051 15.8087 7.06466 15.7483 6.91259 15.7483H5.70361Z" fill="white" />
    <path d="M23.3586 27.6115H22.163C21.9712 27.6115 21.7812 27.5737 21.604 27.5001C21.4269 27.4266 21.266 27.3188 21.1305 27.1829C20.9951 27.0471 20.8878 26.8858 20.8148 26.7085C20.7418 26.5311 20.7045 26.341 20.7051 26.1492V16.3217C20.7051 15.9346 20.8585 15.5634 21.1318 15.2893C21.4051 15.0151 21.7759 14.8606 22.163 14.8594H23.3586C24.6334 14.8617 25.8553 15.3692 26.7567 16.2706C27.6581 17.172 28.1656 18.3939 28.1679 19.6687V22.8022C28.1667 24.0774 27.6597 25.3 26.758 26.2016C25.8563 27.1033 24.6338 27.6103 23.3586 27.6115ZM22.163 15.7483C22.0879 15.7483 22.0135 15.7632 21.9442 15.7921C21.8749 15.8209 21.812 15.8632 21.7591 15.9165C21.7062 15.9698 21.6644 16.0331 21.6361 16.1026C21.6077 16.1722 21.5935 16.2466 21.594 16.3217V26.1314C21.5935 26.2065 21.6077 26.2809 21.6361 26.3505C21.6644 26.42 21.7062 26.4833 21.7591 26.5366C21.812 26.5899 21.8749 26.6322 21.9442 26.661C22.0135 26.6899 22.0879 26.7048 22.163 26.7048H23.3586C24.398 26.7036 25.3945 26.2902 26.1294 25.5552C26.8644 24.8203 27.2778 23.8238 27.279 22.7845V19.6509C27.2778 18.6115 26.8644 17.615 26.1294 16.8801C25.3945 16.1451 24.398 15.7317 23.3586 15.7306L22.163 15.7483Z" fill="white" />
    <path d="M18.8782 34.8792H16.1002C15.6397 34.878 15.1983 34.6945 14.8726 34.3689C14.547 34.0432 14.3635 33.6018 14.3623 33.1413V32.919C14.3635 32.4589 14.5471 32.018 14.8729 31.693C15.1987 31.368 15.6401 31.1855 16.1002 31.1855H18.8782C19.3376 31.1867 19.7778 31.3697 20.1027 31.6946C20.4275 32.0194 20.6105 32.4596 20.6117 32.919V33.1413C20.6117 33.6014 20.4292 34.0428 20.1042 34.3686C19.7793 34.6944 19.3384 34.878 18.8782 34.8792ZM16.1002 32.0745C15.8758 32.0745 15.6606 32.1633 15.5015 32.3216C15.3424 32.4798 15.2524 32.6946 15.2513 32.919V33.1413C15.2524 33.3661 15.3422 33.5813 15.5012 33.7403C15.6602 33.8992 15.8754 33.989 16.1002 33.9902H18.8782C19.1026 33.989 19.3174 33.8991 19.4757 33.74C19.6339 33.5809 19.7227 33.3657 19.7227 33.1413V32.919C19.7227 32.695 19.6338 32.4802 19.4754 32.3219C19.317 32.1635 19.1022 32.0745 18.8782 32.0745H16.1002Z" fill="white" />
  </svg>
);

export default IconHeadset;
