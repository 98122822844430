export default i18n => ({
  slide: [
    {
      brand: 'Auaha',
      img: '/mlb/auaha.png',
      url_card: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-diseno-web/agencia-auaha',
      track: 'AUAHA',
    },
    {
      brand: 'Benova',
      img: '/mlb/benova.png',
      url_card: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-diseno-web/benova',
      track: 'BENOVA',
    },
    {
      brand: 'Mt SoluÇoes',
      img: '/mlb/mt_solucoes.png',
      url_card: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-diseno-web/mt-solucoes',
      track: 'MT_SOLUCOES',
    },
    {
      brand: 'Wigoo',
      img: '/mlb/wigoo.png',
      url_card: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-diseno-web/wigoo',
      track: 'WIGOO',
    },
  ],
});
