import React from 'react';

const IconBanners = () => (
  <svg
    width="74"
    height="57"
    viewBox="0 0 74 57"
  >
    <path
      d="M26.8745 25.7963H44.1266V12.1436C44.1267 11.581 43.9056 11.0409 43.511 10.6399C43.1164 10.2388 42.5799 10.009 42.0174 10H8.62507C8.05654 10 7.5113 10.2258 7.1093 10.6279C6.70729 11.0299 6.48145 11.5751 6.48145 12.1436L6.48145 34.9096C6.48145 35.4781 6.70729 36.0233 7.1093 36.4253C7.5113 36.8273 8.05654 37.0532 8.62507 37.0532H24.0775V28.5933C24.0805 27.8524 24.3761 27.1428 24.9 26.6189C25.4239 26.095 26.1336 25.7993 26.8745 25.7963Z"
      fill="#E73B90"
    />
    <path
      d="M59.9001 28.6392H28.3648C27.5798 28.6392 26.9434 29.2756 26.9434 30.0606V53.3309C26.9434 54.116 27.5798 54.7524 28.3648 54.7524H59.9001C60.6851 54.7524 61.3215 54.116 61.3215 53.3309V30.0606C61.3215 29.2756 60.6851 28.6392 59.9001 28.6392Z"
      fill="#FFE600"
    />
  </svg>
);

export default IconBanners;
