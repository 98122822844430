import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import constants from '../constants';

const PricingTable = ({ active, shop, locales, siteId }) => {
  const textMercadoShops = 'Mercadoshops';
  const SHOPS_PRICES = constants(siteId);

  return (
    <table>
      <thead>
        <tr>
          <th className="pricingMobile__first-th" />
          <th className="pricingMobile__first-ms">{textMercadoShops}<span />
          </th>
          <th className="pricingMobile__first-other">
            {SHOPS_PRICES.find((item) => item.id === shop).name}
          </th>
        </tr>
      </thead>
      <tbody>
        {locales.table.map((item, key) => (
          <tr>
            <td
              className={classnames(
                'pricingMobile__left',
                'pricingMobile__border',
                { 'pricingMobile__border--first': key === 0 },
              )}
            >
              <p className="table__titles-col" dangerouslySetInnerHTML={{ __html: item.title_col }} />
              {item.col_1}
            </td>
            <td
              className={classnames(
                'pricingMobile__main',
                { 'pricingMobile__main--first': key === 0 },
                { 'pricingMobile__border--last': locales.table.length === key + 1 },
              )}
            >
              {item.col_2} <span />
            </td>
            <td
              className={classnames(
                'pricingMobile__border',
                'pricingMobile__border--other',
                'pricingMobile__center',
                { 'pricingMobile__border--first': key === 0 },
                { 'pricingMobile__border--last': locales.table.length === key + 1 },
              )}
            >
              {item[`col_${shop + 3}`]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>);
};

PricingTable.propTypes = {
  shop: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  locales: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PricingTable;
