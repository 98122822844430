import MLA from './mla';
import MLM from './mlm';
import MLB from './mlb';

export default (i18n, siteId) => {
  const APPSTORE = {
    MLA: MLA(i18n),
    MLM: MLM(i18n),
    MCO: MLM(i18n),
    MLC: MLA(i18n),
    MLB: MLB(i18n),
  };

  return APPSTORE[siteId];
};
