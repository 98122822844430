export default [
  {
    img: '/landing30/agencia_suma.png',
    name: 'SUMA',
    link: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-marketing/suma',
  },
  {
    img: '/landing30/agencia_orka.png',
    name: 'ORKA',
    link: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-marketing/orka',
  },
  {
    img: '/landing30/agencia_ecore.png',
    name: 'E-CORE',
    link: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-marketing/e-core-digital-performance-sas',
  },
  {
    img: '/landing30/agencia_estudioMerca.png',
    name: 'ESTUDIO_MERCA',
    link: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-marketing/estudio-merca',
  },
  {
    img: '/landing30/agencia_emarketer360.png',
    name: 'EMARKETER_360',
    link: 'https://centrodepartners.mercadolibre.com.mx/solutions/consultoras/emarketer360',
  },
  {
    img: '/landing30/agencia_cajanauta.png',
    name: 'CAJANAUTA',
    link: 'https://centrodepartners.mercadolibre.com.mx/solutions/consultoras/cajanauta-com',
  },
];
