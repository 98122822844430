import MeliGaTrackerService from '@mshops-web-core/meli-ga-tracker';
import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

const trackEvent = (track, commonTrackData = {}) => {
  const { analytics, melidata, analyticsView } = track;

  if (analyticsView) {
    const { path } = analyticsView;

    MeliGaTrackerService.sendViewTrack({ path });
  }

  if (analytics) {
    const { category, label, action } = analytics;

    MeliGaTrackerService.sendEventTrack({
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  if (melidata) {
    const { path, eventData, type = 'event' } = melidata;

    MelidataTrackerService.notify({
      path,
      data: {
        ...eventData,
        ...commonTrackData,
      },
      type,
    });
  }
};

export default trackEvent;
