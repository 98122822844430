const category = 'LANDING';
const action = 'CLICK_ACTIVATE';
const label = 'BANNER_DESIGN';
const path = '/home/landing/activate';

export const TRACK_CLICK_ACTIVATE = () => ({
  melidata: {
    path,
    eventData: {
      category: category.toLowerCase(),
      action: action.toLowerCase(),
      label: label.toLowerCase(),
    },
    useBeacon: true,
  },
  analytics: {
    category,
    action,
    label,
  },
});
