export default i18n => ({
  title: i18n.gettext('Marcas que {0}confían{1} en Mercado Shops:', '<strong class="brands__title--bold">', '</strong>'),
  cards: [
    {
      brand: 'Longvie',
      link: 'https://www.tiendaoficial.longvie.com/',
      logo: 'mla/logo_longvie.png',
      modal_image: 'mla/screen_longvie.png',
      button: i18n.gettext('Ver tienda'),
      track: 'LONGVIE',
    }, {
      brand: 'Legion Extranjera',
      link: 'https://www.legionextranjera.com.ar/',
      logo: 'mla/logo_legion_extranjera.png',
      modal_image: 'mla/screen_legion_extranjera.png',
      button: i18n.gettext('Ver tienda'),
      track: 'LEGION_EXTRANJERA',
    }, {
      brand: 'Regina',
      link: 'https://www.reginak.com/',
      logo: 'mla/logo_regina.png',
      modal_image: 'mla/screen_regina.png',
      button: i18n.gettext('Ver tienda'),
      track: 'REGINA',
    }, {
      brand: 'Cebra',
      link: 'https://www.cebra.com.ar/',
      logo: 'mla/logo_cebra.png',
      modal_image: 'mla/screen_cebra.png',
      button: i18n.gettext('Ver tienda'),
      track: 'CEBRA',
    },
  ],
});
