import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import classnames from 'classnames';

import wordings from '../wordings';
import TabNumber from '../TabNumber';
import TabSlider from '../TabSlider';
import constants from '../constants';

const TabsDesktop = ({ i18n, siteId }) => {
  const { tab1, tab2, tab3, cardsData } = wordings(i18n, siteId);
  const { cards } = constants(cardsData, siteId);
  const slider = useRef(null);
  const [tabActive, setTabActive] = useState(0);
  let tabs = [];

  if (siteId === 'MLB') {
    tabs = [
      { id: 0, title: tab2 },
      { id: 1, title: tab3 },
    ];
  } else {
    tabs = [
      { id: 0, title: tab1 },
      { id: 1, title: tab2 },
      { id: 2, title: tab3 },
    ];
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    arrows: true,
  };

  const classTabsBtnGroup = classnames('tabs__btn-group', { 'tabs__btn-group--mlb': siteId === 'MLB' });

  return (
    <div className="tabs">
      <div className={classTabsBtnGroup}>
        {tabs.map(({ title, id }) => (
          <button
            type="button"
            className={classnames('tabs__btn', { 'tabs__btn--active': tabActive === id })}
            onClick={() => {
              setTabActive(id);
              slider?.current?.slickGoTo(id);
            }}
          >
            <span>{id + 1}</span>
            <div>{title}</div>
          </button>
        ))}
      </div>

      <Slider ref={slider} {...settings}>
        {cards.map((card) => card !== null && (card.type === 1 ? (
          <TabNumber card={card} />
        ) : (
          <TabSlider card={card} siteId={siteId} />
        )))}
      </Slider>
    </div>
  );
};

TabsDesktop.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default TabsDesktop;
