const CATEGORY_LANDING = 'PARTNERS';
const paths = '/shops/partners/click_design_web';

export const TRACK_AGENCY = (action, shop) => ({
  melidata: {
    path: paths,
    eventData: {
      category: CATEGORY_LANDING.toLowerCase(),
      action: action.toLowerCase(),
      label: shop.toLowerCase(),
    },
  },
  analytics: {
    category: CATEGORY_LANDING,
    action,
    label: shop,
  },
});
