import MLA from './mla';
import MLM from './mlm';
import MCO from './mco';
import MLC from './mlc';
import MLB from './mlb';

const MERCADO_PAGO = { MLA, MLM, MCO, MLC, MLB };

export default (i18n, siteId) => {
  const {
    tallerVideo,
    tallerCount1Number,
    tallerCount2Number,
    tallerBtnLink,
    brigadaVideo,
    brigadaCount1Number,
    brigadaCount2Number,
    brigadaCount2Subtitle,
    brigadaBtnLink,
    agenciaBtnLink,
  } = MERCADO_PAGO[siteId];

  return {
    tab1: i18n.gettext('talleres'),
    tab2: i18n.gettext('brigadas'),
    tab3: i18n.gettext('agencias'),
    hideInfo: i18n.gettext('Ver menos'),
    showInfo1: i18n.gettext('Conocé el programa'),
    showInfo2: i18n.gettext('Desarrollá tu Shop'),
    showInfo3: i18n.gettext('Asesórate con expertos'),
    cardsData: {
      card1: {
        title: i18n.gettext('¡Hagamos despegar tu marca juntos!'),
        subtitle: i18n.gettext('Si no sabes por donde empezar,{0} esta sección es para ti. {0} Aprenderás a:', '<br/>'),
        items: [
          i18n.gettext('Dar los primeros pasos en tu tienda'),
          i18n.gettext('Diseñarla como más te guste'),
          i18n.gettext('Implementar campañas de Marketing Digital para llegar a más compradores'),
        ],
        count1Title: i18n.gettext('sellers'),
        count1Subtitle: i18n.gettext('Participaron en nuestros talleres'),
        count2Title: i18n.gettext('Ventas'),
        count2Subtitle: i18n.gettext('de aumento en sus'),
        alertText: i18n.gettext('¡Además te regalamos crédito en Mercado Ads para promocionar tu Shop dentro de Mercado Libre!'),
        alertBtn: i18n.gettext('Inscribirme ahora'),
        experience: i18n.gettext('Conocé la iniciativa contada en primera persona:'),
        tallerVideo,
        tallerCount1Number,
        tallerCount2Number,
        tallerBtnLink,
      },
      card2: {
        title: i18n.gettext('¿Ya tienes tu tienda andando?'),
        description: i18n.gettext('Con el apoyo de una agencia de Marketing Digital y asesores comerciales de Shops, puedes participar de un programa intensivo para desarrollar la imagen de tu marca e incrementar tu facturación.'),
        subtitle: i18n.gettext('Llévala al siguiente nivel'),
        count1Title: i18n.gettext('sellers'),
        count1Subtitle: i18n.gettext('Participaron en nuestras Brigadas'),
        count2Title: i18n.gettext('facturación'),
        count2Subtitle: i18n.gettext('Aumentaron {0} su', brigadaCount2Subtitle),
        alertText: i18n.gettext('“Queremos que tu negocio acelere su crecimiento. Invertimos cubriendo los costos de agencia de Marketing Digital durante los primeros meses”.'),
        alertBtn: i18n.gettext('¡Conoce más de nuestras Brigadas!'),
        brigadaVideo,
        brigadaCount1Number,
        brigadaCount2Number,
        brigadaBtnLink,
      },
      card3: {
        title: i18n.gettext('Consolida tu crecimiento'),
        description: i18n.gettext('Con nuestra red de Expertos en Marketing Digital, estamos listos para ayudarte a escalar tu negocio.'),
        adviceTitle: i18n.gettext('¡Asesoramiento para todos los canales de venta!'),
        adviceSubtitle: i18n.gettext('Nuestros Expertos podrán ayudarte en toda tu operación, brindándote un asesoramiento integral.'),
        slideTitle: i18n.gettext('Descubre las agencias más relevantes:'),
        alertBtn: i18n.gettext('Conocer Expertos certificados'),
        agenciaBtnLink,
      },
    },
  };
};
