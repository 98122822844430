import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';
import Slider from 'react-slick';

import trackEvent from '../../../../utils/tracking';
import wordings from './wordings';
import configSlider from './configSlider';
import { TRACK_AGENCY } from './tracks';

const Agency = ({ i18n, siteId }) => {
  const locales = wordings(i18n, siteId);
  const renderSlide = locales.slide;

  const CLICK_DESIGN_WEB = 'CLICK_DESIGN_WEB';

  const trackAgency = (track) => {
    trackEvent(TRACK_AGENCY(CLICK_DESIGN_WEB, track));
  };

  return (
    <div className="agency-slide">
      <Slider {...configSlider}>
        {renderSlide.map(({ img, brand, url_card, track }, key) => (
          <div key={key} className="agency-slide__agency-slide-img-container">
            <a href={url_card} target="_blank" rel="noreferrer" onClick={() => { trackAgency(track); }}>
              <div className="agency-slide__agency-card">
                <Image className="agency-slide__agency-slide-img" src={`partners30/${img}`} alt={brand} />
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

Agency.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Agency;
