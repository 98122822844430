export default i18n => ({
  title: i18n.gettext('Marcas que {0}confían{1} en Mercado Shops:', '<strong class="brands__title--bold">', '</strong>'),
  cards: [
    {
      brand: 'Tecnoplaza',
      link: 'https://www.tecnoplaza.com.co/',
      logo: 'mco/logo_tecnoplaza.png',
      modal_image: 'mco/screen_tecnoplaza.png',
      button: i18n.gettext('Ver tienda'),
      track: 'TECNOPLAZA',
    }, {
      brand: 'Celulares 99',
      link: 'https://www.celulares99.com.co/',
      logo: 'mco/logo_celulares_99.png',
      modal_image: 'mco/screen_celulares_99.png',
      button: i18n.gettext('Ver tienda'),
      track: 'CELULARES_99',
    }, {
      brand: 'Bebesitos',
      link: 'https://www.bebesitos.com/',
      logo: 'mco/logo_bebesitos.png',
      modal_image: 'mco/screen_bebesitos.png',
      button: i18n.gettext('Ver tienda'),
      track: 'BEBESITOS',
    }, {
      brand: 'Croydon',
      link: 'https://www.croydon.com.co/',
      logo: 'mco/logo_croydon.png',
      modal_image: 'mco/screen_croydon.png',
      button: i18n.gettext('Ver tienda'),
      track: 'CROYDON',
    },
  ],
});
