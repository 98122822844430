export default i18n => ({
  title: i18n.gettext('¿Qué es {0}Mercado Shops{1} y por qué es la mejor plataforma para vender online?', '<strong class="about-ms__headline-bold">', '</strong>'),
  description: i18n.gettext('Es la plataforma de {0}Mercado Libre{1} que te permite crear tu propia tienda online.', '<strong class="about-ms__copy-text--bold">', '</strong>'),
  List: [
    {
      text: i18n.gettext('Está integrado con Mercado Libre, lo que te permite acceder a todas las herramientas del e-commerce #1 en Latinoamérica: pagos seguros con Mercado Pago, envíos en 24hs. y publicidad dentro de la plataforma.'),
      icons: 'metrics',
    },
    {
      text: i18n.gettext('Te ofrece herramientas poderosas para llegar a más compradores y maximizar tus ventas.'),
      icons: 'shops',
    },
  ],
});
