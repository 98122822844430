import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';
import classnames from 'classnames';

import wordings from './wordings';

const HeroEcosystem = ({ i18n, siteId }) => {
  const { title, subtitle } = wordings(i18n, siteId);

  const titleLocation = classnames('ui-ms-hero-ecosystem__title',
    { 'ui-ms-hero-ecosystem__title--title-mlb': siteId === 'MLB' });

  return (
    <section className="ui-ms-hero-ecosystem">
      <div className="ui-ms-hero-ecosystem__container">
        <div className="ui-ms-hero-ecosystem__content">
          <h1 className={titleLocation} dangerouslySetInnerHTML={{ __html: title }} />
          <Image className="ui-ms-hero-ecosystem__img" src="landing30/hero-ecosystem.png" />
          <p className="ui-ms-hero-ecosystem__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }} />
        </div>
      </div>
      <Image className="ui-ms-hero-ecosystem__wave" src="landing30/hero-wave-curve.png" />
    </section>
  );
};

HeroEcosystem.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default HeroEcosystem;
