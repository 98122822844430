export default [
  {
    img: '/landing30/agencias_milbrands.png',
    name: 'MILBRANDS',
    link: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-marketing/milbrands',
  },
  {
    img: '/landing30/agencias_krabe.png',
    name: 'KRABE',
    link: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-marketing/krab-e',
  },
  {
    img: '/landing30/agencias_bitacora.png',
    name: 'BITACORA',
    link: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-marketing/bitacora',
  },
  {
    img: '/landing30/agencias_adamas.png',
    name: 'ADAMAS',
    link: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-marketing/adamas',
  },
  {
    img: '/landing30/agencias_brainsted.png',
    name: 'BRAINSTED',
    link: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-marketing/brainsted',
  },
  {
    img: '/landing30/agencias_galarreta.png',
    name: 'GALARRETA',
    link: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-marketing/galarreta',
  },
];
