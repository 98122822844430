import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import Slider from 'react-slick';

import Card from './Card';
import wordings from './wordings';

const Cases = ({
  i18n,
  deviceType,
  isWebview,
  siteId,
}) => {
  const appClassName = classnames('app app_landing', { 'app--top': isWebview });
  const locales = wordings(i18n, siteId);
  const { title } = locales;

  const makeLowerCase = (value) => value.toString().toLowerCase().replace(/\s+/g, '-');

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          dots: true,
        },
      }, {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: 1,
        },
      },
    ],
  };

  return (
    <div className={appClassName}>
      <section id="brands" className="brands">
        <div className="brands__container">
          <div className="brands__cont-title">
            <h2 className="brands__title" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div className="cards-brands">
            <ul className="cards-brands__cards-list">
              <Slider {...settings} slidesToShow={locales.cards.length <= 4 ? locales.cards.length : 4}>
                {locales.cards.map((item) => (
                  <li>
                    <Card
                      Link={item.link}
                      logoImage={`landing30/${item.logo}`}
                      altImage={item.brand}
                      bgBrand={makeLowerCase(item.brand)}
                      imageModal={item.modal_image}
                      ctaText={item.button}
                      DeviceType={deviceType}
                      track={item.track}
                    />
                  </li>))}
              </Slider>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

Cases.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  isWebview: PropTypes.bool.isRequired,
};

export default Cases;
