const col_2_3_1 = '$USD 25';
const col_2_3_2 = '$USD 399';
const col_2_4_1 = '$14.000';
const col_2_4_2 = '$180.000';
const col_2_5_1 = 'USD 70';

const col_3_3_1 = '0,5%';
const col_3_3_2 = '2%';

const col_4_2_1 = '1,99%';
const col_4_3_1 = '2,89%';

const col_5_3_1 = '6,38%';
const col_5_2_2 = '9,88%';
const col_5_2_1 = '9,00%';

const col_6_2_1 = '50%';

const tags = ['<strong>', '</strong>'];

export default i18n => ({
  logos: {
    logo_1: 'landing30/logo_shopify.png',
    logo_1_alt: 'Shopify',
    logo_2: 'landing30/logo_jumpseller.png',
    logo_2_alt: 'Jumpseller',
    logo_3: 'landing30/logo_woo_commerce.png',
    logo_3_alt: 'Woocommerce',
  },
  table: [
    {
      col_1: i18n.gettext('Costos fijos por plataforma'),
      col_2: i18n.gettext('Gratis'),
      col_3: i18n.gettext('{0} a {1} fijo mensual', col_2_3_1, col_2_3_2),
      col_4: i18n.gettext('{0} a {1} fijo mensual', col_2_4_1, col_2_4_2),
      col_5: i18n.gettext('A partir de {0} fijo mensual', col_2_5_1),
    }, {
      col_1: i18n.gettext('Costo por transacción por plataforma'),
      col_2: i18n.gettext('Gratis'),
      col_3: i18n.gettext('{0} a {1} por transacción', col_3_3_1, col_3_3_2),
      col_4: i18n.gettext('Gratis'),
      col_5: i18n.gettext('Gratis'),
    },
    {
      title_col: i18n.gettext(
        '{0}Costo total ofreciendo{1} un pago o financiación en cuotas con interés',
        ...tags,
      ),
      col_2: i18n.gettext('{0} + IVA', col_4_2_1),
      col_3: i18n.gettext('{0} + IVA', col_4_3_1),
      col_4: i18n.gettext('{0} + IVA', col_4_3_1),
      col_5: i18n.gettext('{0} + IVA', col_4_3_1),
    }, {
      title_col: i18n.gettext(
        '{0}Costo total ofreciendo hasta 6 cuotas sin interés{1}',
        ...tags,
      ),
      col_2: i18n.gettext('{0} + IVA', col_4_2_1),
      col_3: i18n.gettext('{0} + IVA', col_5_3_1),
      col_4: i18n.gettext('{0} + IVA', col_5_3_1),
      col_5: i18n.gettext('{0} + IVA', col_5_3_1),
    }, {
      title_col: i18n.gettext(
        '{0}Costo total ofreciendo hasta 12 cuotas sin interés{1}',
        ...tags,
      ),
      col_2: i18n.gettext('{0} + IVA', col_5_2_1),
      col_3: i18n.gettext('{0} + IVA', col_5_2_2),
      col_4: i18n.gettext('{0} + IVA', col_5_2_2),
      col_5: i18n.gettext('{0} + IVA', col_5_2_2),
    }, {
      col_1: i18n.gettext('Costo por envío gratis en 24 hs'),
      col_2: i18n.gettext('Hasta {0} de descuento', col_6_2_1),
      col_3: i18n.gettext('Costo total a cargo del vendedor'),
      col_4: i18n.gettext('Costo total a cargo del vendedor'),
      col_5: i18n.gettext('Costo total a cargo del vendedor'),
    }, {
      col_1: i18n.gettext('Acceso a canal de publicidad exclusivo Mercado Ads'),
      col_2: i18n.gettext('Tráfico desde Mercado Libre a tu Shop'),
      col_3: i18n.gettext('No disponible'),
      col_4: i18n.gettext('No disponible'),
      col_5: i18n.gettext('No disponible'),
    },
  ],
  legales: [
    {
      text: i18n.gettext('(1) Las tarifas incluidas en la fila "Costos fijos Plataforma" no contemplan precios promocionales, descuentos por contrataciones trimestrales, semestrales o anuales, ni precios de lanzamiento de nuevos procesadores de pago y ni pruebas gratuitas. Las tarifas de Shopify y Woocommerce están publicadas en su sitio oficial en dólares estadounidenses.'),
    },
    {
      text: i18n.gettext('(2) Las tarifas incluidas en las filas "Costo por un pago o financiación en meses con interés" y "Costo por financiación con hasta 12 meses sin interés" corresponden al servicio prestado por Mercado Pago y considerando un plazo de liberación de dinero de 14 días contados a partir de la aprobación del pago.'),
    },
    {
      text: i18n.gettext('(3) En relación a la fila "Costo por envío gratis en 24 horas" se aclara que Shopify, Jumpseller y Woocommerce no informan servicios de envíos ni beneficios asociados para el vendedor.'),
    },
    {
      text: i18n.gettext('(4) Este cuadro tiene meros fines informativos y ha sido confeccionado con información pública obtenida con fecha 21/11/2023 de los sitios oficiales de Shopify, Jumpseller y Woocommerce destinados para Colombia.'),
    },
    {
      text: i18n.gettext('(5) Las marcas Shopify, Jumpseller y WooCommerce no son propiedad de Mercado Libre, perteneciendo a sus respectivos titulares.'),
    },
  ],
});
