export default [
  {
    img: '/landing30/agencia_digitalGrowth.png',
    name: 'DIGITAL_GROWTH',
    link: 'https://www.digitalgrowth.marketing/',
  },
  {
    img: '/landing30/agencia_BDLabs.png',
    name: 'BD_LABS',
    link: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-marketing/bd-labs',
  },
  {
    img: '/landing30/agencia_Wigoo.png',
    name: 'WIGOO',
    link: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-marketing/wigoo',
  },
  {
    img: '/landing30/agencia_3M.png',
    name: '3M_IMPULSE',
    link: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-marketing/3m-consultoria',
  },
  {
    img: '/landing30/agencia_BDLabs.png',
    name: 'ECOM_LABS',
    link: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-marketing/ecom-labs',
  },
  {
    img: '/landing30/agencia_Marketplace40.png',
    name: 'MARKETPLACE_40',
    link: 'https://centraldeparceiros.mercadolivre.com.br/solutions/agencias-de-marketing/marketplace-4-0',
  },
];
