import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';

import wordings from './wordings';
import Rockets from '../Rockets';

const HeroPartners = ({ i18n, siteId }) => {
  const { title, subtitle } = wordings(i18n);

  return (
    <section className="background-components">
      <div className="second-layer">
        <div className="hero-partner">
          <div className="hero-partner__container">
            <div className="hero-partner__cont-text">
              <h1 className="hero-partner__title">{title}</h1>
              <p className="hero-partner__subtitle">{subtitle}</p>
            </div>
            <div>
              <Image src="landing30/hero-partner.png" alt={title} className="hero-partner__image-hero" />
            </div>
          </div>
        </div>
        <Rockets i18n={i18n} siteId={siteId} />
      </div>
    </section>
  );
};

HeroPartners.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default HeroPartners;
