import React from 'react';
import PropTypes from 'prop-types';
import Image from 'nordic/image';

import wordings from './wordings';

const EbooksHero = ({ i18n }) => {
  const { title, subtitle } = wordings(i18n);

  return (
    <section className="ui-ms-hero-ebooks">
      <div className="ui-ms-hero-ebooks__content">
        <h1 className="ui-ms-hero-ebooks__title">{title}</h1>
        <p className="ui-ms-hero-ebooks__subtitle">{subtitle}</p>
      </div>
      <Image className="ui-ms-hero-ebooks__image" src="ebooks/hero-ebooks.png" />
    </section>
  );
};

EbooksHero.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default EbooksHero;
