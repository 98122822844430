import React from 'react';

import Image from 'nordic/image';
import Button from '@andes/button';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import trackEvent from '../../../../utils/tracking';
import { TRACK_MODULES } from './tracks';
import IconList from './IconList';
import ShapeTop from '../../assets/components/ShapeTop';
import ShapeBottom from '../../assets/components/ShapeBottom';

const Ecosystem = ({
  name,
  name_mlb: nameMlb,
  track,
  bgColor,
  wavetop,
  wavebottom,
  order,
  title,
  button,
  image,
  siteId,
  iconList,
  deviceType,
}) => {
  const backgroundClass = classnames(
    {
      'ecosystems__bg-white': bgColor === 'white',
      'ecosystems__bg-white-first': bgColor === 'white-first',
      'ecosystems__bg-grey': bgColor === 'grey',
      'ecosystems__bg-grey-last': bgColor === 'grey-last',
    },
  );

  const orderClass = classnames('ecosystems__container',
    {
      'ecosystems__container--left-to-right': order === 'ltr',
      'ecosystems__container--right-to-left': order === 'rtl',
    });

  const imageClass = classnames('ecosystems__col-image',
    {
      'ecosystems__col-image--image-space-ltr': order === 'ltr',
      'ecosystems__col-image--image-space-rtl': order === 'rtl',
    });

  const makeLowerCase = (value) => value.toString().toLowerCase().replace(/\s+/g, '-');

  const trackModules = (title) => {
    const ACTION = 'CLICK_ACTIVATE';
    trackEvent(TRACK_MODULES(ACTION, title));
  };

  return (
    <div>
      {wavetop && (
        <div className="ecosystems__shape-top">
          <ShapeTop />
        </div>
      )}
      <div className={backgroundClass}>
        <div className={orderClass}>
          <div className="ecosystems__col-text">
            {
              nameMlb === 'Mercado Livre' && siteId === 'MLB' ? (
                <Image src={`ecosystem/${makeLowerCase(nameMlb)}.png`} className="ecosystems__logo" alt={nameMlb} />
              ) : (
                <Image src={`ecosystem/${makeLowerCase(name)}.png`} className="ecosystems__logo" alt={name} />
              )
            }
            <h2 className="ecosystems__title">
              {title}
            </h2>
            {deviceType !== 'desktop' && (
              <Image src={`ecosystem/${image}`} className="ecosystems__image" alt={title} />
            )}
            <ul className="icon-list">
              {iconList.map((c) => <IconList text={c.text} icon={c.iconName} />)}
            </ul>
            <Button
              variant="danger"
              size="large"
              type="submit"
              className="btn-ecosystem"
              onClick={() => { trackModules(track); }}
            >
              {button}
            </Button>
          </div>
          {deviceType === 'desktop' && (
            <div className={imageClass}>
              <Image src={`ecosystem/${image}`} className="ecosystems__image" alt={title} />
            </div>
          )}
        </div>
      </div>
      {wavebottom && (
        <div className="ecosystems__shape-bottom">
          <ShapeBottom />
        </div>
      )}
    </div>
  );
};

Ecosystem.propTypes = {
  siteId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameMlb: PropTypes.string.isRequired,
  track: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  iconList: PropTypes.shape({
    text: PropTypes.string,
    iconName: PropTypes.string,
  }),
  wavebottom: PropTypes.bool.isRequired,
  wavetop: PropTypes.bool.isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Ecosystem;
