import React from 'react';

const IconBox = () => (
  <svg width="21" height="23" viewBox="0 0 21 23" fill="none">
    <path
      d="M9.83135 21.9995C9.76871 21.9995 9.70606 21.9867 9.6476 21.9567L0.234358 17.2369C0.0923655 17.1643 0.000488281 17.0189 0.000488281 16.8522V5.36481C0.000488281 5.20236 0.0881893 5.057 0.226005 4.98432L9.63924 0.0465062C9.75618 -0.0133461 9.89399 -0.0176212 10.0151 0.0465062L19.7708 4.98432C19.9128 5.057 20.0047 5.20663 20.0047 5.36909V16.8094C20.0047 16.9762 19.9128 17.1258 19.7666 17.1942L10.0109 21.961C9.95246 21.9867 9.89399 22.0038 9.83135 22.0038V21.9995ZM0.835736 16.5871L9.83135 21.0974L19.1652 16.5358V5.62987L9.83135 0.905814L0.835736 5.6256V16.5871Z"
      fill="#E73B90"
    />
    <path
      d="M9.81882 10.5248C9.75618 10.5248 9.68936 10.5077 9.63089 10.4777L8.68706 9.99038C8.48242 9.8835 8.3989 9.62699 8.5033 9.4175C8.60771 9.20802 8.85828 9.12252 9.06292 9.2294L9.823 9.62271L12.216 8.48552C12.4248 8.38719 12.6754 8.47697 12.7714 8.69073C12.8675 8.90448 12.7798 9.16099 12.571 9.25932L9.99422 10.4863C9.93993 10.5119 9.87729 10.5248 9.81882 10.5248V10.5248Z"
      fill="#E73B90"
    />
    <path
      d="M9.83144 21.9993C9.60175 21.9993 9.41382 21.8069 9.41382 21.5718V10.0246C9.41382 9.78943 9.60175 9.59705 9.83144 9.59705C10.0611 9.59705 10.2491 9.78943 10.2491 10.0246V21.5718C10.2491 21.8069 10.0611 21.9993 9.83144 21.9993Z"
      fill="#E73B90"
    />
    <path
      d="M7.59301 9.46025C7.53037 9.46025 7.46772 9.44743 7.40508 9.41323L0.230302 5.74513C0.0256667 5.63825 -0.057858 5.38174 0.0423717 5.17226C0.142601 4.96278 0.397352 4.873 0.601988 4.97988L7.77676 8.64797C7.9814 8.75485 8.06492 9.01136 7.96469 9.22084C7.8937 9.37047 7.74335 9.45598 7.58883 9.45598L7.59301 9.46025Z"
      fill="#E73B90"
    />
    <path
      d="M13.7862 8.63942C13.6317 8.63942 13.4814 8.54964 13.4104 8.39574C13.3101 8.18198 13.3978 7.92547 13.6066 7.82714L19.4033 4.97988C19.6121 4.87728 19.8626 4.96705 19.9587 5.18081C20.0589 5.39457 19.9712 5.65108 19.7624 5.74941L13.9658 8.59667C13.9073 8.6266 13.8447 8.63942 13.7862 8.63942V8.63942Z"
      fill="#E73B90"
    />
    <path
      d="M13.7862 12.6282C13.6859 12.6282 13.5857 12.5897 13.5063 12.517L3.90936 3.70162C3.73813 3.54344 3.72143 3.27411 3.87595 3.09883C4.03047 2.92354 4.29357 2.90644 4.4648 3.06462L13.853 11.6876L15.6321 10.8027V7.35262L6.56127 2.4148C6.35663 2.30365 6.28146 2.04714 6.39004 1.83765C6.49862 1.62817 6.7492 1.55122 6.95383 1.66237L16.2501 6.72417C16.388 6.79684 16.4715 6.9422 16.4715 7.10038V11.072C16.4715 11.2345 16.3796 11.3841 16.2376 11.4568L13.9699 12.5854C13.9114 12.6153 13.8488 12.6282 13.7862 12.6282V12.6282Z"
      fill="#E73B90"
    />
    <path
      d="M4.38971 15.3386C4.32706 15.3386 4.26442 15.3258 4.20595 15.2959C3.11595 14.7529 1.45799 13.9321 1.35358 13.8851C1.14895 13.791 1.05289 13.5516 1.13224 13.3379C1.21159 13.1241 1.44128 13.0172 1.6501 13.0856C1.68351 13.0984 1.71274 13.107 4.56511 14.5221C4.77392 14.6247 4.85745 14.8812 4.75722 15.095C4.68622 15.2489 4.53588 15.3344 4.38135 15.3344L4.38971 15.3386Z"
      fill="#E73B90"
    />
    <path
      d="M3.15347 16.1937C3.09083 16.1937 3.02819 16.1808 2.96972 16.1509C2.38922 15.8602 1.43704 15.3899 1.35352 15.3557C1.14888 15.2617 1.04865 15.0223 1.128 14.8085C1.20735 14.5948 1.43286 14.4836 1.64585 14.5563C1.67926 14.5691 1.7085 14.5777 3.33305 15.3857C3.54186 15.4883 3.62539 15.7448 3.52516 15.9585C3.45416 16.1124 3.30382 16.1979 3.1493 16.1979L3.15347 16.1937Z"
      fill="#E73B90"
    />
  </svg>
);

export default IconBox;
