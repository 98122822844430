import React from 'react';

const IconTrafic = () => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none">
    <path
      d="M15.0314 9.38733C14.1871 10.4355 13.1186 11.2812 11.9046 11.8622C10.6905 12.4432 9.36164 12.7448 8.0157 12.7448C6.66976 12.7448 5.34093 12.4432 4.12685 11.8622C2.91278 11.2812 1.84432 10.4355 1 9.38733C1.84457 8.33953 2.91311 7.49421 4.12715 6.91346C5.34119 6.3327 6.6699 6.03125 8.0157 6.03125C9.3615 6.03125 10.6902 6.3327 11.9043 6.91346C13.1183 7.49421 14.1868 8.33953 15.0314 9.38733Z"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.0145 11.6228C9.28349 11.6228 10.3122 10.594 10.3122 9.32505C10.3122 8.05606 9.28349 7.02734 8.0145 7.02734C6.74551 7.02734 5.7168 8.05606 5.7168 9.32505C5.7168 10.594 6.74551 11.6228 8.0145 11.6228Z"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.17001 9.3244C7.70778 9.3244 8.14373 8.88845 8.14373 8.35068C8.14373 7.8129 7.70778 7.37695 7.17001 7.37695C6.63224 7.37695 6.19629 7.8129 6.19629 8.35068C6.19629 8.88845 6.63224 9.3244 7.17001 9.3244Z"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.09473 16.1411L3.3901 13.8574"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.9611 16.2414L12.7334 14.0254"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.97656 17.6076V15.1955"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.07324 2.4668L3.36861 4.75049"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.8739 2.4295L12.6462 4.64548"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.95508 1V3.41212"
      stroke="#E73B90"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default IconTrafic;
