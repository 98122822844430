import React from 'react';

import PropTypes from 'prop-types';

import IconProfit from '../../assets/components/IconProfit';
import IconPayment from '../../assets/components/IconPayment';
import IconPoint from '../../assets/components/IconPoint';
import IconBox from '../../assets/components/IconBox';
import IconSecurity from '../../assets/components/IconSecurity';
import IconDelivery from '../../assets/components/IconDelivery';
import IconLoan from '../../assets/components/IconLoan';
import IconCredits from '../../assets/components/IconCredits';
import IconBudget from '../../assets/components/IconBudget';
import IconTrafic from '../../assets/components/IconTrafic';
import IconPlataform from '../../assets/components/IconPlataform';

const IconList = ({ text, icon }) => {
  const ListIcons = {
    IconProfit: <IconProfit />,
    IconPayment: <IconPayment />,
    IconPoint: <IconPoint />,
    IconBox: <IconBox />,
    IconSecurity: <IconSecurity />,
    IconDelivery: <IconDelivery />,
    IconLoan: <IconLoan />,
    IconCredits: <IconCredits />,
    IconBudget: <IconBudget />,
    IconTrafic: <IconTrafic />,
    IconPlataform: <IconPlataform />,
  };

  return (
    <li className="icon-list__item-list">
      <span className="icon-list__icon">
        {ListIcons[icon]}
      </span>
      <p className="icon-list__text">
        {text}
      </p>
    </li>
  );
};

IconList.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default IconList;
