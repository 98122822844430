const category = 'LANDING';
const action = 'GO_TO_BIOLIBRE';
const label = 'BANNER_BIOLIBRE';
const path = '/home/landing/go_to_biolibre';

export const TRACK_BIO = () => ({
  melidata: {
    path,
    eventData: {
      category: category.toLowerCase(),
      action: action.toLowerCase(),
      label: label.toLowerCase(),
    },
  },
  analytics: {
    category,
    action,
    label,
  },
});
