import React from 'react';

const IconDelivery = () => (
  <svg width="23" height="17" viewBox="0 0 23 17" fill="none">
    <path
      d="M1.25439 6.58136H7.28305"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.98486 8.67902H7.28307"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.00938 4.52637V2.44577C6.00531 2.13439 6.12477 1.83407 6.34161 1.61057C6.55846 1.38706 6.85503 1.25858 7.16639 1.25323H20.3325C20.6437 1.25858 20.94 1.38714 21.1564 1.61072C21.3728 1.8343 21.4917 2.13463 21.487 2.44577V15.0055C21.4911 15.3162 21.3719 15.6159 21.1555 15.8389C20.9391 16.0619 20.6432 16.1901 20.3325 16.1955H7.16639C6.85547 16.1901 6.55929 16.062 6.34252 15.839C6.12574 15.6161 6.00598 15.3164 6.00938 15.0055V12.8082"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15.0522 1.46613V9.42314L16.8715 8.58583L18.6654 9.42314V1.47881"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default IconDelivery;
