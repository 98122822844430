const urlCDV = {
  MLA: 'https://vendedores.mercadolibre.com.ar/',
  MLM: 'https://vendedores.mercadolibre.com.mx/',
  MLB: 'https://vendedores.mercadolivre.com.br/',
  MLC: 'https://vendedores.mercadolibre.cl/',
  MCO: 'https://vendedores.mercadolibre.com.co/',
};

const urlEbooks = {
  MLA: 'https://www.mercadoshops.com.ar/landing/ebooks',
  MLM: 'https://www.mercadoshops.com.mx/landing/ebooks',
  MLB: 'https://www.mercadoshops.com.br/landing/ebooks',
  MLC: 'https://www.mercadoshops.cl/landing/ebooks',
  MCO: 'https://www.mercadoshops.com.co/landing/ebooks',
};

const urlYoutube = {
  MLA: 'https://www.youtube.com/watch?v=xvwanaIiSoY&list=PLVzK2waPZa77_xc7IvQHWy46YqH1_rZp8',
  MLM: 'https://www.youtube.com/watch?v=HgX3suyjcTg&list=PLa0JQr6N65dU_l1lXcy-I8DnsKBESKhI6',
  MLB: 'https://www.youtube.com/watch?v=E8u5WELZQoE&list=PLPf_RPsE-VcNinDcbIyQMYCV9S4_gNcm9',
  MLC: 'https://www.youtube.com/watch?v=HgX3suyjcTg&list=PLa0JQr6N65dU_l1lXcy-I8DnsKBESKhI6',
  MCO: 'https://www.youtube.com/watch?v=HgX3suyjcTg&list=PLa0JQr6N65dU_l1lXcy-I8DnsKBESKhI6',
};

const urlWebinars = {
  MLA: 'https://mktdevzone.com/MercadoShops/download/webinar-prop-de-valor-y-configuracion.mp4',
  MLM: 'https://www.youtube.com/watch?v=ztIhNADsWaM',
  MLB: 'https://my.demio.com/recording/fDQCenqs',
  MLC: 'https://www.youtube.com/watch?v=YI0KNp7bM2c',
  MCO: 'https://www.youtube.com/watch?v=YI0KNp7bM2c',
};

const urlTrafic = {
  MLA: 'https://vendedores.mercadolibre.com.ar/nota/como-llevar-trafico-a-tu-mercado-shop-y-conseguir-ventas',
  MLM: 'https://vendedores.mercadolibre.com.mx/nota/como-llevar-trafico-a-tu-mercado-shop-y-conseguir-ventas',
  MLB: 'https://vendedores.mercadolivre.com.br/nota/como-gerar-trafego-em-seu-mercado-shop-e-conseguir-vendas',
  MLC: 'https://vendedores.mercadolibre.cl/nota/como-llevar-trafico-a-tu-mercado-shop-y-conseguir-ventas',
  MCO: 'https://vendedores.mercadolibre.com.co/nota/como-llevar-trafico-a-tu-mercado-shop-y-conseguir-ventas',
};

export default (i18n, siteId) => ({
  List:[
    {
      name: 'sellers',
      title: i18n.gettext('Central de Vendedores'),
      description: i18n.gettext('Contenido para convertirte en especialista y crecer.'),
      cta_text: i18n.gettext('Ver más'),
      cta_url: urlCDV[siteId],
      image: 'learn/img_sellers.jpg',
    },
    {
      name: 'youtube',
      title: i18n.gettext('Youtube'),
      description: i18n.gettext('Accede a nuestros videos tutoriales, webinars, tips y más para enterarte de todo.'),
      cta_text: i18n.gettext('Ver más'),
      cta_url: urlYoutube[siteId],
      image: 'learn/img_youtube.jpg',
    },
    {
      name: 'webinar',
      title: i18n.gettext('Webinars'),
      description: i18n.gettext('Súmate para enterarte de todas las novedades y aprender con especialistas.'),
      cta_text: i18n.gettext('Ver más'),
      cta_url: urlWebinars[siteId],
      image: 'learn/img_webinars.jpg',
    },
    {
      name: 'ebooks',
      title: i18n.gettext('E-books'),
      description: i18n.gettext('Encuentra toda la información que necesitas para convertirte en especialista y crecer.'),
      cta_text: i18n.gettext('Ver más'),
      cta_url: urlEbooks[siteId],
      image: 'learn/img_ebooks.jpg',
    },
    {
      name: 'trafic',
      title: i18n.gettext('Tráfico'),
      description: i18n.gettext('Usa herramientas de marketing y potencia tus ventas.'),
      cta_text: i18n.gettext('Ver más'),
      cta_url: urlTrafic[siteId],
      image: 'learn/img_trafic.jpg',
    },
  ]
});
