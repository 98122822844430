const LINK_BIOLIBRE = {
  MLA: 'http://www.mercadolibre.com.ar/biolibre?utm_source=mercadoshops&utm_medium=referral&utm_campaign=shops_landing&utm_content=banner_biolibre',
  MCO: 'http://www.mercadolibre.com.co/biolibre?utm_source=mercadoshops&utm_medium=referral&utm_campaign=shops_landing&utm_content=banner_biolibre',
  MLM: 'http://www.mercadolibre.com.mx/biolibre?utm_source=mercadoshops&utm_medium=referral&utm_campaign=shops_landing&utm_content=banner_biolibre',
  MLB: 'http://www.mercadolivre.com.br/biolivre?utm_source=mercadoshops&utm_medium=referral&utm_campaign=shops_landing&utm_content=banner_biolibre',
  MLC: 'http://www.mercadolibre.cl/biolibre?utm_source=mercadoshops&utm_medium=referral&utm_campaign=shops_landing&utm_content=banner_biolibre',
};

export default (i18n, siteId) => ({
  title: i18n.gettext('Con Bio Libre, convierte a tus seguidores en {0}compradores.{1}', '<strong class="bio-libre__title--bold">', '</strong>'),
  description: i18n.gettext('Crea gratis con Bio Libre una página con todos tus links y publícala en la biografía de tus redes sociales.'),
  button: i18n.gettext('Conoce Bio Libre'),
  url: LINK_BIOLIBRE[siteId],
  list: [
    {
      text: i18n.gettext('Personaliza tu Bio Libre y agrega todos los enlaces que desees: blogs, notas, eventos, productos y más.'),
    },
    {
      text: i18n.gettext('Si ya tienes tu Mercado Shops, destaca el enlace de tu tienda y muestra tus productos más vendidos.'),
    },
    {
      text: i18n.gettext('Comparte el enlace de tu Bio Libre en tus redes sociales como Instagram, TikTok, Twitter, WhatsApp y en todas tus redes sociales.'),
    },
    {
      text: i18n.gettext('Recibe dinero de tus seguidores y compradores a través de Mercado Pago.'),
    },
  ],
});
