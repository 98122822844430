import React, { useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import trackEvent from '../../../../../utils/tracking';
import { TRACK_PARTNERS } from '../tracks';

import IconArrow from '../../../assets/components/IconArrow';
import IconHeadset from '../../../assets/components/IconHeadset';
import constants from '../constants';

const TabSlider = ({ card, siteId }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    draggable: true,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  const { slides, AGENCY } = constants(null, siteId);

  const sliderTab = useRef();

  return (
    <div>
      <div className="tabs__card">
        <div className="tabs__card-head">
          <div className="tabs__card-content">
            <div className="tabs__card-title">{card.title}</div>
            {card.description && (
              <div className="tabs__card-description">{card.description}</div>
            )}
          </div>
          <div className="tabs__card-advice">
            <div className="tabs__card-advice-headset">
              <IconHeadset />
            </div>
            <div className="tabs__card-advice-padding">
              <div className="tabs__card-advice-title">{card.adviceTitle}</div>
              <div className="tabs__card-advice-subtitle">
                {card.adviceSubtitle}
              </div>
            </div>
          </div>
        </div>

        <div className="tabs__card-footer tabs__card-slide-footer">
          <div className="tabs__card-slide-title">{card.slideTitle}</div>
          <div className="tabs__card-slide-container">
            <Slider ref={sliderTab} {...settings}>
              {slides.map(({ img, link, name }) => (
                <a
                  key={`tabs-card-slide-img-${name}`}
                  onClick={() => trackEvent(TRACK_PARTNERS(AGENCY, name))}
                  href={link}
                  className="tabs__card-slide-img-container"
                >
                  <img
                    className="tabs__card-slide-img"
                    src={`https://http2.mlstatic.com/frontend-assets/mshops-web-landing${img}`}
                    alt=""
                  />
                </a>
              ))}
            </Slider>
            <div className="tabs__card-slide-arrows">
              <button
                type="button"
                onClick={() => sliderTab?.current?.slickPrev()}
                className="tabs__card-slide-arrow tabs__card-slide-arrow--left"
              >
                <IconArrow />
              </button>
              <button
                type="button"
                onClick={() => sliderTab?.current?.slickNext()}
                className="tabs__card-slide-arrow tabs__card-slide-arrow--right"
              >
                <IconArrow />
              </button>
            </div>
          </div>
          <a
            onClick={() => trackEvent(TRACK_PARTNERS(card.track))}
            href={card.alertBtnLink}
            className="tabs__card-slide-button"
            type="button"
          >
            {card.alertBtn}
          </a>
        </div>
      </div>
    </div>
  );
};

TabSlider.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    adviceTitle: PropTypes.string,
    adviceSubtitle: PropTypes.string,
    slideTitle: PropTypes.string,
    type: PropTypes.number,
    alertBtn: PropTypes.string,
    alertBtnLink: PropTypes.string,
    track: PropTypes.string,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default TabSlider;
