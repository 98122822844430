import React from 'react';

const IconPayment = () => (
  <svg width="22" height="18" fill="none" viewBox="0 0 22 18">
    <path
      d="M13.9442 13.5617V13.9091H13.6351V13.5617C13.2937 13.5465 12.9708 13.4017 12.7324 13.1568L12.9841 12.8067C13.1565 12.9911 13.3857 13.1124 13.6351 13.1514V12.4046C13.2357 12.3034 12.8145 12.1557 12.8145 11.6524C12.8145 11.2585 13.1345 10.9439 13.6351 10.9001V10.5527H13.9442V10.9165C14.2366 10.9401 14.512 11.0637 14.7238 11.2667L14.4503 11.6031C14.3088 11.4613 14.1275 11.3659 13.9305 11.3296V11.9943C14.3354 12.1037 14.7676 12.2678 14.7676 12.7739C14.7812 13.1732 14.5268 13.5152 13.9442 13.5617ZM13.6351 11.9204V11.3159C13.4135 11.3378 13.2795 11.4582 13.2795 11.6332C13.2795 11.8083 13.4299 11.8603 13.6351 11.9204ZM14.3217 12.8231C14.3217 12.6426 14.163 12.5496 13.9442 12.4976V13.1623C14.2123 13.124 14.3217 12.9681 14.3217 12.8231Z"
      fill="#E73B90"
    />
    <path
      d="M13.818 1.27344H2.06944C1.62982 1.27344 1.27344 1.62982 1.27344 2.06944V8.76844C1.27344 9.20806 1.62982 9.56444 2.06944 9.56444H13.818C14.2576 9.56444 14.614 9.20806 14.614 8.76844V2.06944C14.614 1.62982 14.2576 1.27344 13.818 1.27344Z"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.6142 8.55859H19.3246C19.5848 8.55932 19.8341 8.66301 20.0181 8.84702C20.2022 9.03102 20.3058 9.28038 20.3066 9.5406V14.3823C20.3051 14.6423 20.2012 14.8912 20.0174 15.0751C19.8335 15.2589 19.5846 15.3628 19.3246 15.3643H7.94803C7.81825 15.3654 7.68954 15.3408 7.5693 15.2919C7.44905 15.2431 7.33964 15.171 7.24737 15.0797C7.15509 14.9885 7.08176 14.8799 7.0316 14.7602C6.98144 14.6405 6.95543 14.512 6.95508 14.3823V9.64455"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.5782 6.52359H13.2681C13.3226 6.52359 13.3747 6.50198 13.4132 6.4635C13.4517 6.42503 13.4733 6.37285 13.4733 6.31844C13.4733 6.26403 13.4517 6.21184 13.4132 6.17337C13.3747 6.1349 13.3226 6.11328 13.2681 6.11328H2.5782C2.52379 6.11328 2.47161 6.1349 2.43314 6.17337C2.39466 6.21184 2.37305 6.26403 2.37305 6.31844C2.37305 6.37285 2.39466 6.42503 2.43314 6.4635C2.47161 6.50198 2.52379 6.52359 2.5782 6.52359V6.52359Z"
      fill="#E73B90"
    />
    <path
      d="M2.5782 3.36929H4.37536C4.42977 3.36929 4.48195 3.34768 4.52043 3.30921C4.5589 3.27073 4.58052 3.21855 4.58052 3.16414C4.58052 3.10973 4.5589 3.05755 4.52043 3.01907C4.48195 2.9806 4.42977 2.95898 4.37536 2.95898H2.5782C2.52379 2.95898 2.47161 2.9806 2.43314 3.01907C2.39466 3.05755 2.37305 3.10973 2.37305 3.16414C2.37305 3.21855 2.39466 3.27073 2.43314 3.30921C2.47161 3.34768 2.52379 3.36929 2.5782 3.36929V3.36929Z"
      fill="#E73B90"
    />
    <path
      d="M2.5782 7.87711H11.0579C11.1124 7.87711 11.1645 7.85549 11.203 7.81702C11.2415 7.77854 11.2631 7.72636 11.2631 7.67195C11.2631 7.61754 11.2415 7.56536 11.203 7.52688C11.1645 7.48841 11.1124 7.4668 11.0579 7.4668H2.5782C2.52379 7.4668 2.47161 7.48841 2.43314 7.52688C2.39466 7.56536 2.37305 7.61754 2.37305 7.67195C2.37305 7.72636 2.39466 7.77854 2.43314 7.81702C2.47161 7.85549 2.52379 7.87711 2.5782 7.87711V7.87711Z"
      fill="#E73B90"
    />
  </svg>
);

export default IconPayment;
