import React from 'react';

const IconArrowOutline = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.0758 6.6189L7.66793 9.91972L4.25638 6.6189"
      stroke="#E63C90"
      strokeMiterlimit="10"
    />
    <path
      d="M7.66795 15.0867C11.5586 15.0867 14.7126 12.0346 14.7126 8.26955C14.7126 4.50454 11.5586 1.45239 7.66795 1.45239C3.77729 1.45239 0.623291 4.50454 0.623291 8.26955C0.623291 12.0346 3.77729 15.0867 7.66795 15.0867Z"
      stroke="#E63C90"
      strokeMiterlimit="10"
    />
  </svg>
);

export default IconArrowOutline;
