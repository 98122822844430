export default i18n => ({
  title: i18n.gettext('Marcas que {0}confían{1} en Mercado Shops:', '<strong class="brands__title--bold">', '</strong>'),
  cards: [
    {
      brand: 'Friso',
      link: 'https://www.tiendafriso.com.mx/',
      logo: 'mlm/logo_friso.png',
      modal_image: 'mlm/screen_friso.jpg',
      button: i18n.gettext('Ver tienda'),
      track: 'FRISO',
    }, {
      brand: 'Wrangler',
      link: 'https://www.tienda.wrangler.com.mx/',
      logo: 'mlm/logo_wrangler.png',
      modal_image: 'mlm/screen_wrangler.jpg',
      button: i18n.gettext('Ver tienda'),
      track: 'WRANGLER',
    }, {
      brand: 'Montepio',
      link: 'https://www.tiendaluzsavinon.com/',
      logo: 'mlm/logo_montepio.png',
      modal_image: 'mlm/screen_montepio.png',
      button: i18n.gettext('Ver tienda'),
      track: 'MONTEPIO',
    }, {
      brand: 'Rheem',
      link: 'https://www.tienda.rheem.com.mx/',
      logo: 'mlm/logo_rheem.png',
      modal_image: 'mlm/screen_rheem.png',
      button: i18n.gettext('Ver tienda'),
      track: 'RHEEM',
    },
  ],
});
