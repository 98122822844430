import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import LearnGallery from '../LearnGallery';
import LearnHero from '../LearnHero';

const LearnPage = ({
  siteId,
  i18n,
  deviceType,
  isWebview,
}) => {
  const appClassName = classnames('App app_landing', { 'App--top': isWebview });

  return (
    <div className={appClassName}>
      <LearnHero i18n={i18n} deviceType={deviceType} />
      <LearnGallery i18n={i18n} siteId={siteId} />
    </div>
  );
};

LearnPage.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  isWebview: PropTypes.bool.isRequired,
};

export default LearnPage;
