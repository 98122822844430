import React from 'react';
import PropTypes from 'prop-types';

import PricesDesktop from './PricesDesktop';
import PricesMobile from './PricesMobile';

const Prices = ({ i18n, siteId, deviceType }) => {
  const PricesByDevice = {
    mobile: PricesMobile,
    tablet: PricesMobile,
    desktop: PricesDesktop,
  };

  const PricesComponent = PricesByDevice[deviceType];

  return (<PricesComponent i18n={i18n} siteId={siteId} />);
};

Prices.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Prices;
