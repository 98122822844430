import React from 'react';
import PropTypes from 'prop-types';

const IconTriangle = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="11"
    viewBox="0 0 20 11"
    fill="none"
  >
    <mask id="path-1-inside-1_2643_5939" fill="white">
      <path d="M0 0.259457L7.65002 10.1594C7.84476 10.4106 8.09226 10.616 8.37512 10.761C8.65798 10.906 8.96927 10.9871 9.28693 10.9986C9.60458 11.01 9.92088 10.9515 10.2134 10.8273C10.506 10.703 10.7677 10.516 10.98 10.2794L19.89 0.279415" />
    </mask>
    <path d="M0 0.259457L7.65002 10.1594C7.84476 10.4106 8.09226 10.616 8.37512 10.761C8.65798 10.906 8.96927 10.9871 9.28693 10.9986C9.60458 11.01 9.92088 10.9515 10.2134 10.8273C10.506 10.703 10.7677 10.516 10.98 10.2794L19.89 0.279415" fill="white" />
    <path d="M7.65002 10.1594L6.85967 10.7721L6.85874 10.7709L7.65002 10.1594ZM10.98 10.2794L11.7266 10.9447L11.7242 10.9474L10.98 10.2794ZM0.791283 -0.351994L8.44131 9.54797L6.85874 10.7709L-0.791283 0.870907L0.791283 -0.351994ZM8.44038 9.54677C8.54544 9.68231 8.67889 9.79303 8.83131 9.87116L7.91894 11.6509C7.50563 11.4391 7.14408 11.139 6.85967 10.7721L8.44038 9.54677ZM8.83131 9.87116C8.98381 9.94934 9.15167 9.99307 9.32294 9.99924L9.25091 11.9979C8.78688 11.9812 8.33215 11.8628 7.91894 11.6509L8.83131 9.87116ZM9.32294 9.99924C9.49415 10.0054 9.6647 9.97388 9.82249 9.90686L10.6044 11.7477C10.1771 11.9292 9.71501 12.0147 9.25091 11.9979L9.32294 9.99924ZM9.82249 9.90686C9.98019 9.83987 10.1213 9.73904 10.2358 9.61147L11.7242 10.9474C11.4141 11.2929 11.0318 11.5661 10.6044 11.7477L9.82249 9.90686ZM10.2334 9.61417L19.1434 -0.38583L20.6366 0.94466L11.7266 10.9447L10.2334 9.61417Z" fill="#E73B90" mask="url(#path-1-inside-1_2643_5939)" />
  </svg>

);

IconTriangle.propTypes = {
  className: PropTypes.string.isRequired,
};

export default IconTriangle;
