import React from 'react';

const ArrowAccordion = () => (
  <svg width="13" height="8" viewBox="0 0 13 8" fill="none">
    <path
      d="M1 1L6.75 7.13333L12.5 1"
      stroke="#0A0080"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowAccordion;
