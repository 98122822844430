/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import injectI18n from 'nordic/i18n/injectI18n';
import { ErrorScreen } from '@andes/technical-error';

/**
 * NotFoundView Component
 */
const Wrapper = (props) => {
  const { i18n } = props;
  return (
    <ErrorScreen
      title={i18n.gettext('Parece que esta página no existe')}
      action={{
        text: i18n.gettext('Volver atrás'),
        href: '/',
      }}
    />
  );
};

Wrapper.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
};

/**
 * Inject i18n context as props into HomeView.
 */
export default injectI18n(Wrapper);
