import React from 'react';

const ShapeBottom = () => (
  <svg x="0" fill="#fff" y="0" viewBox="0 0 1440 151.8">
    <path
      d="M-88,76.4C1.9,61.1,128.2,45.3,273,45.3c177.7,0,321.4,24.7,462.1,48.9c207.7,35.7,421.7,70.3,715.9,22.6 c1.1,0.6,0,36.4,0,36.4H-88V76.4z"
    />
  </svg>
);

export default ShapeBottom;
