import React from 'react';

const IconEbooks = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M1 23.8806C9.71364 22.9326 15.5186 25.889 15.5186 25.889V3.74848C15.5186 3.74848 9.86805 -0.722194 1 1.74009V23.8806Z"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0001 23.8806C20.9127 22.9326 15.5186 25.8889 15.5186 25.8889V3.74846C15.5186 3.74846 20.7655 -0.722209 29.0001 1.74008C29.0001 4.39918 29.0001 22.6755 29.0001 23.8806Z"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.11133 6.23786C4.11133 6.23786 9.49542 5.84072 12.4076 7.22196"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.11133 18.6826C4.11133 18.6826 9.49542 18.2854 12.4076 19.6667"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.11133 12.4602C4.11133 12.4602 9.49542 12.0631 12.4076 13.4443"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8886 6.23786C25.8886 6.23786 20.5045 5.84072 17.5923 7.22196"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8886 12.4602C25.8886 12.4602 20.5045 12.0631 17.5923 13.4443"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.8886 18.6826C25.8886 18.6826 20.5045 18.2854 17.5923 19.6667"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4811 28.9998C14.4811 28.9998 8.3698 26.2622 2.03662 27.0774"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5552 28.9998C16.5552 28.9998 22.6665 26.2622 28.9997 27.0774"
      stroke="white"
      strokeWidth="1.38889"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEbooks;
