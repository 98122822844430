import React from 'react';

const IconYoutube = () => (
  <svg
    width="33"
    height="27"
    viewBox="0 0 33 27"
    fill="none"
  >
    <path
      d="M24.9744 1H7.98859C4.1289 1 1 4.11487 1 7.95726V18.631C1 22.4734 4.1289 25.5882 7.98859 25.5882H24.9744C28.8341 25.5882 31.963 22.4734 31.963 18.631V7.95726C31.963 4.11487 28.8341 1 24.9744 1Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9337 18.3028V12.8388L11.9282 7.37473L16.9369 10.1016L21.9457 12.8285L16.9424 15.5657L11.9337 18.3028Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconYoutube;
