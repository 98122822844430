export default i18n => ({
  core: {
    title: i18n.gettext('Mercado Shops - Tu tienda online'),
    description: i18n.gettext(
      'Crea tu tienda online con Mercado Shops. Cuenta con todas las soluciones de Mercado Libre en tu tienda virtual. '
        + 'Mercado Pago incluido en tu tarifa. La única plataforma de ecommerce con Mercado Envíos Full.',
    ),
    keywords: i18n.gettext(
      'Ecommerce, tienda online, Mercado Shops, Mercado Libre, Mercado Pago, Mercado Envíos, vender por internet, '
        + 'Vender por instagram, vender por facebook, vender por Mercado Libre, Tienda Mercado Libre',
    ),
  },
  views: {
    notAvailable: {
      activationMessage: i18n.gettext('¡Tu tienda ya está activa!'),
      pageTitle: i18n.gettext('Mercado Shops'),
      title: i18n.gettext('Personaliza tu tienda desde de tu computador'),
      firstText: i18n.gettext(
        'Por el momento la edición de tu tienda no está disponible para dispositivos móviles. '
        + 'Personalízala desde tu computador en https://www.mercadolibre.com.ar/mercadoshops/.',
      ),
      secondText: i18n.gettext(
        'Si quieres, puedes ver cómo se está mostrando tu tienda frente a los clientes.',
      ),
      button: {
        text: i18n.gettext('Ver mi tienda'),
      },
    },
  },
});
