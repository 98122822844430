import MLA from './mla';
import MLM from './mlm';
import MCO from './mco';
import MLC from './mlc';
import MLB from './mlb';

const slides = { MLA, MLM, MCO, MLC, MLB };

export default (cardsData = null, siteId = 'MLA') => ({
  cards: (cardsData == null) ? [] : [
    (siteId === 'MLB' ? null : {
      id: 1,
      title: cardsData.card1.title,
      subtitle: cardsData.card1.subtitle,
      description: null,
      items: cardsData.card1.items,
      video: cardsData.card1.tallerVideo,
      count1Number: cardsData.card1.tallerCount1Number,
      count1Title: cardsData.card1.count1Title,
      count1Subtitle: cardsData.card1.count1Subtitle,
      count2Number: cardsData.card1.tallerCount2Number,
      count2Title: cardsData.card1.count2Title,
      count2Subtitle: cardsData.card1.count2Subtitle,
      alertIcon: 'landing30/icon_price.png',
      alertText: cardsData.card1.alertText,
      alertBtn: cardsData.card1.alertBtn,
      alertBtnLink: cardsData.card1.tallerBtnLink,
      experience: cardsData.card1.experience,
      type: 1,
      track: 'WORKSHOP',
    }),
    {
      id: siteId === 'MLB' ? 1 : 2,
      title: cardsData.card2.title,
      subtitle: cardsData.card2.subtitle,
      items: [],
      description: cardsData.card2.description,
      video: cardsData.card2.brigadaVideo,
      count1Number: cardsData.card2.brigadaCount1Number,
      count1Title: cardsData.card2.count1Title,
      count1Subtitle: cardsData.card2.count1Subtitle,
      count2Number: cardsData.card2.brigadaCount2Number,
      count2Title: cardsData.card2.count2Title,
      count2Subtitle: cardsData.card2.count2Subtitle,
      alertIcon: 'landing30/icon_message.png',
      alertText: cardsData.card2.alertText,
      alertBtn: cardsData.card2.alertBtn,
      alertBtnLink: cardsData.card2.brigadaBtnLink,
      type: 1,
      track: 'BRIGADE',
    },
    {
      id: siteId === 'MLB' ? 2 : 3,
      title: cardsData.card3.title,
      description: cardsData.card3.description,
      adviceTitle: cardsData.card3.adviceTitle,
      adviceSubtitle: cardsData.card3.adviceSubtitle,
      slideTitle: cardsData.card3.slideTitle,
      alertBtn: cardsData.card3.alertBtn,
      alertBtnLink: cardsData.card3.agenciaBtnLink,
      type: 2,
      track: 'PARTNERS',
    },
  ],
  slides: slides[siteId],
  AGENCY: 'AGENCY',
  tracks: {
    category: 'PARTNERS',
    path: {
      WORKSHOP: '/shops/partners/inscription_workshop',
      BRIGADE: '/shops/partners/inscription_brigade',
      PARTNERS: '/shops/partners/view_list',
      AGENCY: '/shops/partners/click_agency',
    },
    action: {
      WORKSHOP: 'INSCRIPTION',
      BRIGADE: 'INSCRIPTION',
      PARTNERS: 'VIEW_LIST',
      AGENCY: 'CLICK_AGENCY',
    },
    label: {
      WORKSHOP: 'WORKSHOP',
      BRIGADE: 'BRIGADE',
      PARTNERS: 'PARTNERS',
    },
  },
});
