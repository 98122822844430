import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';

import Card from './Card';
import wordings from './wordings';

const MeliProducts = ({ i18n, siteId }) => {
  const locales = wordings(i18n, siteId);
  const { title } = locales;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          infinite: true,
          dots: true,
        },
      }, {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section id="ecosystems" className="section-ecosystems">
        <div className="section-ecosystems__container">
          <div className="section-ecosystems__cont-title">
            <h2 className="section-ecosystems__title" dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div className="cards-section-ecosystems">
            <ul className="cards-section-ecosystems__cards-list">
              <Slider {...settings} slidesToShow={locales.cards.length <= 4 ? locales.cards.length : 4}>
                {locales.cards.map((item) => (
                  <li>
                    <Card
                      title={item.title}
                      description={item.description}
                      brand={item.brand}
                    />
                  </li>))}
              </Slider>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

MeliProducts.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default MeliProducts;
