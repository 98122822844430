export default i18n => ({
  slide: [
    {
      brand: 'El Orfanato',
      img: '/mlm/el_orfanato.png',
      url_card: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-diseno-web/el-orfanato-ideas-sin-madre',
      track: 'EL_ORFANATO',
    },
    {
      brand: 'Kubo Digital',
      img: '/mlm/kubo_digital.png',
      url_card: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-diseno-web/kubo-digital',
      track: 'KUBO_DIGITAL',
    },
    {
      brand: 'Sales Up',
      img: '/mlm/salesup.png',
      url_card: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-diseno-web/salesup',
      track: 'SALES_UP',
    },
    {
      brand: 'Suma',
      img: '/mlm/suma.png',
      url_card: 'https://centrodepartners.mercadolibre.com.mx/solutions/agencias-de-diseno-web/suma',
      track: 'SUMA',
    },
  ],
});
