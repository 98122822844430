/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

import injectI18n from 'nordic/i18n/injectI18n';

import ErrorScreen from '../ErrorScreen';
import wordings from './wordings';

/**
 * NotAvailableView Component
 */
const NotAvailableView = (props) => {
  const { i18n, shopUrl, isNewShop, shopId } = props;
  const locales = wordings(i18n);
  const { title, firstText, secondText, button, activationMessage } = locales.views.notAvailable;

  return (
    <ErrorScreen
      activationMessage={activationMessage}
      button={button}
      firstText={firstText}
      isNewShop={isNewShop}
      secondText={secondText}
      shopUrl={shopUrl}
      shopId={shopId}
      title={title}
    />
  );
};

NotAvailableView.propTypes = {
  i18n: PropTypes.shape({}).isRequired,
  shopUrl: PropTypes.string.isRequired,
  isNewShop: PropTypes.bool.isRequired,
  shopId: PropTypes.string.isRequired,
};

/**
 * Inject i18n context as props into HomeView.
 */
export default injectI18n(NotAvailableView);
