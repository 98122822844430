import React from 'react';

const IconGroup = () => (
  <svg width="29" height="25" viewBox="0 0 29 25" fill="none">
    <path d="M11.8781 24.6464C11.7817 24.6464 11.6891 24.6081 11.6209 24.5399C11.5527 24.4717 11.5144 24.3792 11.5144 24.2828V18.3637C11.5188 17.6946 11.3911 17.0313 11.1387 16.4116C10.8864 15.7919 10.5143 15.228 10.0438 14.7522C9.57331 14.2764 9.0136 13.898 8.39669 13.6386C7.77978 13.3793 7.11778 13.2441 6.44856 13.2408C5.77934 13.2441 5.11736 13.3793 4.50045 13.6386C3.88354 13.898 3.32383 14.2764 2.85332 14.7522C2.38281 15.228 2.01073 15.7919 1.75839 16.4116C1.50605 17.0313 1.37839 17.6946 1.3827 18.3637V24.2828C1.3827 24.3792 1.3444 24.4717 1.2762 24.5399C1.208 24.6081 1.11548 24.6464 1.01903 24.6464C0.922581 24.6464 0.830087 24.6081 0.761886 24.5399C0.693686 24.4717 0.655366 24.3792 0.655366 24.2828V18.3637C0.651057 17.5991 0.797548 16.8412 1.08644 16.1333C1.37534 15.4254 1.80096 14.7813 2.33901 14.238C2.87705 13.6947 3.51695 13.2627 4.2221 12.9668C4.92726 12.671 5.68382 12.5169 6.44856 12.5136C7.21329 12.5169 7.96988 12.671 8.67503 12.9668C9.38019 13.2627 10.0201 13.6947 10.5581 14.238C11.0962 14.7813 11.5218 15.4254 11.8107 16.1333C12.0996 16.8412 12.2461 17.5991 12.2418 18.3637V24.2828C12.2418 24.3792 12.2035 24.4717 12.1353 24.5399C12.0671 24.6081 11.9746 24.6464 11.8781 24.6464Z" fill="#0A0080" />
    <path d="M6.40183 13.2871C5.39615 13.2814 4.43384 12.8769 3.72612 12.1625C3.01841 11.4482 2.62311 10.4823 2.62698 9.4768C2.62408 8.47196 3.0198 7.50696 3.72741 6.79337C4.43502 6.07978 5.39679 5.67581 6.40183 5.67004C7.40688 5.67581 8.36862 6.07978 9.07623 6.79337C9.78385 7.50696 10.1796 8.47196 10.1767 9.4768C10.1805 10.4823 9.78524 11.4482 9.07752 12.1625C8.3698 12.8769 7.40751 13.2814 6.40183 13.2871ZM6.40183 6.37906C5.58969 6.38483 4.81291 6.71218 4.2417 7.28939C3.67049 7.86661 3.35141 8.64662 3.35431 9.4586C3.35044 10.2712 3.6691 11.0522 4.24042 11.6302C4.81173 12.2082 5.58906 12.536 6.40183 12.5418C7.21461 12.536 7.99191 12.2082 8.56323 11.6302C9.13455 11.0522 9.45321 10.2712 9.44933 9.4586C9.44744 8.64977 9.12622 7.8744 8.55553 7.30111C7.98483 6.72782 7.21083 6.40296 6.40183 6.39721V6.37906Z" fill="#0A0080" />
    <path d="M27.9845 24.6464C27.8881 24.6464 27.7956 24.6081 27.7274 24.5399C27.6592 24.4717 27.6209 24.3792 27.6209 24.2828V18.3637C27.6252 17.6946 27.4975 17.0313 27.2452 16.4116C26.9928 15.7919 26.6208 15.228 26.1503 14.7522C25.6798 14.2764 25.12 13.898 24.5031 13.6386C23.8862 13.3793 23.2242 13.2441 22.555 13.2408C21.8858 13.2441 21.2238 13.3793 20.6069 13.6386C19.99 13.898 19.4302 14.2764 18.9597 14.7522C18.4892 15.228 18.1172 15.7919 17.8648 16.4116C17.6125 17.0313 17.4848 17.6946 17.4891 18.3637V24.2828C17.4891 24.3792 17.4508 24.4717 17.3826 24.5399C17.3144 24.6081 17.2219 24.6464 17.1255 24.6464C17.029 24.6464 16.9365 24.6081 16.8683 24.5399C16.8001 24.4717 16.7618 24.3792 16.7618 24.2828V18.3637C16.7575 17.5991 16.904 16.8412 17.1929 16.1333C17.4818 15.4254 17.9074 14.7813 18.4455 14.238C18.9835 13.6947 19.6234 13.2627 20.3285 12.9668C21.0337 12.671 21.7903 12.5169 22.555 12.5136C23.3197 12.5169 24.0763 12.671 24.7815 12.9668C25.4866 13.2627 26.1265 13.6947 26.6646 14.238C27.2026 14.7813 27.6282 15.4254 27.9171 16.1333C28.206 16.8412 28.3525 17.5991 28.3482 18.3637V24.2828C28.3482 24.3792 28.3099 24.4717 28.2417 24.5399C28.1735 24.6081 28.081 24.6464 27.9845 24.6464Z" fill="#0A0080" />
    <path d="M22.5073 13.2871C21.5016 13.2814 20.5393 12.8769 19.8316 12.1625C19.1239 11.4482 18.7286 10.4823 18.7325 9.4768C18.7295 8.47196 19.1253 7.50696 19.8329 6.79337C20.5405 6.07978 21.5023 5.67581 22.5073 5.67004C23.512 5.67582 24.4734 6.0799 25.1804 6.79359C25.8875 7.50728 26.2824 8.4723 26.2785 9.4768C26.2824 10.4816 25.8876 11.447 25.1807 12.1612C24.4737 12.8755 23.5123 13.2804 22.5073 13.2871ZM22.5073 6.37906C21.6952 6.38483 20.9184 6.71218 20.3472 7.28939C19.776 7.86661 19.4569 8.64662 19.4598 9.4586C19.4559 10.2712 19.7746 11.0522 20.3459 11.6302C20.9172 12.2082 21.6945 12.536 22.5073 12.5418C23.3197 12.536 24.0967 12.208 24.6674 11.6299C25.2381 11.0518 25.556 10.2709 25.5512 9.4586C25.5502 8.6501 25.2298 7.87472 24.6597 7.30133C24.0896 6.72794 23.316 6.40296 22.5073 6.39721V6.37906Z" fill="#0A0080" />
    <path d="M14.4096 8.30077C13.404 8.295 12.4417 7.89057 11.734 7.1762C11.0262 6.46183 10.6309 5.49588 10.6348 4.49042C10.6309 3.4853 11.0264 2.51977 11.7342 1.80598C12.442 1.09218 13.4043 0.688527 14.4096 0.683716C15.4144 0.689487 16.3757 1.09357 17.0828 1.80726C17.7898 2.52096 18.1847 3.48593 18.1809 4.49042C18.1847 5.49526 17.7899 6.46064 17.083 7.17491C16.3761 7.88918 15.4147 8.29404 14.4096 8.30077V8.30077ZM14.4096 1.41088C13.5975 1.41665 12.8207 1.744 12.2495 2.32122C11.6783 2.89843 11.3592 3.67845 11.3621 4.49042C11.3583 5.30303 11.6769 6.08398 12.2482 6.66198C12.8196 7.23999 13.5969 7.56784 14.4096 7.5736C15.2221 7.56784 15.999 7.23987 16.5698 6.66176C17.1405 6.08365 17.4584 5.30269 17.4535 4.49042C17.4574 3.67878 17.1391 2.89876 16.5685 2.32144C15.9979 1.74412 15.2215 1.41665 14.4096 1.41088Z" fill="#0A0080" />
    <path d="M19.1876 10.5787C19.0958 10.5784 19.0074 10.5433 18.9403 10.4805C17.7052 9.35536 16.0907 8.73837 14.42 8.75306C12.8664 8.74449 11.3582 9.27679 10.1542 10.2586C10.1174 10.2893 10.075 10.3124 10.0293 10.3266C9.98364 10.3408 9.93562 10.3458 9.88799 10.3414C9.84036 10.337 9.79406 10.3233 9.75175 10.301C9.70944 10.2786 9.67194 10.2482 9.64141 10.2114C9.611 10.1744 9.58817 10.1318 9.57427 10.086C9.56036 10.0402 9.55564 9.99207 9.56037 9.94443C9.5651 9.89679 9.57919 9.85055 9.60184 9.80837C9.62448 9.76619 9.65524 9.7289 9.69233 9.69863C11.0258 8.60833 12.6975 8.01676 14.42 8.02573C16.2738 8.0114 18.0647 8.69714 19.4349 9.9459C19.469 9.97971 19.4961 10.0199 19.5145 10.0642C19.533 10.1086 19.5425 10.1561 19.5425 10.2041C19.5425 10.2521 19.533 10.2997 19.5145 10.344C19.4961 10.3883 19.469 10.4285 19.4349 10.4623C19.3708 10.5318 19.2821 10.5735 19.1876 10.5787Z" fill="#0A0080" />
  </svg>

);

export default IconGroup;
