import React from 'react';
import PropTypes from 'prop-types';

import Ecosystem from './Ecosystem';
import wordings from './wordings';

const EcosystemsBody = ({ i18n, siteId, deviceType }) => {
  const locales = wordings(i18n, siteId);

  return (
    <section className="ecosystems">
      {locales.ecosystemModules.map((item) => (
        <>
          {item.name && (
            <Ecosystem
              name={item.name}
              name_mlb={item.name_mlb}
              title={item.title}
              bgColor={item.bg_color}
              wavetop={item.wave_top}
              wavebottom={item.wave_bottom}
              order={item.order}
              image={item.image}
              button={item.button}
              track={item.track}
              siteId={siteId}
              iconList={item.iconList}
              deviceType={deviceType}
            />
          )}
        </>
      ))}
    </section>
  );
};

EcosystemsBody.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default EcosystemsBody;
