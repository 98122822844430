import constants from './constants';

const { AGENCY, tracks } = constants();
const {
  category,
  path,
  action,
  label,
} = tracks;

export const TRACK_PARTNERS = (type, agency = '') => ({
  melidata: {
    path: path[type],
    eventData: {
      category: category.toLowerCase(),
      action: action[type].toLowerCase(),
      label: type === AGENCY ? agency.toLowerCase() : label[type].toLowerCase(),
    },
  },
  analytics: {
    category,
    action: action[type],
    label: type === AGENCY ? agency : label[type],
  },
});
