import React from 'react';

const IconShops = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M34.7811 15.2738C36.2811 24.5438 29.9911 33.2738 20.7211 34.7738C11.4511 36.2738 2.72112 29.9838 1.22112 20.7238C-0.27888 11.4538 6.01112 2.72382 15.2811 1.22382C24.5411 -0.28618 33.2711 6.00382 34.7811 15.2738Z"
      fill="#E73B90"
      stroke="#E43B8D"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M16.9102 14.7438V13.4852C16.9099 13.366 16.9379 13.248 16.9926 13.1378C17.0473 13.0277 17.1276 12.9275 17.2289 12.8432C17.3302 12.7589 17.4506 12.692 17.5831 12.6463C17.7156 12.6006 17.8576 12.5771 18.0011 12.5771C18.1445 12.5771 18.2866 12.6006 18.4191 12.6463C18.5516 12.692 18.6719 12.7589 18.7732 12.8432C18.8746 12.9275 18.9549 13.0277 19.0096 13.1378C19.0643 13.248 19.0923 13.366 19.092 13.4852V14.7438"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3056 24.161H14.998C14.9014 24.1526 14.8076 24.124 14.7228 24.0772C14.638 24.0305 14.564 23.9665 14.5057 23.8895C14.4474 23.8125 14.406 23.7242 14.3843 23.6304C14.3626 23.5365 14.3609 23.4391 14.3796 23.3445L15.0348 16.0035C15.0459 15.8181 15.1248 15.6431 15.2569 15.5116C15.3889 15.38 15.5648 15.301 15.7515 15.2894H20.4515C20.6377 15.301 20.8134 15.3796 20.9453 15.5107C21.0773 15.6417 21.1565 15.8161 21.1681 16.0011L21.924 23.3421C21.9426 23.4368 21.941 23.5343 21.9193 23.6283C21.8976 23.7223 21.8563 23.8108 21.798 23.888C21.7397 23.9652 21.6657 24.0293 21.5809 24.0764C21.4961 24.1234 21.4023 24.1522 21.3056 24.161V24.161Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
   <path
      d="M10.2217 11.8956C11.4925 10.3294 13.1998 9.16977 15.1303 8.5614"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
   <path
      d="M8.531 21.7518C7.8719 19.9038 7.75864 17.9072 8.20457 15.9974"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3379 28.3836C14.422 28.0268 12.6479 27.1358 11.2231 25.8147"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.1062 24.9397C24.7591 26.5191 22.9624 27.6567 20.9521 28.2032"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.9048 14.7496C28.3332 15.8928 28.5518 17.1029 28.5503 18.3226C28.5499 19.1497 28.4493 19.9737 28.2508 20.7769"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9727 8.23962C21.9816 8.5766 23.8441 9.49969 25.323 10.8913"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.0458 25.0372C27.9594 25.0372 28.7 24.3018 28.7 23.3945C28.7 22.4873 27.9594 21.7518 27.0458 21.7518C26.1322 21.7518 25.3916 22.4873 25.3916 23.3945C25.3916 24.3018 26.1322 25.0372 27.0458 25.0372Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.18105 14.9567C10.0946 14.9567 10.8352 14.2212 10.8352 13.314C10.8352 12.4067 10.0946 11.6713 9.18105 11.6713C8.26746 11.6713 7.52686 12.4067 7.52686 13.314C7.52686 14.2212 8.26746 14.9567 9.18105 14.9567Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.9848 14.8495C27.8983 14.8495 28.639 14.114 28.639 13.2068C28.639 12.2996 27.8983 11.5641 26.9848 11.5641C26.0712 11.5641 25.3306 12.2996 25.3306 13.2068C25.3306 14.114 26.0712 14.8495 26.9848 14.8495Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.24257 25.1444C10.1562 25.1444 10.8968 24.409 10.8968 23.5017C10.8968 22.5945 10.1562 21.859 9.24257 21.859C8.32899 21.859 7.58838 22.5945 7.58838 23.5017C7.58838 24.409 8.32899 25.1444 9.24257 25.1444Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1151 9.79457C19.0287 9.79457 19.7693 9.0591 19.7693 8.15186C19.7693 7.24462 19.0287 6.50916 18.1151 6.50916C17.2015 6.50916 16.4609 7.24462 16.4609 8.15186C16.4609 9.0591 17.2015 9.79457 18.1151 9.79457Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.1151 30.1993C19.0287 30.1993 19.7693 29.4639 19.7693 28.5566C19.7693 27.6494 19.0287 26.9139 18.1151 26.9139C17.2015 26.9139 16.4609 27.6494 16.4609 28.5566C16.4609 29.4639 17.2015 30.1993 18.1151 30.1993Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconShops;
