import React from 'react';
import PropTypes from 'prop-types';

import IconColors from '../../assets/components/IconColors';
import IconSections from '../../assets/components/IconSections';
import IconTemplates from '../../assets/components/IconTemplates';
import IconBanners from '../../assets/components/IconBanners';

const CardList = ({ title, description, descriptionMx, icons, siteId }) => {
  const namespace = 'design-cols-cont__icono';
  const cardListIcons = {
    banners: <IconBanners className={namespace} />,
    colors: <IconColors className={namespace} />,
    sections: <IconSections className={namespace} />,
    templates: <IconTemplates className={namespace} />,
  };

  return (
    <li className="design-cols-cont__icon-list-item">
      {cardListIcons[icons]}
      <div className="design-cols-cont__text-cont">
        <h3 className="design-cols-cont__title">
          {title}
        </h3>
        <p className="design-cols-cont__text">
          { descriptionMx ? (
            siteId === 'MLM' || siteId === 'MCO' ? descriptionMx : description
          ) : description }
        </p>
      </div>
    </li>
  );
};

CardList.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icons: PropTypes.string.isRequired,
};

export default CardList;
