export default i18n => ({
  slide: [
    {
      title: 'Doppler',
      subtitle: 'Email Marketing',
      img: '/mla/doppler.png',
      url_card: 'https://centrodepartners.mercadolibre.com.mx/apps/doppler',
      track: 'DOPPLER',
    },
    {
      title: 'Mailup',
      subtitle: i18n.gettext('Email Marketing'),
      img: '/mla/mailup.png',
      url_card: 'https://centrodepartners.mercadolibre.com.mx/apps/mailup',
      track: 'MAILUP',
    },
    {
      title: 'Perfit',
      subtitle: i18n.gettext('Email Marketing'),
      img: '/mla/perfit.png',
      url_card: 'https://centrodepartners.mercadolibre.com.mx/apps/perfit-mshops',
      track: 'PERFIT',
    },
  ],
});
