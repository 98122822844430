import React from 'react';

const IconPoint = () => (
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none">
    <path
      d="M18.0985 16.5114L16.9794 18.0975C16.9453 18.1461 16.9005 18.1864 16.8485 18.2152C16.7966 18.244 16.7387 18.2606 16.6794 18.2638C16.62 18.267 16.5608 18.2567 16.506 18.2336C16.4512 18.2106 16.4024 18.1753 16.3632 18.1307L14.3903 16.3208L12.4838 18.1693C12.4469 18.212 12.4013 18.2463 12.3501 18.2697C12.2988 18.2932 12.2431 18.3053 12.1867 18.3053C12.1303 18.3053 12.0746 18.2932 12.0234 18.2697C11.9721 18.2463 11.9265 18.212 11.8897 18.1693L9.89468 16.3208L8.23679 18.0782C8.19839 18.128 8.14858 18.1679 8.09155 18.1944C8.03451 18.2209 7.97194 18.2334 7.90909 18.2307C7.84625 18.228 7.78497 18.2102 7.73044 18.1788C7.6759 18.1475 7.6297 18.1035 7.59573 18.0505L6.4435 16.2545C6.2754 15.9943 6.18615 15.6911 6.18652 15.3813V2.47736C6.18652 2.15858 6.31316 1.85285 6.53857 1.62744C6.76399 1.40203 7.06971 1.27539 7.3885 1.27539H17.3359C17.6546 1.27539 17.9604 1.40203 18.1858 1.62744C18.4112 1.85285 18.5378 2.15858 18.5378 2.47736V15.1686C18.5336 15.6508 18.3801 16.1199 18.0985 16.5114Z"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6302 16.7383V18.714C14.6306 19.0663 14.5215 19.4101 14.318 19.6977L13.0856 21.4468C13.0515 21.4954 13.0067 21.5357 12.9547 21.5645C12.9028 21.5933 12.8449 21.6099 12.7856 21.6131C12.7262 21.6163 12.667 21.606 12.6122 21.5829C12.5574 21.5598 12.5086 21.5246 12.4694 21.4799L10.5131 19.5872L8.57891 21.5214C8.54207 21.5641 8.49648 21.5983 8.44522 21.6218C8.39396 21.6452 8.33825 21.6574 8.28187 21.6574C8.22549 21.6574 8.16978 21.6452 8.11852 21.6218C8.06726 21.5983 8.02166 21.5641 7.98483 21.5214L6.00364 19.5872L4.31812 21.4164C4.27981 21.4661 4.23007 21.5058 4.17312 21.5321C4.11618 21.5584 4.05372 21.5706 3.99105 21.5676C3.92838 21.5647 3.86736 21.5466 3.81317 21.515C3.75899 21.4833 3.71325 21.4391 3.67983 21.386L2.56351 19.6369C2.37964 19.343 2.28376 19.0026 2.2872 18.656V5.82111C2.2872 5.50233 2.41384 5.1966 2.63925 4.97119C2.86466 4.74578 3.17039 4.61914 3.48917 4.61914H6.18325"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16211 6.8418H15.534"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16211 8.83594H15.534"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.16211 10.832H12.348"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconPoint;
