import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';
import classnames from 'classnames';

import IconSellers from '../../assets/components/IconSellers';
import IconWebinar from '../../assets/components/IconWebinar';
import IconLearnTrafic from '../../assets/components/IconLearnTrafic';
import IconYoutube from '../../assets/components/IconYoutube';
import IconEbooks from '../../assets/components/IconEbooks';
import trackEvent from '../../../../utils/tracking';
import { TRACK_LEARN } from './track';

const LearnCard = ({ title, image, description, cta, url, name }) => {
  const trackLearn = () => {
    trackEvent(TRACK_LEARN(name));
  };

  const IconCard = {
    webinar: <IconWebinar />,
    sellers: <IconSellers />,
    ebooks: <IconEbooks />,
    trafic: <IconLearnTrafic />,
    youtube: <IconYoutube />,
  };

  const [showCard, setShowCard] = useState(false);

  const classShow = classnames('learning-card',
    { 'show-card': showCard },
    { 'hide-card': !showCard });

  const ShowImage = classnames('learning-card__image-card',
    { 'hide-image': !showCard });

  const ShowText = classnames('learning-card__text-card',
    { 'show-text': showCard });

  return (
    <div
      className={classShow}
      onClick={() => setShowCard(true)}
      onMouseLeave={() => setShowCard(false)}
    >
      <div className={ShowImage}>
        <Image
          className="learning-card__card-img"
          src={image}
          alt=""
        />
      </div>
      <div className="learning-card__content-card">
        <div className="learning-card__content-title">
          <div className="learning-card__content-icon">
            {IconCard[name]}
          </div>
          <h2 className="learning-card__title">
            {title}
          </h2>
        </div>
        <div className={ShowText}>
          <p className="learning-card__description">
            {description}
          </p>
          <div className="learning-card__cont-btn">
            {url && (
              <a
                href={url}
                className="learning-card__card-btn"
                rel="noreferrer"
                target="_blank"
                onClick={() => { trackLearn(name); }}
              >
                {cta}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LearnCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default LearnCard;
