export default i18n => ({
  title: i18n.gettext('Marcas que {0}confían{1} en Mercado Shops:', '<strong class="brands__title--bold">', '</strong>'),
  cards: [
    {
      brand: 'Eforest',
      link: 'https://www.eforest.cl/',
      logo: 'mlc/logo_eforest.png',
      modal_image: 'mlc/screen_eforest.png',
      button: i18n.gettext('Ver tienda'),
      track: 'EFOREST',
    }, {
      brand: 'Lhua Store',
      link: 'https://lhua-store.cl/',
      logo: 'mlc/logo_lhua_store.png',
      modal_image: 'mlc/screen_lhua_store.png',
      button: i18n.gettext('Ver tienda'),
      track: 'LHUA_STORE',
    }, {
      brand: 'Happy Store',
      link: 'https://www.happystore.cl/',
      logo: 'mlc/logo_happy_store.png',
      modal_image: 'mlc/screen_happy_store.png',
      button: i18n.gettext('Ver tienda'),
      track: 'HAPPY_STORE',
    },
  ],
});
