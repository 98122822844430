export default i18n => ({
  category_list: [
    {
      title: i18n.gettext('Configuraciones de tu Shop'),
      category: 'config',
      List: [
        {
          title: i18n.gettext('Super Guia Mercado Shops'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1r6oES6HIP3OuvmxKwrClOczLnEZ7a0GS/view',
          track: 'mshops_guide',
          image: 'mlb/super_guia_mshops.jpg',
        },
        {
          title: i18n.gettext('Glosario digital: no te pierdas en el mundo del ecommerce'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1MKpdQe7Y70FqXvuhd3Ss7nVpyO5BVilR/view?usp=share_link',
          track: 'digital_glossary',
          image: 'mlb/glosario_digital.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Estrategias de Tráfico'),
      category: 'trafic',
      List: [
        {
          title: i18n.gettext('¿Cómo llevar tráfico a tu tienda?'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1pu_B7uuLeXj4F7GDoWjaATm_LRrl3uKZ/view?usp=share_link',
          track: 'getting_traffic',
          image: 'mlb/trafico_tienda.jpg',
        },
        {
          title: i18n.gettext('Template RRSS'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://www.canva.com/design/DAFP978yaVI/W_s3oDpHDq7uyV7QMQacvw/view?utm_content=DAFP978yaVI&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
          track: 'rrss_templates',
          image: 'mlb/template_rrss.jpg',
        },
        {
          title: i18n.gettext('Cómo aprovechar las ventas de tu marketplace para potenciar tu tienda'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1gC6SHBkpZlSIRd6ToFI7ZXJMBG_aKDOv/view?usp=share_link',
          track: 'improve_your_shop',
          image: 'mlb/aprovechar_ventas.jpg',
        },
        {
          title: i18n.gettext('Superguía de Mercado Ads'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1ZCxQn-hKstzoNunGRR7xg9R2X0QrT1E2/view',
          track: 'mads_guide',
          image: 'mlb/superguia_mads.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Fidelización y ventas'),
      category: 'sales',
      List: [
        {
          title: i18n.gettext('Calendario fechas especiales 2023'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1jmEoZg4_bPaPbHMaQnl-pSNF0tSciRd5/view?usp=share_link',
          track: 'ecommerce_calendar',
          image: 'mlb/calendario_fechas_especiales.jpg',
        },
        {
          title: i18n.gettext('Check List Hot Sale / Cyber Day'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://docs.google.com/spreadsheets/d/1ix52PSN3100gTUetHt_c_Gm83vXE6Uu-gd7Y47kU3l0/edit',
          track: 'checklist_hotsale_cyberday',
          image: 'mlb/checklist_hs_cd.jpg',
        },
        {
          title: i18n.gettext('Guía Cyber Monday'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/drive/folders/1bY77xgAkZ049JurHexH4v1Mkm8_LY2db',
          track: 'cybermonday_guide',
          image: 'mlb/guia_cyber_monday.jpg',
        },
        {
          title: i18n.gettext('Planer Cyber Monday'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://docs.google.com/spreadsheets/d/1DvhwctZA-XgIZlnWYl-aehWhQHnq-3mnF_NNhwqO5gI/edit?usp=sharing',
          track: 'planner_cybermonday',
          image: 'mlb/planer_cyber_monday.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Personalización'),
      category: 'custom',
      List: [
        {
          title: i18n.gettext('Banners descargables'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://www.canva.com/design/DAFP-H1E3dg/W2Bc9A0b8SQ5FAtIVwhNAA/view?utm_content=DAFP-H1E3dg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview',
          track: 'download_banners',
          image: 'mlb/banners_descargables.jpg',
        },
      ],
    },
  ],
});
