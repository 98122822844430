import React from 'react';
import PropTypes from 'prop-types';

import wordings from './wordings';
import EbookCategory from './EbooksCategory';

const EbooksGallery = ({ i18n, deviceType, siteId }) => {
  const locales = wordings(i18n, siteId);

  return (
    <section className="ebooks-gallery">
      {locales.category_list.map((item, i) => (
        <EbookCategory
          key={i}
          index={i}
          title={item.title}
          category={item.category}
          cards={item.List}
          deviceType={deviceType}
        />
      ))}
    </section>
  );
};

EbooksGallery.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default EbooksGallery;
