import React from 'react';

const IconLoan = () => (
  <svg width="29" height="27" viewBox="0 0 27 27" fill="none">
    <path
      d="M16.9969 10.3933C17.0319 6.98939 10.5735 7.04679 10.5635 10.3733C10.813 14.3662 16.8647 11.5163 17.1192 15.5191C17.0993 18.8831 10.6309 18.913 10.6683 15.4792"
      stroke="#E73B90"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.8418 6.33203V19.4635"
      stroke="#E73B90"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.6478 12.9629C24.6478 15.8386 23.5054 18.5966 21.4719 20.6301C19.4384 22.6636 16.6804 23.8059 13.8047 23.8059"
      stroke="#E73B90"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.96875 12.9642C2.96875 10.0884 4.11114 7.33042 6.14461 5.29695C8.17808 3.26348 10.9361 2.12109 13.8118 2.12109"
      stroke="#E73B90"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.95281 11.4399L2.97885 13.2242L1.18457 11.2852"
      stroke="#E73B90"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.7842 14.3188L24.6109 12.3848L26.5524 14.1766"
      stroke="#E73B90"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default IconLoan;
