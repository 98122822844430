import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';

import wordings from './wordings';

const LearnHero = ({ i18n, deviceType }) => {
  const { title, description } = wordings(i18n);

  return (
    <section className="learn-hero">
      <div className="learn-hero__cont-hero">
        <div className="learn-hero__content">
          <h1 dangerouslySetInnerHTML={{ __html: title }} className="learn-hero__title" />
          <p className="learn-hero__text">{description}</p>
        </div>
        {deviceType === 'desktop' && (
          <div className="learn-hero__image">
            <Image
              className="learn-hero__img-class"
              src="learn/hero_learning.png"
              alt=""
            />
          </div>
        )}
      </div>
    </section>
  );
};

LearnHero.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default LearnHero;
