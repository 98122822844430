/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const UnavailableDevice = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="240" height="106" viewBox="0 0 240 106">
    <defs>
      <path id="hr4qdpvzua" d="M3.311.309C1.573.309.164 1.79.164 3.619v46.8c0 1.828 1.409 3.31 3.147 3.31h24.995c1.74 0 3.148-1.482 3.148-3.31V3.62c0-1.829-1.408-3.31-3.148-3.31H3.311z" />
      <path id="2e6usqhz2b" d="M28.306 3.901H3.311C1.573 3.901.164 2.191.164.083v2.785c0 2.109 1.409 3.818 3.147 3.818h24.995c1.74 0 3.148-1.709 3.148-3.818V.083c0 2.109-1.408 3.818-3.148 3.818z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path stroke="#000" strokeLinecap="round" strokeOpacity=".102" strokeWidth="2" d="M18 105h220M0 105h9" transform="translate(-60 -104) translate(61 104)" />
          </g>
          <g>
            <g transform="translate(-60 -104) translate(61 104) translate(26 18) translate(0 31.004)">
              <g transform="translate(0 -.37)" />
              <path fill="#EDEDED" fillRule="nonzero" d="M27.881 50.47H3.118C1.396 50.47 0 48.915 0 47v2.531C0 51.447 1.396 53 3.118 53H27.88C29.605 53 31 51.447 31 49.53V47c0 1.916-1.395 3.47-3.119 3.47" />
              <g>
                <g transform="translate(0 47) translate(0 -.37)">
                  <use fill="#EDEDED" xlinkHref="#2e6usqhz2b" />
                </g>
              </g>
              <g stroke="#231F20" strokeWidth="2">
                <path d="M13.127 48.116h4.746M28.647 53H2.353C1.053 53 0 51.906 0 50.556V2.445C0 1.095 1.053 0 2.353 0h26.294C29.947 0 31 1.094 31 2.444v48.112C31 51.906 29.947 53 28.647 53z" transform="translate(0 1)" />
              </g>
              <rect width="22.853" height="37" x="4.074" y="5.266" fill="#000" fillOpacity=".1" rx="1" />
            </g>
            <path fill="#000" fillOpacity=".1" d="M78.877 79L127.46 79 127.46 75 78.877 75z" transform="translate(-60 -104) translate(61 104) translate(26 18)" />
            <path fill="#FFF" fillRule="nonzero" d="M121.831 75c-.037 2.354-1.893 4.245-4.174 4.253H87.812c-2.282-.008-4.138-1.898-4.176-4.252H28.144v1.06c0 4.937 3.88 8.939 8.664 8.939h131.85c2.297 0 4.501-.942 6.126-2.618 1.625-1.677 2.538-3.951 2.538-6.322V75h-55.49z" transform="translate(-60 -104) translate(61 104) translate(26 18)" />
            <path fill="#000" fillOpacity=".1" d="M118.293 79c-.21.04-.423.063-.636.069H87.81c-.217 0-.427-.035-.637-.069H28.7c1.23 3.502 4.39 6 8.107 6h131.85c3.614-.001 6.847-2.393 8.11-6h-58.476.001z" transform="translate(-60 -104) translate(61 104) translate(26 18)" />
            <path stroke="#231F20" strokeWidth="2" d="M121.687 75c-.037 2.354-1.893 4.245-4.174 4.253H87.667c-2.281-.008-4.137-1.898-4.175-4.252H28v1.06C28 80.998 31.88 85 36.664 85h131.85c2.297 0 4.501-.942 6.126-2.618 1.625-1.677 2.538-3.951 2.538-6.322V75h-55.491 0z" transform="translate(-60 -104) translate(61 104) translate(26 18)" />
            <path stroke="#333" strokeWidth="2" d="M51.938 75L154.401 75 154.401 9 51.938 9z" transform="translate(-60 -104) translate(61 104) translate(26 18)" />
            <path stroke="#231F20" strokeWidth="2" d="M163.865 75H41.602V7.137C41.602 3.212 44.827 0 48.768 0h107.93c3.942 0 7.167 3.212 7.167 7.137V75z" transform="translate(-60 -104) translate(61 104) translate(26 18)" />
          </g>
          <path className="icon__computer-nav" fill="#DCDCDC" d="M79 28H180V35H79z" transform="translate(-60 -104) translate(61 104)" />
          <path stroke="#000" strokeLinecap="round" strokeOpacity=".8" strokeWidth="1.4" d="M39.095 46C38.21 34 43.511 28 55 28" transform="translate(-60 -104) translate(61 104)" />
          <path fill="#F5F5F5" d="M35 41H44V48H35z" transform="translate(-60 -104) translate(61 104)" />
          <path stroke="#313131" strokeLinecap="round" strokeWidth="1.5" d="M52 24.713l3.786 3.06c.107.087.124.245.037.352-.005.007-.01.013-.016.018L52.01 32h0" transform="translate(-60 -104) translate(61 104)" />
        </g>
      </g>
    </g>
  </svg>
);

UnavailableDevice.propTypes = {
  className: PropTypes.string,
};

UnavailableDevice.defaultProps = {
  className: '',
};

export default UnavailableDevice;
