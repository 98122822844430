import React from 'react';
import PropTypes from 'prop-types';
import injectI18n from 'nordic/i18n/injectI18n';

import CoreView from './CoreView';
import ErrorView from './ErrorView';
import NotFoundView from './NotFoundView';
import NotAvailableView from './NotAvailableView';
import Landing from '../Landing';
import PartnersPage from '../PartnersPage';
import EcosystemsPage from '../EcosystemsPage';
import EbooksPage from '../EbooksPage';
import LearnPage from '../LearnPage';

const VIEWS = {
  ERROR: ErrorView,
  NOT_FOUND: NotFoundView,
  NOT_AVAILABLE: NotAvailableView,
  LANDING_3_0: Landing,
  EXPERTS: PartnersPage,
  ECOSYSTEM: EcosystemsPage,
  EBOOKS: EbooksPage,
  LEARN: LearnPage,
};

const LandingView = props => {
  const {
    viewName,
    siteId,
    pricing,
    i18n,
    shopId,
    shopName,
    shopStatus,
    commonTrackData,
    deviceType,
    isWebview,
    locale,
  } = props;
  const View = VIEWS[viewName];
  return (
    <CoreView {...props}>
      <View
        siteId={siteId}
        viewName={viewName}
        pricing={pricing}
        i18n={i18n}
        shopId={shopId}
        shopName={shopName}
        shopStatus={shopStatus}
        deviceType={deviceType}
        isWebview={isWebview}
        commonTrackData={commonTrackData}
        state={{ locale }}
      />
    </CoreView>
  );
};

LandingView.propTypes = {
  siteId: PropTypes.string.isRequired,
  viewName: PropTypes.string.isRequired,
  pricing: PropTypes.shape().isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  shopId: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  commonTrackData: PropTypes.shape({
    shopId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    shopName: PropTypes.string,
    shopDomain: PropTypes.string,
  }).isRequired,
  shopStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  isWebview: PropTypes.bool.isRequired,
};

/**
  * Inject i18n context as props into HomeView.
  */
export default injectI18n(LandingView);
