import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';
import Button from '@andes/button';

import IconCheck from '../../assets/components/IconCheck';
import wordings from './wordings';
import trackEvent from '../../../../utils/tracking';
import { TRACK_HERO } from './tracks';

const Hero = ({ i18n, deviceType, siteId }) => {
  const locales = wordings(i18n);
  const { title, button } = locales;
  const trackHero = () => trackEvent(TRACK_HERO());

  const DeskopImage = {
    MLA: 'landing30/hero_image_mobile.png',
    MLB: 'landing30/hero_desktop_br.png',
    MLC: 'landing30/hero_image_mobile.png',
    MLM: 'landing30/hero_image_mobile.png',
    MCO: 'landing30/hero_image_mobile.png',
  }

  const MobileImage = {
    MLA: 'landing30/hero_image.png',
    MLB: 'landing30/hero_mobile_br.png',
    MLC: 'landing30/hero_image.png',
    MLM: 'landing30/hero_image.png',
    MCO: 'landing30/hero_image.png',
  }

  return (
    <section id="main-slider" className="hero">
      <div className="hero__container">
        <div className="hero__cont-text">
          <h1 className="hero__headline">
            {title}
          </h1>
          <ul>
            {locales.list.map((item, key) => (
              <li key={key} className="hero__item-list">
                <IconCheck className="hero__item-list-ico"/>
                <p className="hero__copy-text">{item.text}</p>
              </li>
            ))}
          </ul>
          <Button onClick={trackHero} variant="danger" type="submit" className="mercadoshops-button button-hero" size="large">
            {button}
          </Button>
        </div>
        <div className="hero__cont-img">
          { deviceType === 'desktop' ? (
              <Image className="hero__img-mobile" src={DeskopImage[siteId]} alt="" />
            ) : (
              <Image className="hero__img-mobile" src={MobileImage[siteId]} alt="" />
            )
          }
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Hero;
