export default i18n => ({
  prices: {
    title: i18n.gettext('Mercado Shops es la plataforma {0}más competitiva.{1}', '<strong class="prices__headline-bold">', '</strong>'),
    description: i18n.gettext('Tu tienda online gratis: sin costos de activación, ni de mantenimiento. {0}¡Sin planes, ni límites!{1}',
      '<br> <strong class="prices__paragraph-bold">',
      '</strong>'),
    subtitle: i18n.gettext('Compara Mercado Shops con:'),
    ShowTable: i18n.gettext('Ver detalle de costos'),
    HideTable: i18n.gettext('Ocultar detalle de costos'),
  },
});
