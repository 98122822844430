import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';

import wordings from './wordings';
import ItemsList from './ItemsList';

const AboutMs = ({ i18n, siteId }) => {
  const ImageCall = 'landing30/';
  const locales = wordings(i18n);
  const { title, description } = locales;

  return (
    <section id="AboutMs" className="about-ms">
      <div className="about-ms__container">
        <div className="about-ms__cont-img">
          { siteId !== 'MLB' ? (
            <Image src={`${ImageCall}img_about.png`} className="about-ms__img-desktop" alt="" />
          ) : (
            <Image src={`${ImageCall}img_about_br.png`} className="about-ms__img-desktop" alt="" />)
          }
        </div>
        <div className="about-ms__cont-text">
          <h2 className="about-ms__headline" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="about-ms__copy-text" dangerouslySetInnerHTML={{ __html: description }} />
          {locales.List.map((item) => <ItemsList text={item.text} icons={item.icons} />)}
        </div>
      </div>
    </section>
  );
};

AboutMs.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default AboutMs;
