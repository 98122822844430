export default i18n => ({
  category_list: [
    {
      title: i18n.gettext('Configuraciones de tu Shop'),
      category: 'config',
      List: [
        {
          title: i18n.gettext('Super Guia Mercado Shops'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://bit.ly/ShopsMX_SuperGuia3',
          track: 'mshops_guide',
          image: 'mla/super_guia_mshops.jpg',
        },
        {
          title: i18n.gettext('Glosario digital: no te pierdas en el mundo del ecommerce'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1r_uci8nT7M8QLBWPHXlUyiFo33Ltd4JH/view',
          track: 'digital_glossary',
          image: 'mla/glosario_digital.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Estrategias de Tráfico'),
      category: 'trafic',
      List: [
        {
          title: i18n.gettext('Superguía de Mercado Ads'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1dLG-5wX3_PMQAI6UF9fHjdt29utqffHA/view',
          track: 'mads_guide',
          image: 'mla/superguia_mads.jpg',
        },
      ],
    },
    {
      title: i18n.gettext('Fidelización y ventas'),
      category: 'sales',
      List: [
        {
          title: i18n.gettext('Calendario fechas especiales 2023'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/15P_WDjQP392QI9APUhojCLCj83PLazT5/view',
          track: 'ecommerce_calendar',
          image: 'mla/calendario_fechas_especiales.jpg',
        },
        {
          title: i18n.gettext('Guía para hacer campañas de remarketing'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1FeAzGHo_oQgnfJM6j7JNhtPhLxxxDR7F/view',
          track: 'remarketing_guide',
          image: 'mla/guia_hot_sale.jpg',
        },
        {
          title: i18n.gettext('Checklist: prepárate para el Buen Fin'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1ZvX9RcatxESw1rRS5B2VgOoAoC9KcyOf/view',
          track: 'checklist_buenfin',
          image: 'mla/checklist_hs_cd.jpg',
        },
        {
          title: i18n.gettext('Consejos para potenciar tus ventas Buen Fin'),
          button: i18n.gettext('Descargar'),
          button_url: 'https://drive.google.com/file/d/1vslgIee1x2AZGlMJnHG4DfzzsQpdUi0T/view',
          track: 'advice_buenfin',
          image: 'mla/potenciar_ventas_buenfin.jpg',
        },
      ],
    },
  ],
});
