const category = 'LANDING';
const action = 'VIEW_VALUE_DETAILS';
const path = '/home/landing/view_value_details';
const label = 'MERCADOSHOPS';

export const TRACK_VIEW_VALUE_DETAILS = () => ({
  melidata: {
    path,
    eventData: {
      category: category.toLowerCase(),
      action: action.toLowerCase(),
      label: label.toLowerCase(),
    },
  },
  analytics: {
    category,
    action,
    label,
  },
});
