import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';
import Slider from 'react-slick';

import trackEvent from '../../../../utils/tracking';
import wordings from './wordings';
import configSlider from './configSlider';
import { TRACK_STUDIOS } from './tracks';

const Studios = ({ i18n, siteId }) => {
  const locales = wordings(i18n, siteId);
  const renderSlide = locales.slide;

  const CLICK_PHOTOGRAPHY = 'CLICK_PHOTOGRAPHY';

  const trackStudios = (track) => {
    trackEvent(TRACK_STUDIOS(CLICK_PHOTOGRAPHY, track));
  };

  return (
    <div className="studio-cont">
      <Slider {...configSlider}>
        {renderSlide.map(({ img, studio_name, track, url_card }) => (
          <div className="studio-cont__photograph-slide-img-container">
            <a
              href={url_card}
              target="_blank"
              rel="noreferrer"
              className="studio-cont__url-link"
              onClick={() => { trackStudios(track); }}
            >
              <div className="studio-cont__photograph-card">
                <Image className="studio-cont__photograph-slide-img" alt={studio_name} src={`partners30/${img}`} />
                <h3 className="studio-cont__photograph-card-title"> {studio_name}</h3>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

Studios.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Studios;
