export default i18n => ({
  title: i18n.gettext('Marcas que {0}confían{1} en Mercado Shops:', '<strong class="brands__title--bold">', '</strong>'),
  cards: [
    {
      brand: 'Azeite Andorinha',
      link: 'https://www.compreandorinha.com.br/',
      logo: 'mlb/logo_azeite_andorinha.png',
      modal_image: 'mlb/screen_azeite_andorinha.png',
      button: i18n.gettext('Ver tienda'),
      track: 'ACEITE_ANDORINHA',
    }, {
      brand: 'Turma da monica',
      link: 'https://www.lojafraldasturmamonicababy.com.br/',
      logo: 'mlb/logo_turma_da_monica.png',
      modal_image: 'mlb/screen_turma_da_monica.png',
      button: i18n.gettext('Ver tienda'),
      track: 'TURMA_DA_MONICA',
    }, {
      brand: 'Comercial Textil',
      link: 'https://www.comercialtextilstore.com.br/',
      logo: 'mlb/logo_comercial_textil.png',
      modal_image: 'mlb/screen_new_comercial_textil.jpg',
      button: i18n.gettext('Ver tienda'),
      track: 'COMERCIAL_TEXTIL',
    }, {
      brand: 'Mae Terra',
      link: 'https://www.loja.maeterra.com.br/',
      logo: 'mlb/logo_mae_terra.png',
      modal_image: 'mlb/screen_mae_terra.png',
      button: i18n.gettext('Ver tienda'),
      track: 'MAE_TERRA',
    },
  ],
});
