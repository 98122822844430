import React from 'react';

const WaveCard = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M27.8966 15.4828V31H19.3887V22.2126H12.792V31H4.10352V15.4828"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3448 11.3448C11.0805 13.667 8.86379 15.4828 6.16702 15.4828C3.47024 15.4828 1.29125 13.7005 1 11.4022"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6551 11.3448C20.2528 13.6989 18.3201 15.4828 15.9999 15.4828C13.6797 15.4828 11.747 13.6989 11.3447 11.3501"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.0001 11.5241C30.6158 13.7675 28.4504 15.4828 25.8412 15.4828C23.232 15.4828 20.9476 13.6754 20.6553 11.3448"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 11.3448L5.42623 1H26.8249L31 11.3448"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WaveCard;
