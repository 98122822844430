const SHOPS_PRICES_MLA = [
  {
    id: 0,
    name: 'Tiendanube',
    img: 'landing30/logo_tiendanube.png',
    alt: 'logo_tienda_nube',
    track: 'TIENDANUBE',
  },
  {
    id: 1,
    name: 'Shopify',
    img: 'landing30/logo_shopify.png',
    alt: 'logo_shopify',
    track: 'SHOPIFY',
  },
  {
    id: 2,
    name: 'Woocommerce',
    img: 'landing30/logo_woo_commerce.png',
    alt: 'logo_woo_commerce',
    track: 'WOOCOMMERCE',
  },
];

const SHOPS_PRICES_MLM = [
  {
    id: 0,
    name: 'Shopify',
    img: 'landing30/logo_shopify.svg',
    alt: 'logo_shopify',
    track: 'SHOPIFY',
  },
  {
    id: 1,
    name: 'Wix',
    img: 'landing30/logo_wix.png',
    alt: 'logo_wix',
    track: 'WIX',
  },
  {
    id: 2,
    name: 'Woocommerce',
    img: 'landing30/logo_woo_commerce.png',
    alt: 'logo_woo_commerce',
    track: 'WOOCOMMERCE',
  },
];

const SHOPS_PRICES_MCO = [
  {
    id: 0,
    name: 'Shopify',
    img: 'landing30/logo_shopify.svg',
    alt: 'logo_shopify',
    track: 'SHOPIFY',
  },
  {
    id: 1,
    name: 'Jumpseller',
    img: 'landing30/logo_jumpseller.png',
    alt: 'logo_jumpseller',
    track: 'JUMPSELLER',
  },
  {
    id: 2,
    name: 'Woocommerce',
    img: 'landing30/logo_woo_commerce.png',
    alt: 'logo_woo_commerce',
    track: 'WOOCOMMERCE',
  },
];

const SHOPS_PRICES_MLC = [
  {
    id: 0,
    name: 'Shopify',
    img: 'landing30/logo_shopify.svg',
    alt: 'logo_shopify',
    track: 'SHOPIFY',
  },
  {
    id: 1,
    name: 'Jumpseller',
    img: 'landing30/logo_jumpseller.png',
    alt: 'logo_jumpseller',
    track: 'JUMPSELLER',
  },
  {
    id: 2,
    name: 'Woocommerce',
    img: 'landing30/logo_woo_commerce.png',
    alt: 'logo_woo_commerce',
    track: 'WOOCOMMERCE',
  },
];

const SHOPS_PRICES_MLB = [
  {
    id: 0,
    name: 'Nubemshop',
    img: 'landing30/logo_nuvemshop.png',
    alt: 'logo_nubemshop',
    track: 'NUBEMSHOP',
  },
  {
    id: 1,
    name: 'Shopify',
    img: 'landing30/logo_shopify.svg',
    alt: 'logo_shopify',
    track: 'SHOPIFY',
  },
  {
    id: 2,
    name: 'Lojaintegrada',
    img: 'landing30/logo_loja_integrada.png',
    alt: 'logo_loja_integrada',
    track: 'LOJAINTEGRADA',
  },
];

export default (siteId) => {
  const SHOPS_PRICES = {
    MLA: SHOPS_PRICES_MLA,
    MLM: SHOPS_PRICES_MLM,
    MCO: SHOPS_PRICES_MCO,
    MLC: SHOPS_PRICES_MLC,
    MLB: SHOPS_PRICES_MLB,
  };

  return SHOPS_PRICES[siteId];
};
