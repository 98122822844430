const CATEGORY_LANDING = 'LANDING';
const CATEGORY_PREVIEW_SHOP = 'PREVIEW_SHOP';

const paths = {
  VIEW_SHOP: '/home/landing/view_shop',
  OPEN_SHOP: '/home/preview_shop/open_shop',
  LANDING_OPEN_SHOP: '/home/landing/open_shop',
  HOME_PREVIEW_SHOP: '/home/preview_shop',
};

export const TRACK_CASES = (action, shop, mobile = false) => ({
  melidata: {
    path: mobile ? paths.LANDING_OPEN_SHOP : paths[action],
    eventData: {
      category: CATEGORY_LANDING.toLowerCase(),
      action: action.toLowerCase(),
      label: shop.toLowerCase(),
    },
  },
  analytics: {
    category: CATEGORY_LANDING,
    action,
    label: shop,
  },
});

export const TRACK_VIEWS = (action) => ({
  melidata: {
    path: paths[action], type: 'view',
  },
  analytics: {
    path: paths[action],
  },
  analyticsView: {
    path: `${paths[action].toUpperCase()}/`,
  },
});

export const TRACK_MODAL = (action, shop) => ({
  melidata: {
    path: paths[action],
    eventData: {
      category: CATEGORY_PREVIEW_SHOP.toLowerCase(),
      action: action.toLowerCase(),
      label: shop.toLowerCase(),
    },
  },
  analytics: {
    category: CATEGORY_PREVIEW_SHOP,
    action,
    label: shop,
  },
});
