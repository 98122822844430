import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';
import Slider from 'react-slick';

import trackEvent from '../../../../utils/tracking';
import wordings from './wordings';
import configSlider from './configSlider';
import { TRACK_APPSTORE } from './tracks';

const Appstore = ({ i18n, siteId }) => {
  const locales = wordings(i18n, siteId);
  const renderSlide = locales.slide;

  const CLICK_APPS = 'CLICK_APPS';

  const trackAppstore = (title) => {
    trackEvent(TRACK_APPSTORE(CLICK_APPS, title));
  };

  return (
    <div className="appstore-slide">
      <Slider {...configSlider}>
        {renderSlide.map(({ img, title, subtitle, url_card, track }, key) => (
          <div key={key} className="appstore-slide__connect-slide-img-container">
            <a
              href={url_card}
              target="_blank"
              rel="noreferrer"
              className="appstore-slide__connect-card-link"
              onClick={() => { trackAppstore(track); }}
            >
              <div className="appstore-slide__connect-card">
                <Image className="appstore-slide__connect-slide-img" src={`partners30/${img}`} alt={title} />
                <div className="appstore-slide__connect-card-content">
                  <h3 className="appstore-slide__connect-card-title">{title}</h3>
                  <p className="appstore-slide__connect-card-subtitle">{subtitle}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

Appstore.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Appstore;
