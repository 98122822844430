import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EbooksHero from '../EbooksHero';
import EbooksGallery from '../EbooksGallery';

const EbooksPage = ({
  siteId,
  i18n,
  deviceType,
  isWebview,
}) => {
  const appClassName = classnames('App app_landing', { 'App--top': isWebview });

  return (
    <div className={appClassName}>
      <EbooksHero i18n={i18n} />
      <EbooksGallery i18n={i18n} siteId={siteId} deviceType={deviceType} />
    </div>
  );
};

EbooksPage.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  isWebview: PropTypes.bool.isRequired,
};

export default EbooksPage;
