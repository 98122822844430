import React from 'react';

const ArrowStep = ({ className }) => (
  <svg
    className={className}
    width="11"
    height="19"
    viewBox="0 0 11 19"
    fill="none"
  >
    <path
      d="M1.78907 2.03735L8.67767 8.92595C9.06819 9.31647 9.06819 9.94964 8.67767 10.3402L1.78907 17.2288"
      stroke="#B2C2D0"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowStep;
