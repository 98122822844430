import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import Cases from '../Cases';
import AboutMs from '../AboutMs';
import Hero from '../Hero';
import Design from '../Design';
import Prices from '../Prices';
import BioLibre from '../BioLibre';
import MeliProducts from '../MeliProducts';

const Landing = ({
  siteId,
  i18n,
  deviceType,
  isWebview,
}) => {
  const appClassName = classnames('App app_landing', { 'App--top': isWebview });

  return (
    <div className={appClassName}>
      <Hero i18n={i18n} deviceType={deviceType} siteId={siteId} />
      <AboutMs i18n={i18n} siteId={siteId} />
      <MeliProducts i18n={i18n} siteId={siteId}/>
      <Prices i18n={i18n} siteId={siteId} deviceType={deviceType} />
      <Design i18n={i18n} siteId={siteId} />
      <Cases i18n={i18n} deviceType={deviceType} siteId={siteId} isWebview={isWebview} />
      <BioLibre i18n={i18n} deviceType={deviceType} siteId={siteId} />
    </div>
  );
};

Landing.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  isWebview: PropTypes.bool.isRequired,
};

export default Landing;
