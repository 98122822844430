const paths = '/shops/ebooks/download';

const CATEGORY_LANDING = {
  config: 'EBOOK_SHOP_CONFIGURATION',
  custom: 'EBOOK_CUSTOMIZATION',
  trafic: 'EBOOK_TRAFFIC_STRATEGIES',
  sales: 'EBOOK_LOYALTY_SALES',
};

const CATEGORY_EVENT = {
  config: 'EBOOK_SHOP_CONFIGURATION',
  custom: 'EBOOK_SHOP_CUSTOMIZATION',
  trafic: 'EBOOK_TRAFFIC_STRATEGIES',
  sales: 'EBOOK_LOYALTY_SALES',
};

export const TRACK_EBOOKS = (action, shop, category) => ({
  melidata: {
    path: paths,
    eventData: {
      category: CATEGORY_EVENT[category].toLowerCase(),
      action: action.toLowerCase(),
      label: shop.toLowerCase(),
    },
  },
  analytics: {
    category: CATEGORY_LANDING[category],
    action,
    label: shop.toUpperCase(),
  },
});
