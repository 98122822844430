import React from 'react';

import IconMetrics from '../../assets/components/IconMetrics';
import IconShops from '../../assets/components/IconShops';

const ItemsList = ({ text, icons }) => {
  const namespace = 'item-list__icon';
  const IconsList = new Array();
  IconsList.metrics = <IconMetrics className={namespace} />;
  IconsList.shops = <IconShops className={namespace} />;

  return (
    <div className="item-list">
      {IconsList[icons]}
      <div className="item-list__text-cont">
        <p className="item-list__text">
          {text}
        </p>
      </div>
    </div>
  );
};

export default ItemsList;
