import appstore from './appstore';
import agency from './agency';
import studios from './studios';

export default (i18n, name) => {
  const wordingsType = {
    appstore: appstore(i18n),
    agency: agency(i18n),
    studios: studios(i18n),
  };

  return wordingsType[name];
};
