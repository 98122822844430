module.exports = (i18n, siteId) => ({
  ecosystemModules: [
    {
      name: 'Mercado Pago',
      bg_color: 'white-first',
      wave_top: false,
      wave_bottom: false,
      order: 'ltr',
      title: i18n.gettext('¡Brinda transacciones protegidas y potencia tus ventas!'),
      button: i18n.gettext('Crea gratis tu Shop'),
      image: 'mercado-pago-img.png',
      track: 'BANNER_MPAGO',
      iconList: [
        {
          text: i18n.gettext('Genera rendimientos diarios gestionando todo online.'),
          iconName: 'IconProfit',
        },
        {
          text: i18n.gettext('Ofrece cuotas al mismo precio que publiques.'),
          iconName: 'IconPayment',
        },
        {
          text: i18n.gettext('Cobra desde cualquier lugar con tu Point Smart 100% bonificado y accede al dinero cuando tú quieras.'),
          iconName: 'IconPoint',
        },
      ],
    },
    {
      name: 'Mercado Envios',
      bg_color: 'grey',
      wave_top: true,
      wave_bottom: true,
      order: 'rtl',
      title: i18n.gettext('¡Prepara tus productos, nosotros nos encargamos del resto!'),
      button: i18n.gettext('Crea gratis tu Shop'),
      image: 'mercado-envios-img.png',
      track: 'BANNER_MENVIOS',
      iconList: [
        {
          text: i18n.gettext('Simplificamos tus entregas: ¡puedes hacer el seguimiento del envío cuando quieras!'),
          iconName: 'IconBox',
        },
        {
          text: i18n.gettext('No tienes que coordinar cada envío: el comprador lo elige y lo paga por Mercado Pago.'),
          iconName: 'IconSecurity',
        },
        {
          text: i18n.gettext('Si envías tus productos con Mercado Envíos Full, llegarán a todo el país en menos de 24 hs.'),
          iconName: 'IconDelivery',
        },
      ],
    },
    {
      name: (siteId !== 'MCO') ? ('Mercado Credito') : null,
      bg_color: 'white',
      wave_top: false,
      wave_bottom: false,
      order: 'ltr',
      title: i18n.gettext('¡Obtén un crédito a tasa preferencial y financia tu negocio a tu manera! '),
      button: i18n.gettext('Crea gratis tu Shop '),
      image: 'mercado-credito-img.png',
      track: 'BANNER_MCREDITS',
      iconList: [
        {
          text: i18n.gettext('Impulsa tu marca con un crédito a cuotas fijas seleccionando el monto y la cantidad de meses que prefieras.'),
          iconName: 'IconLoan',
        },
        {
          text: i18n.gettext('Accede a mejores condiciones crediticias; y si lo necesitas, también puedes pedir un adelanto de dinero.'),
          iconName: 'IconCredits',
        },
      ],
    },
    {
      name: 'Mercado Ads',
      bg_color: (siteId !== 'MCO') ? ('grey') : ('white'),
      wave_top: siteId !== 'MCO',
      wave_bottom: false,
      order: 'rtl',
      title: i18n.gettext('¡Llega a compradores que solo Mercado Shops puede darte!'),
      button: i18n.gettext('Crea gratis tu Shop'),
      image: 'mercado-ads-img.png',
      track: 'BANNER_MADS',
      iconList: [
        {
          text: i18n.gettext('Con Product Ads y Shops puedes llevar el tráfico de Mercado Libre a tu tienda.'),
          iconName: 'IconBudget',
        },
        {
          text: i18n.gettext('Controla tu presupuesto diario, sigue de cerca su rendimiento y activa o pausa la campaña cuando quieras.'),
          iconName: 'IconTrafic',
        },
      ],
    },
    {
      name: 'Mercado Libre',
      name_mlb: 'Mercado Livre',
      bg_color: 'grey-last',
      wave_top: siteId === 'MCO',
      wave_bottom: false,
      order: 'ltr',
      title: i18n.gettext('¡Vende en 2 canales a la vez y gestiona todo desde un solo lugar!'),
      button: i18n.gettext('Crea gratis tu Shop'),
      image: 'mercado-libre-img.png',
      track: 'BANNER_MKP',
      iconList: [
        {
          text: i18n.gettext('Es la única plataforma que te permite conectar tu marca con la tecnología de Mercado Libre. '),
          iconName: 'IconPlataform',
        },
      ],
    },
  ],
});
