import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';

import wordings from './wordings';
import IconCheck from '../../assets/components/IconCheck';
import trackEvent from '../../../../utils/tracking';
import { TRACK_BIO } from './tracks';

const BioLibre = ({ i18n, deviceType, siteId }) => {
  const locales = wordings(i18n, siteId);
  const { title, description, button, url } = locales;
  const trackBioLibre = () => trackEvent(TRACK_BIO());

  const BiolibreImage = {
    MLA: 'landing30/img_biolibre.png',
    MLB: 'landing30/img_biolibre_br.png',
    MLC: 'landing30/img_biolibre.png',
    MLM: 'landing30/img_biolibre.png',
    MCO: 'landing30/img_biolibre.png',
  };

  return (
    <section className="bio-libre">
      <div className="bio-libre__cont-bio">
        <div className="bio-libre__col-text">
          <h2 className="bio-libre__title" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="bio-libre__description">
            {description}
          </p>
          {
            deviceType !== 'desktop' && (
              <Image src={BiolibreImage[siteId]} alt="" className="bio-libre__image" />
            )
          }
          <ul className="bio-libre__list">
            {locales.list.map((item) => (
              <li className="bio-libre__list-item">
                <IconCheck className="bio-libre__list-text-ico" />
                <p className="bio-libre__list-text">
                  {item.text}
                </p>
              </li>
            ))}
          </ul>
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            onClick={trackBioLibre}
            className="bio-libre__button-bio"
          >
            {button}
          </a>
        </div>
        {deviceType === 'desktop' && (
          <div className="bio-libre__col-img">
            <Image src={BiolibreImage[siteId]} alt="" className="bio-libre__image" />
          </div>
        )}
      </div>
    </section>
  );
};

BioLibre.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default BioLibre;
