export default i18n => ({
  design: {
    title: i18n.gettext('Diseña {0}tu tienda{1} como más te guste.', '<strong class="design__headline--bold">', '</strong>'),
    description: i18n.gettext('Dale a tu tienda una identidad única con nuestras herramientas de personalización.'),
    button: i18n.gettext('Activar Tienda gratis'),
  },
  card: [
    {
      title: i18n.gettext('Secciones:'),
      description: i18n.gettext('Mueve, añade o quita secciones.'),
      designIcon: 'sections',
    },
    {
      title: i18n.gettext('Plantillas:'),
      description: i18n.gettext('Haz que tu Tienda se vea única.'),
      description_mx: i18n.gettext('Haz que tu tienda se vea única.'),
      designIcon: 'templates',
    },
    {
      title: i18n.gettext('Colores:'),
      description: i18n.gettext('Personaliza tu Tienda con tu paleta preferida.'),
      designIcon: 'colors',
    },
    {
      title: i18n.gettext('Banners:'),
      description: i18n.gettext('Destaca lo mejor de tu Tienda.'),
      designIcon: 'banners',
    },
  ],
});
