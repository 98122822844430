import React from 'react';

const IconStars = () => (
  <svg width="33" height="17" viewBox="0 0 33 17" fill="none">
    <path d="M2.44428 16.8243C1.90609 16.8243 1.38995 16.6105 1.00939 16.2299C0.628833 15.8494 0.415039 15.3332 0.415039 14.795C0.416 14.2571 0.630107 13.7415 1.01046 13.3612C1.39081 12.9808 1.90638 12.7667 2.44428 12.7657C2.98217 12.7667 3.49777 12.9808 3.87812 13.3612C4.25847 13.7415 4.47258 14.2571 4.47354 14.795C4.47354 15.3332 4.25975 15.8494 3.87919 16.2299C3.49863 16.6105 2.98247 16.8243 2.44428 16.8243ZM2.44428 13.4931C2.09928 13.494 1.7687 13.6315 1.52475 13.8755C1.2808 14.1194 1.14333 14.45 1.14237 14.795C1.14237 15.1403 1.27952 15.4714 1.52368 15.7156C1.76784 15.9598 2.09899 16.0969 2.44428 16.0969C2.78957 16.0969 3.12074 15.9598 3.3649 15.7156C3.60905 15.4714 3.74621 15.1403 3.74621 14.795C3.74525 14.45 3.60778 14.1194 3.36383 13.8755C3.11988 13.6315 2.78927 13.494 2.44428 13.4931Z" fill="#0A0080" />
    <path d="M9.43063 9.78936C8.89244 9.78936 8.37628 9.57554 7.99572 9.19498C7.61516 8.81442 7.40137 8.29831 7.40137 7.76012C7.40233 7.22222 7.61643 6.70663 7.99679 6.32628C8.37714 5.94592 8.89273 5.7318 9.43063 5.73083C9.96823 5.73275 10.4833 5.94715 10.8634 6.3273C11.2436 6.70744 11.458 7.22252 11.4599 7.76012C11.4589 8.29801 11.2448 8.81357 10.8645 9.19392C10.4841 9.57427 9.96852 9.7884 9.43063 9.78936ZM9.43063 6.45817C9.08563 6.45913 8.75505 6.59662 8.5111 6.84057C8.26715 7.08452 8.12966 7.41512 8.1287 7.76012C8.1287 8.10541 8.26587 8.43653 8.51003 8.68069C8.75419 8.92485 9.08534 9.06203 9.43063 9.06203C9.77592 9.06203 10.1071 8.92485 10.3512 8.68069C10.5954 8.43653 10.7326 8.10541 10.7326 7.76012C10.7316 7.41512 10.5941 7.08452 10.3502 6.84057C10.1062 6.59662 9.77562 6.45913 9.43063 6.45817Z" fill="#0A0080" />
    <path d="M17.9179 12.7461C17.38 12.7452 16.8644 12.5311 16.4841 12.1507C16.1037 11.7704 15.8896 11.2548 15.8887 10.7169C15.8896 10.179 16.1037 9.66341 16.4841 9.28306C16.8644 8.90271 17.38 8.68858 17.9179 8.68762C18.4561 8.68762 18.9723 8.90144 19.3528 9.282C19.7334 9.66256 19.9472 10.1787 19.9472 10.7169C19.9472 11.2551 19.7334 11.7712 19.3528 12.1518C18.9723 12.5323 18.4561 12.7461 17.9179 12.7461ZM17.9179 9.41495C17.5729 9.41591 17.2423 9.5534 16.9984 9.79735C16.7544 10.0413 16.617 10.3719 16.616 10.7169C16.617 11.0619 16.7544 11.3925 16.9984 11.6364C17.2423 11.8804 17.5729 12.0179 17.9179 12.0188C18.2632 12.0188 18.5944 11.8816 18.8385 11.6375C19.0827 11.3933 19.2198 11.0622 19.2198 10.7169C19.2198 10.3716 19.0827 10.0404 18.8385 9.79629C18.5944 9.55213 18.2632 9.41495 17.9179 9.41495Z" fill="#0A0080" />
    <path d="M27.5082 9.8025C26.8679 9.80202 26.234 9.67542 25.6427 9.42991C25.0514 9.1844 24.5143 8.82478 24.0621 8.37165C23.6099 7.91851 23.2514 7.38075 23.0072 6.78905C22.7629 6.19734 22.6377 5.5633 22.6387 4.9232C22.6406 3.63261 23.1542 2.39545 24.067 1.48287C24.9798 0.570286 26.2173 0.0567329 27.5082 0.0548096C28.7993 0.0557726 30.0373 0.569013 30.9503 1.4818C31.8633 2.39459 32.3767 3.63232 32.3776 4.9232C32.3786 5.5633 32.2534 6.19734 32.0091 6.78905C31.7649 7.38075 31.4064 7.91851 30.9542 8.37165C30.502 8.82478 29.9649 9.1844 29.3736 9.42991C28.7823 9.67542 28.1484 9.80202 27.5082 9.8025ZM27.5082 0.792894C26.4118 0.793849 25.3604 1.22897 24.5841 2.00303C23.8078 2.7771 23.3699 3.82707 23.366 4.9232C23.367 6.02122 23.8037 7.074 24.5803 7.85042C25.3569 8.62683 26.4099 9.06345 27.5082 9.06442C28.6064 9.06345 29.6595 8.62683 30.436 7.85042C31.2126 7.074 31.6493 6.02122 31.6503 4.9232C31.6465 3.82707 31.2085 2.7771 30.4322 2.00303C29.6559 1.22897 28.6046 0.793849 27.5082 0.792894Z" fill="#0A0080" />
    <path d="M29.0968 7.74998C29.0207 7.75006 28.9457 7.73132 28.8786 7.69544L27.5076 6.96827L26.1402 7.69544C26.0629 7.73669 25.9756 7.7555 25.8882 7.74972C25.8008 7.74394 25.7168 7.71381 25.6456 7.66273C25.5745 7.6109 25.5194 7.54013 25.4866 7.45848C25.4538 7.37683 25.4446 7.28758 25.4601 7.20097L25.722 5.67753L24.631 4.58678C24.5681 4.526 24.5238 4.44867 24.5032 4.36371C24.4826 4.27875 24.4866 4.18968 24.5146 4.10687C24.5404 4.02551 24.5879 3.9527 24.6519 3.89632C24.716 3.83994 24.7943 3.80212 24.8783 3.78692L26.4057 3.56514L27.093 2.17986C27.1315 2.1017 27.191 2.03583 27.2649 1.98959C27.3388 1.94335 27.4241 1.91858 27.5112 1.91809C27.599 1.9179 27.6851 1.94234 27.7596 1.98862C27.8342 2.03489 27.8943 2.10114 27.9331 2.17986L28.6168 3.56514L30.1478 3.78692C30.2318 3.80212 30.31 3.83994 30.3741 3.89632C30.4382 3.9527 30.4857 4.02551 30.5115 4.10687C30.539 4.19001 30.5424 4.27927 30.5212 4.36425C30.4999 4.44923 30.4549 4.5264 30.3915 4.58678L29.3005 5.67753L29.5623 7.20097C29.577 7.28755 29.5674 7.3765 29.5347 7.45799C29.5019 7.53948 29.4473 7.61033 29.3768 7.66273C29.2947 7.71987 29.1969 7.75034 29.0968 7.74998ZM25.4965 4.44502L26.3257 5.25217C26.3797 5.30539 26.4202 5.37083 26.4436 5.44297C26.467 5.51511 26.4727 5.59185 26.4602 5.66666L26.2638 6.80832L27.2894 6.27017C27.3565 6.23421 27.4315 6.21541 27.5076 6.21541C27.5837 6.21541 27.6587 6.23421 27.7258 6.27017L28.7513 6.80832L28.5586 5.66666C28.5445 5.59183 28.5494 5.51464 28.5729 5.44221C28.5964 5.36979 28.6378 5.30446 28.6931 5.25217L29.5223 4.44502L28.3767 4.27774C28.2997 4.26765 28.2262 4.23886 28.1628 4.19386C28.0994 4.14886 28.048 4.08904 28.0131 4.01961L27.5003 2.97977L26.9876 4.01961C26.9534 4.08964 26.9022 4.14996 26.8386 4.19506C26.7751 4.24016 26.7013 4.26859 26.6239 4.27774L25.4965 4.44502Z" fill="#0A0080" />
    <path d="M3.69847 13.4932C3.60534 13.4938 3.51553 13.4587 3.44754 13.3951C3.37981 13.3269 3.3418 13.2348 3.3418 13.1387C3.3418 13.0427 3.37981 12.9505 3.44754 12.8824L7.67697 8.38123C7.74511 8.31351 7.83729 8.27551 7.93337 8.27551C8.02944 8.27551 8.12162 8.31351 8.18976 8.38123C8.25996 8.44692 8.30128 8.53774 8.30469 8.63381C8.3081 8.72989 8.27331 8.8234 8.20793 8.8939L3.97486 13.3805C3.93939 13.4178 3.89641 13.4471 3.84876 13.4665C3.80112 13.4859 3.74989 13.495 3.69847 13.4932Z" fill="#0A0080" />
    <path d="M16.1873 10.506C16.1332 10.5057 16.0797 10.4933 16.031 10.4697L11.2051 8.16768C11.1178 8.12667 11.0504 8.05266 11.0177 7.96196C10.985 7.87126 10.9896 7.77126 11.0306 7.68397C11.0512 7.64062 11.0802 7.6018 11.1159 7.5697C11.1516 7.53761 11.1933 7.51288 11.2386 7.49699C11.2839 7.4811 11.3319 7.47435 11.3798 7.4771C11.4278 7.47986 11.4747 7.49208 11.5179 7.51306L16.3437 9.81141C16.3871 9.83203 16.4259 9.86107 16.458 9.89678C16.4901 9.93248 16.5148 9.97417 16.5307 10.0195C16.5466 10.0648 16.5533 10.1128 16.5506 10.1607C16.5478 10.2087 16.5356 10.2556 16.5146 10.2988C16.4851 10.3606 16.4388 10.4129 16.3809 10.4496C16.323 10.4863 16.2559 10.5058 16.1873 10.506Z" fill="#0A0080" />
    <path d="M19.5839 10.6732C19.524 10.6729 19.4651 10.6578 19.4124 10.6292C19.3598 10.6007 19.315 10.5595 19.282 10.5095C19.2554 10.4697 19.2369 10.425 19.2276 10.378C19.2183 10.331 19.2184 10.2826 19.2279 10.2356C19.2373 10.1886 19.256 10.144 19.2828 10.1043C19.3095 10.0645 19.3439 10.0304 19.3839 10.004L23.3842 7.35652C23.4644 7.30389 23.5621 7.28507 23.6561 7.30414C23.7501 7.32321 23.8327 7.37864 23.886 7.45836C23.9387 7.53854 23.9575 7.63627 23.9384 7.73027C23.9194 7.82426 23.8639 7.9069 23.7842 7.96022L19.7839 10.6114C19.7247 10.6511 19.6552 10.6726 19.5839 10.6732Z" fill="#0A0080" />
  </svg>

);

export default IconStars;
