import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import wordings from './wordings';
import Appstore from '../Appstore';
import Agency from '../Agency';
import Studios from '../Studios';

const Connect = ({ i18n, siteId, name }) => {
  const locales = wordings(i18n, name);
  const { title, volanta, description } = locales;

  const bgComponent = classnames('connect', name);

  const breadClass = classnames('connect-bread',
    { 'connect-bread--bread-pink': name === 'agency' || name === 'studios' });

  const titleClass = classnames('connect-title',
    { 'connect-title--title-blue': name === 'agency' });

  const subtitleClass = classnames('connect-subtitle',
    { 'connect-subtitle--subtitle-grey': name === 'agency' });

  const carouselComponent = {
    appstore: <Appstore i18n={i18n} siteId={siteId} />,
    agency: <Agency i18n={i18n} siteId={siteId} />,
    studios: <Studios i18n={i18n} siteId={siteId} />,
  };

  return (
    <section className={bgComponent}>
      <div className="connect-content">
        <p className={breadClass}>{volanta}</p>
        <h2 className={titleClass}>
          {title}
        </h2>
        <p className={subtitleClass}>
          {description}
        </p>
        {carouselComponent[name]}
      </div>
    </section>
  );
};

Connect.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Connect;
