import React from 'react';

const IconBudget = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none">
    <path
      d="M14.1716 18.8574H11.4526C11.1395 18.8574 10.8857 19.1112 10.8857 19.4243V23.0462C10.8857 23.3592 11.1395 23.613 11.4526 23.613H14.1716C14.4847 23.613 14.7385 23.3592 14.7385 23.0462V19.4243C14.7385 19.1112 14.4847 18.8574 14.1716 18.8574Z"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M16.5008 6.42578H9.06981C8.75673 6.42578 8.50293 6.67958 8.50293 6.99266V12.4537C8.50293 12.7667 8.75673 13.0205 9.06981 13.0205H16.5008C16.8139 13.0205 17.0676 12.7667 17.0676 12.4537V6.99266C17.0676 6.67958 16.8139 6.42578 16.5008 6.42578Z"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M8.50293 8.76758H17.0343"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M10.8857 21.6875H14.8"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M4.28584 12.1094H1.56688C1.2538 12.1094 1 12.3632 1 12.6763V16.2981C1 16.6112 1.2538 16.865 1.56688 16.865H4.28584C4.59891 16.865 4.85271 16.6112 4.85271 16.2981V12.6763C4.85271 12.3632 4.59891 12.1094 4.28584 12.1094Z"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M1 14.9414H4.91684"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M24.07 11.9375H21.3511C21.038 11.9375 20.7842 12.1913 20.7842 12.5044V16.1262C20.7842 16.4393 21.038 16.6931 21.3511 16.6931H24.07C24.3831 16.6931 24.6369 16.4393 24.6369 16.1262V12.5044C24.6369 12.1913 24.3831 11.9375 24.07 11.9375Z"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M20.7842 14.7676H24.701"
      stroke="#E73B90"
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
    <path
      d="M9.27983 22.1065C7.2911 21.416 5.54552 20.1631 4.25488 18.5"
      stroke="#E73B90"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
    <path
      d="M21.6636 18.1562C20.2788 20.0937 18.2963 21.5229 16.0205 22.2244"
      stroke="#E73B90"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
    <path
      d="M2.06641 10.4793C2.39851 7.87618 3.66255 5.4816 5.62447 3.73886C7.5864 1.99611 10.1133 1.02326 12.7374 1.00041C15.3615 0.977564 17.905 1.90627 19.8969 3.61459C21.8889 5.3229 23.1944 7.69511 23.5718 10.292"
      stroke="#E73B90"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="4 4"
    />
  </svg>
);

export default IconBudget;
