import React from 'react';

const MercadoEnvios = () => (
  <svg
    width="70"
    height="39"
    viewBox="0 0 70 39"
    fill="none"
  >
    <g clip-path="url(#clip0_158_733)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.0275 13.9716C66.201 12.248 64.4605 11.1201 62.5055 11.1201H49.3447V30.779H67.7984L69.3879 21.9305C68.5931 19.1584 67.9891 17.0773 67.5918 15.6873L67.0275 13.9716Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M69.1416 20.6517H55.7026L56.5053 11.1201"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4294 6.89448L12.9301 0.595703H51.721L49.3368 30.779H10.5459L11.1181 23.543"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2891 7.75231L14.7341 2.224H49.1619L47.0479 28.7376H12.6201L13.1287 22.3833"
        fill="#FFE600"
      />
      <path
        d="M12.4294 6.89448L12.9301 0.595703H51.721L49.3368 30.779H10.5459L11.1181 23.543"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M19.0735 20.0162H7.94727"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M16.292 14.8534H0"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M25.7333 14.8534H19.1768"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M17.2379 9.69043H7.99512"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M22.1013 38.3964C25.6214 38.3964 28.475 35.5265 28.475 31.9864C28.475 28.4463 25.6214 25.5764 22.1013 25.5764C18.5812 25.5764 15.7275 28.4463 15.7275 31.9864C15.7275 35.5265 18.5812 38.3964 22.1013 38.3964Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
      <path
        d="M56.9426 38.3964C60.4627 38.3964 63.3163 35.5265 63.3163 31.9864C63.3163 28.4463 60.4627 25.5764 56.9426 25.5764C53.4225 25.5764 50.5688 28.4463 50.5688 31.9864C50.5688 35.5265 53.4225 38.3964 56.9426 38.3964Z"
        fill="white"
        stroke="#333333"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_158_733">
        <rect width="70" height="39" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default MercadoEnvios;
