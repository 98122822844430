/**
 * Module dependencies
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Head from 'nordic/head';

import Page from 'nordic/page';
import Script from 'nordic/script';
import Hotjar from 'nordic/hotjar';

import injectI18n from 'nordic/i18n/injectI18n';

import wordings from './wordings';
import { pages, url, hotjar } from '../../../../../config/default';
import GTM from '../../../../components/Pixels/Gtm';
import Deeplink from '../../../../utils/deeplink';
import referer from '../../../../utils/referer';
import { openInternalDeeplink } from '../../../../utils/webkit';

const mercadoShopsIsotype = 'https://http2.mlstatic.com/frontend-assets/mshops-web-landing/crosssites/180x180.png';

const CoreView = ({
  i18n,
  translations,
  siteId,
  shopId,
  shopName,
  shopStatus,
  shopUrl,
  lowEnd,
  deviceType,
  company,
  melidataPath,
  commonTrackData,
  viewName,
  pathName,
  trackers,
  children,
  isNewShop,
  pricing,
  gtmId,
  isWebview,
  enableMobileActivation,
  queryParams,
  reqHeader,
}) => {
  const state = {
    translations,
    siteId,
    shopId,
    shopName,
    shopStatus,
    shopUrl,
    lowEnd,
    deviceType,
    company,
    viewName,
    pathName,
    trackers,
    isNewShop,
    melidataPath,
    commonTrackData,
    pricing,
    gtmId,
    isWebview,
    enableMobileActivation,
    queryParams,
    reqHeader,
  };

  const locales = wordings(i18n);
  const analytics = {
    section: 'HOME',
    business: 'MERCADOSHOPS',
    page: '/LANDING/',
    siteId,
    platform: 'MS',
  };

  if (viewName === 'EXPERTS') {
    analytics.section = 'SHOPS';
    analytics.page = '/PARTNERS/';
  }

  if (viewName === 'ECOSYSTEM') {
    analytics.section = 'SHOPS';
    analytics.page = '/ECOSYSTEM/';
  }

  if (viewName === 'EBOOKS') {
    analytics.section = 'SHOPS';
    analytics.page = '/EBOOKS/';
  }

  if (viewName === 'LEARN') {
    analytics.section = 'SHOPS';
    analytics.page = '/LEARN/';
  }

  const melidata = {
    path: melidataPath,
  };

  const { id, traffic, tagPrefix, tags } = hotjar;

  const isMobile = deviceType === 'mobile';

  const activationPage = pages.activate;
  const stringQueryParams = queryParams
    ? `${new URLSearchParams(queryParams).toString()}`
    : '';
  const formAction = `${url.mercadolibre[siteId]}${activationPage}${referer(reqHeader, stringQueryParams)}`;

  useEffect(() => {
    if (viewName !== 'PROMO') {
      const navHeader = document.getElementsByClassName('nav-header-pluslite')[0];
      if (navHeader) {
        navHeader.className += ' flash--hide';
      }
      window.scrollTo(0, 0);
    }

    // Replace logo link
    const headerLogo = document.querySelector('header .nav-logo');
    if (headerLogo) {
      headerLogo.setAttribute('href', url.landing[siteId]);
    }

    // Replace login link
    const headerLink = document.querySelector('a[data-link-id="login"]');
    if (headerLink) {
      headerLink.setAttribute('href', pages.login);
      headerLink.style.display = 'inline';
    }
  }, []);

  const onSubmit = (event) => {
    if (window.MobileWebKit) {
      event.preventDefault();

      const deeplink = Deeplink.buildDeepLinkUrl({
        url: formAction,
        params: {
          authentication_mode: 'required',
        },
      });

      openInternalDeeplink(deeplink);
    }
  };

  useEffect(() => {
    const openShopButtons = document.querySelectorAll('.ui-ms-button--open-shop');
    openShopButtons.forEach((button) => button.addEventListener('click', onSubmit));

    return () => openShopButtons.forEach((button) => button.removeEventListener('click'), onSubmit);
  }, []);

  return (
    <Page
      name="landing30"
      state={state}
      analytics={analytics}
      melidata={melidata}
      deviceType={isMobile ? 'mobile' : 'desktop'}
    >
      <Head>
        <title>{locales.core.title}</title>
        <meta
          name="description"
          content={locales.core.description}
        />
        <meta property="og:image" content={mercadoShopsIsotype} />
        <meta name="keywords" content={locales.core.keywords} />
      </Head>
      <Script
        src="https://www.googleoptimize.com/optimize.js?id=OPT-5RL968F"
      />
      <Script src="vendor.js" />
      {isWebview && (
        <Script
          src="https://http2.mlstatic.com/frontend-assets/mobile-webkit/v2.0.17/mobilewebkit.js"
        />
      )}
      <GTM lowEnd={lowEnd} id={gtmId} />
      <Hotjar
        id={id}
        tagPrefix={tagPrefix}
        tags={tags}
        traffic={traffic}
      />
      <form action={formAction} method="get" onSubmit={onSubmit}>
        {children}
      </form>
    </Page>
  );
};

CoreView.propTypes = {
  children: PropTypes.node,
  i18n: PropTypes.shape().isRequired,
  translations: PropTypes.shape().isRequired,
  siteId: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  melidataPath: PropTypes.string.isRequired,
  shopUrl: PropTypes.string,
  isNewShop: PropTypes.bool.isRequired,
  shopStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  viewName: PropTypes.string.isRequired,
  trackers: PropTypes.shape({}).isRequired,
  pricing: PropTypes.shape({
    newPromo: PropTypes.bool,
    validDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  gtmId: PropTypes.string.isRequired,
  isWebview: PropTypes.bool.isRequired,
  enableMobileActivation: PropTypes.bool.isRequired,
  commonTrackData: PropTypes.shape({
    shopId: PropTypes.string,
    shopName: PropTypes.string,
    shopDomain: PropTypes.string,
  }).isRequired,
  queryParams: PropTypes.shape().isRequired,
  pathName: PropTypes.string.isRequired,
  reqHeader: PropTypes.string.isRequired,
};

CoreView.defaultProps = {
  shopUrl: null,
};

/**
  * Inject i18n context as props into CoreView.
  */
export default injectI18n(CoreView);
