import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import TabNumber from '../TabNumber';
import TabSlider from '../TabSlider';
import IconArrowOutline from '../../../assets/components/IconArrowOutline';

import wordings from '../wordings';
import constants from '../constants';

const TabsMobile = ({ i18n, siteId }) => {
  const {
    tab1,
    tab2,
    tab3,
    hideInfo,
    showInfo1,
    showInfo2,
    showInfo3,
    cardsData,
  } = wordings(i18n, siteId);
  const { cards } = constants(cardsData, siteId);
  const [showInfo, setShowInfo] = useState(false);
  const [active, setActive] = useState(null);
  const ismobile = true;

  const menu1 = useRef(null);
  const menu2 = useRef(null);
  const menu3 = useRef(null);

  const showTabs = (tab) => {
    setShowInfo(!(active === tab && showInfo === true));
    setActive(tab);
  };

  return (
    <div>
      { siteId !== 'MLB' && (
        <div className="tab-mobile" ref={menu1}>
          <div>
            <div className="tab-mobile-title">{tab1}</div>
            <div className="tab-mobile-number-sub">
              <div className="tab-mobile-number">{cards[0].id}</div>
              <div className="tab-mobile-subtitle">{cards[0].title}</div>
            </div>

            <div className="tab-mobile-body">
              {(showInfo && active === 0) && (
                <TabNumber card={cards[0]} />
              )}
            </div>
          </div>
          <button
            type="button"
            className={classnames('tab-mobile-button', { 'tab-mobile-button--active': (showInfo && active === 0) })}
            onClick={() => {
              showTabs(0);
              menu1.current.scrollIntoView();
            }}
          >
            {showInfo && active === 0 ? hideInfo : showInfo1 }
            <IconArrowOutline />
          </button>
        </div>
      )}

      <div className="tab-mobile tab-mobile--white" ref={menu2}>
        <div>
          <div className="tab-mobile-title">{tab2}</div>
          <div className="tab-mobile-number-sub">
            <div className="tab-mobile-number">{cards[1].id}</div>
            <div className="tab-mobile-subtitle">{cards[1].title}</div>
          </div>

          <div className="tab-mobile-body">
            {(showInfo && active === 1) && (
              <TabNumber card={cards[1]} mobile={ismobile} />
            )}
          </div>
        </div>
        <button
          type="button"
          className={classnames(
            'tab-mobile-button',
            'tab-mobile-button--white',
            { 'tab-mobile-button--active': (showInfo && active === 1) },
          )}
          onClick={() => {
            showTabs(1);
            menu2.current.scrollIntoView();
          }}
        >
          {showInfo && active === 1 ? hideInfo : showInfo2 }
          <IconArrowOutline />
        </button>
      </div>

      <div className="tab-mobile" ref={menu3}>
        <div>
          <div className="tab-mobile-title">{tab3}</div>
          <div className="tab-mobile-number-sub">
            <div className="tab-mobile-number">{cards[2].id}</div>
            <div className="tab-mobile-subtitle">{cards[2].title}</div>
          </div>

          <div className="tab-mobile-body">
            {(showInfo && active === 2) && (
              <TabSlider card={cards[2]} siteId={siteId} />
            )}
          </div>
        </div>
        <button
          type="button"
          className={classnames('tab-mobile-button', { 'tab-mobile-button--active': (showInfo && active === 2) })}
          onClick={() => {
            showTabs(2);
            menu3.current.scrollIntoView();
          }}
        >
          {showInfo && active === 2 ? hideInfo : showInfo3 }
          <IconArrowOutline />
        </button>
      </div>
    </div>
  );
};

TabsMobile.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default TabsMobile;
