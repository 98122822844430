import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import EbooksCard from './EbooksCard';
import ArrowAccordion from '../../assets/components/ArrowAccordion';

const EbooksCategory = ({ deviceType, title, category, cards, index }) => {
  const [selected, setSelected] = useState(null);

  const toggle = () => {
    if (selected === index) {
      return setSelected(null);
    }
    return setSelected(index);
  };

  const classTitle = {
    custom: classnames('ebooks-gallery__gallery-title ebooks-gallery__gallery-title--custom'),
    sales: classnames('ebooks-gallery__gallery-title ebooks-gallery__gallery-title--sells'),
    trafic: classnames('ebooks-gallery__gallery-title ebooks-gallery__gallery-title--trafic'),
    config: classnames('ebooks-gallery__gallery-title ebooks-gallery__gallery-title--config'),
  };

  const btnClass = classnames('title-icon',
    { 'title-icon--up': index === selected });

  const accordionItems = classnames('ebooks-gallery__gallery-ebooks',
    { 'ebooks-gallery__gallery-ebooks-show': index === selected });

  return (
    <div className="ebooks-gallery__gallery-cont">
      {
        deviceType !== 'desktop' ? (
          <div
            className="ebooks-gallery__gallery-cont-title"
            role="button"
            tabIndex="0"
            onClick={() => toggle(index)}
          >
            <h2 className={classTitle[category]}>
              <span>{title}</span>
              {deviceType === 'mobile' && (
                <span className={btnClass}>
                  <ArrowAccordion />
                </span>
              )}
            </h2>
            {deviceType === 'tablet' && (
              <span className={btnClass}>
                <ArrowAccordion />
              </span>
            )}
          </div>
        ) : (
          <div className="ebooks-gallery__gallery-cont-title">
            <h2 className={classTitle[category]}>
              {title}
            </h2>
          </div>
        )
      }
      <div className={accordionItems}>
        {cards.map((item, i) => (
          <EbooksCard
            key={i}
            image={item.image}
            title={item.title}
            button={item.button}
            url={item.button_url}
            track={item.track}
            category={category}
          />
        ))}
      </div>
    </div>
  );
};

EbooksCategory.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  cards: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    button: PropTypes.string,
    button_url: PropTypes.string,
  }),
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default EbooksCategory;
