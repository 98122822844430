import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import HeroPartners from '../HeroPartners';
import Connect from '../Connect';
import TabsDesktop from '../Tabs/TabsDesktop';
import TabsMobile from '../Tabs/TabMobile';

const TabsView = {
  mobile: TabsMobile,
  tablet: TabsMobile,
  desktop: TabsDesktop,
};

const PartnersPage = ({
  siteId,
  i18n,
  deviceType,
  isWebview,
}) => {
  const appClassName = classnames('App app_landing', { 'App--top': isWebview });
  const TabComponent = TabsView[deviceType];

  return (
    <div className={appClassName}>
      <HeroPartners i18n={i18n} siteId={siteId} />
      <TabComponent i18n={i18n} siteId={siteId} />
      <Connect i18n={i18n} siteId={siteId} name="appstore" />
      <Connect i18n={i18n} siteId={siteId} name="agency" />
      {siteId === 'MLA' && (
        <Connect i18n={i18n} siteId={siteId} name="studios" />
      )}
    </div>
  );
};

PartnersPage.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  isWebview: PropTypes.bool.isRequired,
};

export default PartnersPage;
