const LOGO_SELECTOR = {
  MLA: 'Mercado Libre',
  MCO: 'Mercado Libre',
  MLC: 'Mercado Libre',
  MLM: 'Mercado Libre',
  MLB: 'Mercado Livre',
};

export default (i18n, siteId) => ({
  title: i18n.gettext('Tu propia Tienda online, sin costos de activación, con {0}toda la potencia{1} de Mercado Libre.', '<b class="section-ecosystems__title--bold">', '</b>'),
  title_mobile: i18n.gettext('Tu propia Tienda online, con {0}toda la potencia{1} de Mercado Libre.', '<b>', '</b>'),
  cards: [
    {
      brand: LOGO_SELECTOR[siteId],
      title: i18n.gettext('¿Quieres gestionar todo desde un solo lugar?'),
      description: i18n.gettext('Vende en dos canales al mismo tiempo: Mercado Shops es la única plataforma que te permite conectar tu marca con la tecnología de {0}Mercado Libre{1}.', '<b>', '</b>'),
      link: 'https://www.tiendaoficial.longvie.com/',
    },
    {
      brand: 'Mercado Pago',
      title: i18n.gettext('¿Quiere transacciones seguras?'),
      description: i18n.gettext('Con {0}Mercado Pago{1}, puedes ofrecer meses al mismo precio que publiques y acceder a tu dinero cuando lo desees. Además, tienes acceso a Créditos con una tasa preferencial.', '<b>', '</b>'),
      link: 'https://www.tiendaoficial.longvie.com/',
    },
    {
      brand: 'Mercado Ads',
      title: i18n.gettext('¿Quieres llegar a los usuarios de Mercado Libre?'),
      description: i18n.gettext('Con {0}Mercado Ads{1}, puedes dirigir a los usuarios de Mercado Libre a tu Tienda.', '<b>', '</b>'),
      link: 'https://www.tiendaoficial.longvie.com/',
    },
    {
      brand: 'Mercado Envios',
      title: i18n.gettext('¿Quieres enviar tus productos?'),
      description: i18n.gettext('Con los {0}Envíos de Mercado Libre{1}, tus productos pueden llegar a todo el país en menos de 24 horas.', '<b>', '</b>'),
    },
  ],
});
