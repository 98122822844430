export default i18n => ({
  title: i18n.gettext('Crea gratis tu propia tienda online'),
  list: [
    {
      text: i18n.gettext('Sin costos de activación, ni de mantenimiento.'),
    },
    {
      text: i18n.gettext('Todo Mercado Libre a tu favor.'),
    },
  ],
  ecosystem: [
    {
      title: i18n.gettext('Tu propia Tienda online, sin costos de activación, con {0}toda la potencia{1} de Mercado Libre.', '<b>', '</b>'),
      title_mobile: i18n.gettext('Tu propia Tienda online, con {0}toda la potencia{1} de Mercado Libre.', '<b>', '</b>'),
    },
    {
      title: i18n.gettext('¿Quieres gestionar todo desde un solo lugar?'),
      description: i18n.gettext('Vende en dos canales al mismo tiempo: Mercado Shops es la única plataforma que te permite conectar tu marca con la tecnología de {0}Mercado Libre{1}.', '<b>', '</b>'),
    },
    {
      title: i18n.gettext('¿Quiere transacciones seguras?'),
      description: i18n.gettext('Con {0}Mercado Pago{1}, puedes ofrecer meses al mismo precio que publiques y acceder a tu dinero cuando lo desees. Además, tienes acceso a Créditos con una tasa preferencial.', '<b>', '</b>'),
    },
    {
      title: i18n.gettext('¿Quieres llegar a los usuarios de Mercado Libre?'),
      description: i18n.gettext('Con {0}Mercado Ads{1}, puedes dirigir a los usuarios de Mercado Libre a tu Tienda.', '<b>', '</b>'),
    },
    {
      title: i18n.gettext('¿Quieres enviar tus productos?'),
      description: i18n.gettext('Con los {0}Envíos de Mercado Libre{1}, tus productos pueden llegar a todo el país en menos de 24 horas.', '<b>', '</b>'),
    },
  ],
  button: i18n.gettext('Activar tu Tienda gratis'),
});
