import React from 'react';
import PropTypes from 'prop-types';

import Image from 'nordic/image';

import trackEvent from '../../../../utils/tracking';
import { TRACK_EBOOKS } from './tracks';

const EbooksCard = ({ image, title, button, url, track, category }) => {
  const trackEbooks = () => {
    const ACTION = 'DOWNLOAD_EBOOK';
    trackEvent(TRACK_EBOOKS(ACTION, track, category));
  };

  return (
    <div className="ebooks-gallery__ebook-card">
      <div className="ebooks-gallery__image-cont">
        <Image
          className="ebooks-gallery__image"
          src={`ebooks/${image}`}
          alt={title}
        />
      </div>
      <div className="ebooks-gallery__text-cont">
        <p className="ebooks-gallery__text">
          {title}
        </p>
        <a
          href={url}
          className="ebooks-gallery__button"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => { trackEbooks(track); }}
        >
          {button}
        </a>
      </div>
    </div>
  );
};

EbooksCard.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  track: PropTypes.string.isRequired,
};

export default EbooksCard;
