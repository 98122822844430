import React from 'react';

const IconStarsWhite = () => (
  <svg width="70" height="37" viewBox="0 0 70 37" fill="none">
    <path d="M4.44415 36.732C3.26548 36.732 2.13511 36.2638 1.30167 35.4304C0.46822 34.597 0 33.4665 0 32.2879C0.0021055 31.1098 0.47101 29.9807 1.304 29.1477C2.13699 28.3147 3.26612 27.8457 4.44415 27.8436C5.62217 27.8457 6.75135 28.3147 7.58434 29.1477C8.41733 29.9807 8.88624 31.1098 8.88834 32.2879C8.88834 33.4665 8.42012 34.597 7.58668 35.4304C6.75323 36.2638 5.62282 36.732 4.44415 36.732ZM4.44415 29.4365C3.68859 29.4386 2.96459 29.7397 2.43032 30.274C1.89606 30.8083 1.595 31.5323 1.5929 32.2879C1.5929 33.0441 1.89327 33.7693 2.42799 34.304C2.96271 34.8387 3.68794 35.1391 4.44415 35.1391C5.20035 35.1391 5.92563 34.8387 6.46035 34.304C6.99507 33.7693 7.29545 33.0441 7.29545 32.2879C7.29334 31.5323 6.99228 30.8083 6.45802 30.274C5.92375 29.7397 5.19971 29.4386 4.44415 29.4365Z" fill="white" />
    <path d="M19.744 21.3209C18.5653 21.3209 17.4349 20.8526 16.6015 20.0192C15.768 19.1857 15.2998 18.0554 15.2998 16.8767C15.3019 15.6987 15.7708 14.5695 16.6038 13.7365C17.4368 12.9036 18.566 12.4346 19.744 12.4325C20.9214 12.4367 22.0493 12.9062 22.8819 13.7388C23.7144 14.5713 24.184 15.6994 24.1882 16.8767C24.1861 18.0548 23.7172 19.1838 22.8842 20.0168C22.0512 20.8498 20.922 21.3188 19.744 21.3209ZM19.744 14.0254C18.9884 14.0275 18.2644 14.3286 17.7302 14.8629C17.1959 15.3971 16.8948 16.1212 16.8927 16.8767C16.8927 17.6329 17.1931 18.3581 17.7278 18.8928C18.2626 19.4276 18.9878 19.728 19.744 19.728C20.5002 19.728 21.2254 19.4276 21.7602 18.8928C22.2949 18.3581 22.5953 17.6329 22.5953 16.8767C22.5932 16.1212 22.2921 15.3971 21.7578 14.8629C21.2236 14.3286 20.4996 14.0275 19.744 14.0254Z" fill="white" />
    <path d="M38.3338 27.7961C37.1558 27.794 36.0266 27.325 35.1936 26.4921C34.3607 25.6591 33.8918 24.53 33.8896 23.352C33.8918 22.1739 34.3607 21.0448 35.1936 20.2118C36.0266 19.3788 37.1558 18.9098 38.3338 18.9077C39.5125 18.9077 40.6429 19.376 41.4763 20.2094C42.3098 21.0429 42.778 22.1733 42.778 23.352C42.778 24.5306 42.3098 25.6609 41.4763 26.4944C40.6429 27.3278 39.5125 27.7961 38.3338 27.7961ZM38.3338 20.5006C37.5783 20.5027 36.8542 20.8038 36.32 21.3381C35.7857 21.8724 35.4846 22.5964 35.4825 23.352C35.4846 24.1075 35.7857 24.8315 36.32 25.3657C36.8542 25.9 37.5783 26.2011 38.3338 26.2032C39.0901 26.2032 39.8153 25.9028 40.35 25.3681C40.8847 24.8333 41.1851 24.1082 41.1851 23.352C41.1851 22.5958 40.8847 21.8705 40.35 21.3358C39.8153 20.801 39.0901 20.5006 38.3338 20.5006Z" fill="white" />
    <path d="M59.3354 21.3528C57.9332 21.3518 56.5449 21.0744 55.25 20.5366C53.955 19.9988 52.7787 19.2111 51.7883 18.2185C50.7979 17.2259 50.0129 16.0479 49.478 14.7517C48.9431 13.4555 48.6688 12.0666 48.6709 10.6645C48.6751 7.83736 49.8 5.1273 51.7991 3.12824C53.7982 1.12918 56.5083 0.00421326 59.3354 0C62.1631 0.00210947 64.8744 1.12639 66.8739 3.1259C68.8734 5.12542 69.9977 7.83672 69.9998 10.6645C70.0019 12.0666 69.7276 13.4555 69.1927 14.7517C68.6578 16.0479 67.8728 17.2259 66.8824 18.2185C65.892 19.2111 64.7157 19.9988 63.4208 20.5366C62.1258 21.0744 60.7376 21.3518 59.3354 21.3528ZM59.3354 1.61681C56.9342 1.6189 54.6317 2.57206 52.9315 4.26769C51.2314 5.96332 50.2722 8.26332 50.2638 10.6645C50.2659 13.0697 51.2224 15.3759 52.9231 17.0767C54.6239 18.7775 56.9301 19.7339 59.3354 19.736C61.7406 19.7339 64.0468 18.7775 65.7476 17.0767C67.4484 15.3759 68.4048 13.0697 68.4069 10.6645C68.3985 8.26332 67.4392 5.96332 65.7391 4.26769C64.039 2.57206 61.7365 1.6189 59.3354 1.61681Z" fill="white" />
    <path d="M62.8162 16.8527C62.6495 16.8529 62.4853 16.8118 62.3383 16.7332L59.3357 15.1403L56.3411 16.7332C56.1718 16.8236 55.9807 16.8648 55.7892 16.8521C55.5978 16.8395 55.4138 16.7735 55.2579 16.6616C55.1021 16.548 54.9814 16.393 54.9096 16.2141C54.8378 16.0353 54.8177 15.8398 54.8517 15.6501L55.4251 12.3129L53.0358 9.92354C52.8981 9.7904 52.8011 9.62101 52.756 9.4349C52.7109 9.24879 52.7195 9.05366 52.7809 8.87227C52.8373 8.69404 52.9413 8.53455 53.0817 8.41105C53.222 8.28755 53.3934 8.2047 53.5774 8.1714L56.9225 7.68557L58.4278 4.65106C58.5121 4.47983 58.6425 4.33555 58.8042 4.23426C58.966 4.13298 59.1528 4.07871 59.3436 4.07764C59.5359 4.07723 59.7244 4.13076 59.8877 4.23213C60.051 4.3335 60.1826 4.47862 60.2675 4.65106L61.7649 7.68557L65.1179 8.1714C65.3018 8.2047 65.4732 8.28755 65.6136 8.41105C65.7539 8.53455 65.8579 8.69404 65.9143 8.87227C65.9748 9.0544 65.9822 9.24992 65.9356 9.43607C65.8891 9.62222 65.7906 9.79128 65.6515 9.92354L63.2622 12.3129L63.8356 15.6501C63.8677 15.8397 63.8468 16.0346 63.7751 16.2131C63.7035 16.3916 63.5838 16.5468 63.4294 16.6616C63.2495 16.7867 63.0354 16.8535 62.8162 16.8527ZM54.9313 9.61301L56.7473 11.3811C56.8656 11.4977 56.9542 11.641 57.0055 11.7991C57.0568 11.9571 57.0693 12.1252 57.0419 12.2891L56.6118 14.7899L58.8578 13.6111C59.0048 13.5323 59.169 13.4911 59.3357 13.4911C59.5025 13.4911 59.6666 13.5323 59.8136 13.6111L62.0596 14.7899L61.6374 12.2891C61.6065 12.1252 61.6174 11.9561 61.6689 11.7974C61.7204 11.6388 61.8109 11.4956 61.9321 11.3811L63.748 9.61301L61.2392 9.24658C61.0704 9.22446 60.9095 9.1614 60.7707 9.06283C60.6319 8.96426 60.5193 8.83322 60.4427 8.68113L59.3198 6.4033L58.1968 8.68113C58.122 8.83452 58.0098 8.96666 57.8707 9.06546C57.7315 9.16425 57.5698 9.22652 57.4004 9.24658L54.9313 9.61301Z" fill="white" />
    <path d="M7.19227 29.4367C6.98831 29.438 6.79161 29.3611 6.64272 29.2217C6.49438 29.0725 6.41113 28.8706 6.41113 28.6602C6.41113 28.4498 6.49438 28.2479 6.64272 28.0987L15.9054 18.2387C16.0546 18.0903 16.2565 18.0071 16.4669 18.0071C16.6773 18.0071 16.8792 18.0903 17.0284 18.2387C17.1822 18.3826 17.2727 18.5815 17.2801 18.792C17.2876 19.0024 17.2114 19.2073 17.0682 19.3617L7.79758 29.1898C7.71989 29.2715 7.62576 29.3357 7.52142 29.3782C7.41707 29.4208 7.30489 29.4407 7.19227 29.4367Z" fill="white" />
    <path d="M34.542 22.8898C34.4233 22.8891 34.3063 22.8619 34.1995 22.8102L23.6306 17.7687C23.4395 17.6789 23.2918 17.5168 23.2201 17.3182C23.1485 17.1195 23.1586 16.9005 23.2483 16.7094C23.2935 16.6144 23.357 16.5294 23.4352 16.4591C23.5134 16.3888 23.6048 16.3347 23.704 16.2999C23.8032 16.2651 23.9083 16.2503 24.0133 16.2563C24.1183 16.2624 24.221 16.2891 24.3156 16.3351L34.8844 21.3686C34.9794 21.4137 35.0645 21.4773 35.1347 21.5555C35.205 21.6337 35.2591 21.725 35.2939 21.8243C35.3287 21.9235 35.3435 22.0287 35.3375 22.1336C35.3315 22.2386 35.3047 22.3413 35.2587 22.4359C35.1942 22.5714 35.0927 22.6859 34.9659 22.7663C34.839 22.8466 34.6921 22.8894 34.542 22.8898Z" fill="white" />
    <path d="M41.9809 23.2563C41.8497 23.2556 41.7207 23.2225 41.6054 23.1599C41.4901 23.0974 41.392 23.0074 41.3199 22.8978C41.2616 22.8106 41.221 22.7127 41.2007 22.6097C41.1803 22.5068 41.1805 22.4008 41.2012 22.298C41.2219 22.1951 41.2628 22.0973 41.3214 22.0103C41.3801 21.9232 41.4553 21.8486 41.5429 21.7907L50.3038 15.9926C50.4794 15.8773 50.6934 15.8361 50.8992 15.8778C51.1051 15.9196 51.2861 16.041 51.4029 16.2156C51.5182 16.3912 51.5594 16.6052 51.5176 16.8111C51.4759 17.0169 51.3545 17.1979 51.1799 17.3147L42.4189 23.1208C42.2893 23.2079 42.137 23.2551 41.9809 23.2563Z" fill="white" />
  </svg>
);

export default IconStarsWhite;
