import React from 'react';

const IconSecurity = () => (
  <svg width="19" height="24" viewBox="0 0 19 24" fill="none">
    <path
      d="M12.9194 3.45012C11.6738 2.83912 10.548 2.00922 9.59593 1C8.64441 2.0086 7.51977 2.83844 6.27538 3.45012C3.69044 4.62242 1.81182 4.64587 1 4.58139V14.2265C1 17.1309 4.51692 19.71 4.90378 19.9943C6.36916 21.0406 8.90427 22.4942 9.593 22.887C10.2847 22.4942 12.8168 21.0406 14.2822 19.9943C14.6837 19.7012 18.186 17.1339 18.186 14.2265V4.58139C17.3771 4.64587 15.5014 4.61363 12.9194 3.45012Z"
      stroke="#E73B90"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.15261 10.1367H13.0399C13.2265 10.1359 13.4059 10.2087 13.5392 10.3393C13.6725 10.4698 13.749 10.6477 13.7521 10.8342V14.741C13.7517 14.9908 13.702 15.2382 13.6058 15.4689C13.5097 15.6995 13.3689 15.9089 13.1917 16.0851C13.0144 16.2612 12.8042 16.4007 12.5729 16.4954C12.3417 16.5901 12.094 16.6383 11.8442 16.6372H7.33957C7.09017 16.6372 6.84321 16.588 6.6128 16.4926C6.38238 16.3972 6.17303 16.2573 5.99667 16.0809C5.82032 15.9046 5.68043 15.6952 5.58499 15.4648C5.48955 15.2344 5.44043 14.9874 5.44043 14.738V10.8342C5.44043 10.6461 5.51515 10.4657 5.64816 10.3327C5.78117 10.1997 5.96157 10.125 6.14967 10.125L6.15261 10.1367Z"
      stroke="#E73B90"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M7.46582 10.0534V7.70879C7.46737 7.14418 7.69234 6.60313 8.09159 6.20389C8.49083 5.80465 9.03187 5.57967 9.59649 5.57812C10.1611 5.57967 10.7021 5.80465 11.1014 6.20389C11.5006 6.60313 11.7256 7.14418 11.7272 7.70879V10.0534"
      stroke="#E73B90"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.6377 13.4029L8.80121 14.3993L11.5532 11.9434"
      stroke="#E73B90"
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
);

export default IconSecurity;
