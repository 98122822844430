import React from 'react';
import PropTypes from 'prop-types';

const Terms = ({ locales }) => (
  <div className="terms">
    <ol>
      {locales.map((item, key) => (
        <li key={key} className="terms__item-list">
          {item.text}
        </li>
      ))}
    </ol>
  </div>
);

Terms.propTypes = {
  locales: PropTypes.shape({
    text: PropTypes.string,
  }),
};

export default Terms;
