import React from 'react';

const IconColors = () => (
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
  >
    <g>
      <path
        d="M46.4382 26.34H2.56183C1.14697 26.34 0 27.487 0 28.9019V46.0214C0 47.4362 1.14697 48.5832 2.56183 48.5832H46.4382C47.853 48.5832 49 47.4362 49 46.0214V28.9019C49 27.487 47.853 26.34 46.4382 26.34Z"
        fill="#FFE600"
      />
      <path
        d="M20.1795 0H2.56183C1.14697 0 0 1.14697 0 2.56183V20.1795C0 21.5943 1.14697 22.7413 2.56183 22.7413H20.1795C21.5943 22.7413 22.7413 21.5943 22.7413 20.1795V2.56183C22.7413 1.14697 21.5943 0 20.1795 0Z"
        fill="#FBEDA2"
      />
      <path
        d="M46.428 0H28.8104C27.3955 0 26.2485 1.14697 26.2485 2.56183V20.1795C26.2485 21.5943 27.3955 22.7413 28.8104 22.7413H46.428C47.8428 22.7413 48.9898 21.5943 48.9898 20.1795V2.56183C48.9898 1.14697 47.8428 0 46.428 0Z"
        fill="#FBEDA2"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="49" height="48.573" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default IconColors;
