export default i18n => ({
  slide: [
    {
      brand: 'Index',
      img: '/mla/index.png',
      url_card: 'https://www.estudioindex.com.ar/',
      track: 'INDEX',
    },
    {
      brand: 'VKD Design',
      img: '/mla/vkd.png',
      url_card: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-diseno-web/vk-design',
      track: 'VKD_DESIGN',
    },
    {
      brand: 'Lupe Duarte',
      img: '/mla/lupe_duarte.png',
      url_card: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-diseno-web/lupe-duarte',
      track: 'LUPE_DUARTE',
    },
    {
      brand: 'Zurbrand',
      img: '/mla/zurbrand.png',
      url_card: 'https://centrodepartners.mercadolibre.com.ar/solutions/agencias-de-diseno-web/zurbrand',
      track: 'ZURBRAND',
    },
  ],
});
