import React from 'react';

const IconProfit = () => (
  <svg width="21" height="15" viewBox="0 0 21 15">
    <g clipPath="url(#clip0_5269_4996)">
      <path
        d="M20.2656 11.9115H0.75C0.335938 11.9115 0 11.5657 0 11.1434V0.772118C0 0.345845 0.335938 0 0.75 0H20.25C20.6641 0 21 0.345845 21 0.772118V11.1595C21 11.5737 20.6719 11.9115 20.2695 11.9115H20.2656ZM0.78125 11.1072H20.2187V0.80429H0.78125V11.1072Z"
        fill="#E73B90"
      />
      <path
        d="M18.9531 13.7815C18.9531 13.7815 18.9179 13.7815 18.9023 13.7815L0.351502 11.8834C0.136658 11.8633 -0.019592 11.6662 -6.07063e-05 11.445C0.0194705 11.2239 0.210877 11.063 0.425721 11.0831L18.6992 12.9491V11.5094C18.6953 11.2882 18.8671 11.1072 19.082 11.1072C19.2968 11.1072 19.4726 11.2882 19.4726 11.5094V13.2346C19.4804 13.3874 19.414 13.5362 19.3046 13.6408C19.207 13.7333 19.0781 13.7815 18.9453 13.7815H18.9531Z"
        fill="#E73B90"
      />
      <path
        d="M16.4609 15C16.4257 15 16.3945 15 16.3593 14.992L0.316328 11.7909C0.105391 11.7507 -0.0352342 11.5376 0.00773453 11.3204C0.0507033 11.1032 0.253828 10.9625 0.464766 11.0027L16.207 14.1435L16.1991 13.1421C16.1991 12.9209 16.371 12.74 16.5859 12.7359C16.8007 12.7359 16.9765 12.9129 16.9765 13.1341L16.9882 14.4491C16.9882 14.614 16.9218 14.7668 16.7968 14.8713C16.6991 14.9558 16.582 14.996 16.457 14.996L16.4609 15Z"
        fill="#E73B90"
      />
      <path
        d="M2.09766 11.7989C1.88281 11.7989 1.70703 11.6179 1.70703 11.3968C1.70703 10.6488 1.11719 10.0415 0.390625 10.0415C0.175781 10.0415 0 9.86057 0 9.63939C0 9.41821 0.175781 9.23724 0.390625 9.23724C1.54688 9.23724 2.48828 10.2064 2.48828 11.3968C2.48828 11.6179 2.3125 11.7989 2.09766 11.7989Z"
        fill="#E73B90"
      />
      <path
        d="M18.7383 11.7989C18.5234 11.7989 18.3477 11.6179 18.3477 11.3968C18.3477 10.2064 19.2891 9.23724 20.4453 9.23724C20.6602 9.23724 20.8359 9.41821 20.8359 9.63939C20.8359 9.86057 20.6602 10.0415 20.4453 10.0415C19.7187 10.0415 19.1289 10.6488 19.1289 11.3968C19.1289 11.6179 18.9531 11.7989 18.7383 11.7989Z"
        fill="#E73B90"
      />
      <path
        d="M0.601562 2.83913C0.386719 2.83913 0.210938 2.65816 0.210938 2.43698C0.210938 2.2158 0.386719 2.03484 0.601562 2.03484C1.32812 2.03484 1.91797 1.4276 1.91797 0.679611C1.91797 0.458431 2.09375 0.277466 2.30859 0.277466C2.52344 0.277466 2.69922 0.458431 2.69922 0.679611C2.69922 1.86996 1.75781 2.83913 0.601562 2.83913Z"
        fill="#E73B90"
      />
      <path
        d="M20.4453 2.83913C19.2891 2.83913 18.3477 1.86996 18.3477 0.679611C18.3477 0.458431 18.5234 0.277466 18.7383 0.277466C18.9531 0.277466 19.1289 0.458431 19.1289 0.679611C19.1289 1.4276 19.7187 2.03484 20.4453 2.03484C20.6602 2.03484 20.8359 2.2158 20.8359 2.43698C20.8359 2.65816 20.6602 2.83913 20.4453 2.83913Z"
        fill="#E73B90"
      />
      <path
        d="M10.8281 9.96919C8.61328 9.96919 6.80859 8.11129 6.80859 5.83112C6.80859 3.55096 8.61328 1.69305 10.8281 1.69305C11.6328 1.69305 12.4102 1.93836 13.0742 2.40083C13.2539 2.52549 13.3008 2.77482 13.1797 2.95981C13.0586 3.1448 12.8164 3.19305 12.6367 3.06839C12.1016 2.69439 11.4766 2.49734 10.8281 2.49734C9.04297 2.49734 7.58984 3.99332 7.58984 5.83112C7.58984 7.66893 9.04297 9.16491 10.8281 9.16491C12.6133 9.16491 14.0664 7.66893 14.0664 5.83112C14.0664 5.1515 13.8711 4.50002 13.4961 3.94104C13.375 3.75606 13.418 3.50673 13.5977 3.38206C13.7773 3.2574 14.0195 3.30163 14.1406 3.48662C14.6016 4.17831 14.8477 4.99064 14.8477 5.83112C14.8477 8.11129 13.043 9.96919 10.8281 9.96919V9.96919Z"
        fill="#E73B90"
      />
      <path
        d="M10.8398 7.93831C10.543 7.93831 10.2422 7.86191 9.99609 7.71311C9.625 7.48791 9.41406 7.1099 9.41797 6.6796C9.41797 6.45842 9.59375 6.28148 9.80859 6.28148C10.0234 6.28148 10.1992 6.46646 10.1953 6.68764C10.1953 6.78014 10.2148 6.91285 10.3906 7.02142C10.6328 7.17022 11.0352 7.17022 11.2852 7.02142C11.4648 6.91687 11.4883 6.78416 11.4883 6.69569C11.4727 6.42625 11.3594 6.35386 10.7539 6.24126C10.2344 6.14475 9.44531 5.99596 9.38281 5.02277C9.38281 4.57236 9.60156 4.20239 9.97656 3.98121C10.4688 3.69167 11.1758 3.69569 11.6562 3.98523C12.0234 4.20641 12.2344 4.58041 12.2305 5.00668C12.2305 5.22786 12.0547 5.4048 11.8398 5.4048H11.8359C11.6211 5.4048 11.4453 5.21982 11.4492 4.99864C11.4492 4.91016 11.4336 4.78148 11.2617 4.67692C11.0195 4.52813 10.6172 4.52813 10.3633 4.67692C10.1836 4.78148 10.1641 4.91016 10.1641 4.99462C10.1836 5.26003 10.293 5.33242 10.8945 5.44502C11.418 5.54555 12.207 5.69435 12.2695 6.66754C12.2695 7.12196 12.0508 7.49596 11.6719 7.71714C11.4258 7.86191 11.1328 7.93429 10.8398 7.93429V7.93831Z"
        fill="#E73B90"
      />
      <path
        d="M11.164 8.37667C10.9765 8.37667 10.8085 8.23592 10.7772 8.03887L10.1093 3.75201C10.0741 3.53083 10.2186 3.32573 10.4335 3.28954C10.6444 3.25335 10.8476 3.40214 10.8827 3.62332L11.5507 7.91018C11.5858 8.13136 11.4413 8.33646 11.2265 8.37265C11.2069 8.37265 11.1835 8.37667 11.164 8.37667Z"
        fill="#E73B90"
      />
    </g>
    <defs>
      <clipPath id="clip0_5269_4996">
        <rect width="21" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconProfit;
