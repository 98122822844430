import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@andes/button';
import UnavailableDevice from '../../assets/components/UnavailableDevice';

const ErrorScreen = (props) => {
  const { title, firstText, secondText, button, activationMessage, shopUrl, isNewShop, shopId } = props;

  useEffect(() => {
    if (isNewShop) {
      window.dataLayer.push({
        event: 'ACTIVATION',
        custId: shopId,
      });
    }
  }, [isNewShop]);

  return (
    <section className="unavailable__error-screen">
      <div className="error-screen__message">{activationMessage}</div>
      <div className="error-screen__container">
        <UnavailableDevice className="error-screen__icon" />
        <h2 className="error-screen__title">{title}</h2>
        <div className="error-screen__text">
          <p>{firstText}</p>
          <p>{secondText}</p>
        </div>
        <Button
          href={shopUrl}
          className="mercadoshops-button"
          size="small"
        >
          {button.text}
        </Button>
      </div>
    </section>
  );
};

ErrorScreen.propTypes = {
  shopUrl: PropTypes.string.isRequired,
  shopId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  firstText: PropTypes.string.isRequired,
  secondText: PropTypes.string.isRequired,
  isNewShop: PropTypes.bool.isRequired,
  activationMessage: PropTypes.string.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default ErrorScreen;
