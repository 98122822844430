import MLA from './mla';
import MLM from './mlm';
import MCO from './mco';
import MLC from './mlc';
import MLB from './mlb';

export default (i18n, siteId) => {
  const MERCADO_PAGO = {
    MLA: MLA(i18n),
    MLM: MLM(i18n),
    MCO: MCO(i18n),
    MLC: MLC(i18n),
    MLB: MLB(i18n),
  };

  return MERCADO_PAGO[siteId];
};
