import React from 'react';

const IconWebinar = () => (
  <svg
    width="42"
    height="28"
    viewBox="0 0 42 28"
    fill="none">
    <path 
      d="M8.60156 14.6636V22.8844C8.60156 22.8844 21.2429 32.2711 32.2569 22.2642V14.6636L20.4292 19.9374L8.60156 14.6636Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path 
      d="M20.4631 15.7548L1 8.77431L20.4283 1L39.9311 8.6999L20.4631 15.7548Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path 
      d="M39.9317 10.3272L38.8452 18.4736H41.4846L39.9317 10.3272Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconWebinar;
