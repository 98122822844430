export default {
  tallerVideo: '',
  tallerCount1Number: '',
  tallerCount2Number: '',
  tallerBtnLink: '',

  brigadaVideo: 'https://www.youtube.com/embed/wq3z5nX23jM',
  brigadaCount1Number: '+600',
  brigadaCount2Number: 'X7',
  brigadaCount2Subtitle: '7 vezes',
  brigadaBtnLink: 'https://partners.mercadolivre.com.br/brigada-mercado-shops-br?utm_source=mercadoshops&utm_medium=referral&utm_campaign=new_site_brigadas',

  agenciaBtnLink: 'https://centraldeparceiros.mercadolivre.com.br/search/marketing-e-publicidade?business_units=mshops&subcategories=marketing-digital&utm_source=mercadoshops&utm_medium=referral&utm_campaign=new_site_agencias_certificadas',
};
